import {
  Card,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  Grid,
  Paper,
  Button,
  Toolbar,
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  Tooltip,
  CardHeader,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

const useStyle = makeStyles(() => ({
  root: {
    marginTop: "2rem",
  },
  leftGrid: {
    padding: "5rem",
  },
  rightGrid: {
    padding: "5rem",
  },
  buttonsGroup: {
    float: "right",
  },
  button: {
    marginLeft: "2rem",
  },
  List: {
    height: 500,
    width: "100%",
    overflow: "auto",
  },
  CardHeader: {},
}));

function QueryStep3(props) {
  const classes = useStyle();
  const {
    Left,
    Right,
    checked,
    leftChecked,
    Querystep3checkrow,
    handleToggle,
    numberOfChecked,
    handleToggleAll,
    handleCheckedRight,
    handleCheckedLeft,
    handleselection,
    handlesort,
    Querystep3check,
    handlestep3Checkbox,
    handleStep3MoveDownAction,
    handleStep3MoveUpAction,
  } = props;

  useEffect(() => {}, []);

  const CustomTransferList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.CardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      {/* <Toolbar className={classes.CardHeader}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Box display="flex" ml="-10px">
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider /> */}
      <List className={classes.List} dense component="div" role="list">
        {items.map((data) => {
          const labelId = `transfer-list-all-item-${data}-label`;
          return (
            <ListItem button style={{ padding: 0, margin: 0 }} onClick={handleToggle(data)} key={data.name}>
              <ListItemIcon style={{ minWidth: 40 }}>
                <Checkbox
                  style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 25, margin: 0 }}
                  checked={checked.indexOf(data) !== -1}
                  tabIndex={-1}
                  inputProps={{ "aria-labelledby": labelId }}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={`${data.name}`} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  const CustomTransferRightList = (title, items) => (
    <Card>
      <Toolbar className={classes.CardHeader}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Box display="flex">
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
            </Box>
            {/* <CardHeader
              className={classes.CardHeader}
              avatar={(
                <Checkbox
                  onClick={handleToggleAll(items)}
                  checked={numberOfChecked(items) === items.length && items.length !== 0}
                  indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                  disabled={items.length === 0}
                  inputProps={{ 'aria-label': 'all items selected' }}
                />
              )}
              title={title}
              subheader={`${numberOfChecked(items)}/${items.length} selected`}
            /> */}
          </Grid>
          <Grid item>
            {Right.length !== 0 && (
              <Box display="flex">
                {Querystep3check !== 0 && (
                  <IconButton onClick={handleStep3MoveUpAction}>
                    <Tooltip title="MoveUp" cursor="pointer">
                      <ArrowUpwardIcon color="secondary" />
                    </Tooltip>
                  </IconButton>
                )}
                {Right.length - 1 !== Querystep3check && (
                  <IconButton onClick={handleStep3MoveDownAction}>
                    <Tooltip title="MoveDown" cursor="pointer">
                      <ArrowDownwardIcon color="secondary" />
                    </Tooltip>
                  </IconButton>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Toolbar>
      <Divider />
      <List className={classes.List} dense component="div">
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {items.map((row, index) => {
          const keyvalue = `${row.name}-${index}`;
          return (
            <Table key={keyvalue}>
              <TableBody>
                <TableRow class="cell-dataset">
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={Querystep3check === index}
                      onChange={(e) => {
                        handlestep3Checkbox(e, index, row);
                      }}
                      tabIndex={-1}
                      inputProps={{ "aria-labelledby": row }}
                      disableRipple
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    scope="row"
                    padding="none"
                    style={{ minWidth: "120px", maxWidth: "120px" }}
                  >
                    <Typography variant="body2">{row.name}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <FormControl>
                      <InputLabel id="data-type-label">Sort</InputLabel>
                      <Select
                        key={index}
                        native
                        label="Type"
                        id="data-type"
                        required
                        onChange={(e) => {
                          handlesort(e, index);
                        }}
                        value={row.orderType}
                        labelId="data-type-label"
                      >
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <option value="-" />
                        <option value="ASCENDING">Ascending</option>
                        <option value="DESCENDING">Descending</option>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell class="sequence">
                    <FormControl>
                      <InputLabel id="data-type-label">Sequence</InputLabel>
                      <Select
                        key={index}
                        native
                        label="Type"
                        id="data-type"
                        required
                        onChange={(e) => {
                          handleselection(e, row);
                        }}
                        value={row.orderSequence}
                        labelId="data-type-label"
                      >
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <option value="select" />
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((data) => (
                          <option value={data} key={data}>
                            {data}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          );
        })}
      </List>
    </Card>
  );

  return (
    <Container className={classes.root}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        direction="row"
      >
        <Grid item sm={5} className={classes.leftGrid}>
          <Paper elevation={3}>
            {CustomTransferList("Fields Available", Left)}
          </Paper>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              <FormattedMessage id="dataset.add.selected" />{" "}
              <ChevronRightIcon />
            </Button>
            <Divider />
            <Divider />
            <Divider />
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={Object.keys(Querystep3checkrow).length === 0}
              aria-label="move selected left"
            >
              <ChevronLeftIcon />{" "}
              <FormattedMessage id="dataset.remove.selected" />
            </Button>
          </Grid>
        </Grid>
        <Grid item sm={5} className={classes.rightGrid}>
          <Paper elevation={3}>
            {CustomTransferRightList("Fields selected", Right)}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

QueryStep3.propTypes = {
  Left: PropTypes.arrayOf(PropTypes.object),
  Right: PropTypes.arrayOf(PropTypes.object),
  checked: PropTypes.arrayOf(PropTypes.object),
  leftChecked: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  Querystep3checkrow: PropTypes.object,
  handleToggle: PropTypes.func,
  handleCheckedRight: PropTypes.func,
  handleCheckedLeft: PropTypes.func,
  handleselection: PropTypes.func,
  handlesort: PropTypes.func,
  Querystep3check: PropTypes.number,
  handlestep3Checkbox: PropTypes.func,
  handleStep3MoveUpAction: PropTypes.func,
  handleStep3MoveDownAction: PropTypes.func,
  numberOfChecked: PropTypes.func,
  handleToggleAll: PropTypes.func,
};

QueryStep3.defaultProps = {
  Left: [],
  Right: [],
  checked: [],
  leftChecked: [],
  Querystep3checkrow: {},
  handleToggle: () => {},
  handleCheckedRight: () => {},
  handleCheckedLeft: () => {},
  handleselection: () => {},
  handlesort: () => {},
  Querystep3check: undefined,
  handlestep3Checkbox: () => {},
  handleStep3MoveUpAction: () => {},
  handleStep3MoveDownAction: () => {},
  numberOfChecked: () => {},
  handleToggleAll: () => {},
};

export default QueryStep3;

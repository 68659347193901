import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Select,
  Typography,
} from "@material-ui/core";
import espree from "espree";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Controlled as CodeMirror } from "react-codemirror17";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  code: {
    fontSize: "15px",
    height: "auto",
  },
  invalid: { color: theme.palette.error.light },
  invalidBorder: {
    border: 1,
    borderStyle: "solid",
    borderColor: theme.palette.error.light,
  },
}));

const RETURN_TYPES = {
  TEXT: "TEXT",
  NUMBER: "NUMBER",
  TABLE: "TABLE",
  VOID: "VOID",
  DATETIME: "DATETIME",
};

const DEFAULT_SCRIPT_VALUE = {
  TEXT: 'return {label: "", value: "text"};',
  NUMBER: 'return {label: "", value: number};',
  TABLE:
    "return {\n" +
    '  name: "Table Name",\n' +
    '  labels: ["Label 1","Label 2","Label 3", ...],\n' +
    '  types: ["TEXT", "NUMBER", "DATETIME", ...],\n' +
    '  rows: [["text", number, "yyyy-MM-DD..."], ["text",number,"yyyy-MM-DD..."], ...]\n' +
    "};",
  VOID: 'return {label: ""}',
  DATETIME:
    'return {label: "", value: "yyyy-MM-dd HH:mm:ss"}; //(Example: 2000-12-31 12:00:00)',
};

function InputActionCode(props) {
  const classes = useStyles();
  const intl = useIntl();
  const { data, setData } = props;
  const [scriptStatus, setScriptStatus] = useState({
    valid: true,
    errorName: undefined,
    errorMessage: undefined,
  });

  useEffect(() => {
    if (data && data.returnType) {
      setData({
        script: data.script,
        returnType: data.returnType,
      });
    } else {
      setData({
        script: DEFAULT_SCRIPT_VALUE.TEXT,
        returnType: RETURN_TYPES.TEXT,
      });
    }
  }, []);

  const onBeforeChange = (e, d, value) => {
    try {
      espree.parse(value, { ecmaFeatures: { globalReturn: true } });
      setScriptStatus({
        valid: true,
        errorName: undefined,
        errorMessage: undefined,
      });
    } catch (error) {
      setScriptStatus({
        valid: false,
        errorName: error.name,
        errorMessage: error.message,
      });
    }
    setData((prevState) => ({ ...prevState, script: value }));
  };

  const onChangeSelectType = (event) => {
    setData({
      script: DEFAULT_SCRIPT_VALUE[event.target.value],
      returnType: event.target.value,
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <FormControl className={classes.formControl}>
          <InputLabel id="return-type-label">
            <FormattedMessage id="extractor.action.script.return.type" />
          </InputLabel>
          <Select
            labelId="return-type-label"
            label="Return Type"
            id="return-type"
            value={data.returnType}
            onChange={onChangeSelectType}
            native
            required
          >
            <option value="TEXT">
              {intl.formatMessage({
                id: "extractor.action.script.return.text",
              })}
            </option>
            <option value="NUMBER">
              {intl.formatMessage({
                id: "extractor.action.script.return.number",
              })}
            </option>
            <option value="DATETIME">
              {intl.formatMessage({
                id: "extractor.action.script.return.datetime",
              })}
            </option>
            <option value="VOID">
              {intl.formatMessage({
                id: "extractor.action.script.return.void",
              })}
            </option>
            <option value="TABLE">
              {intl.formatMessage({
                id: "extractor.action.script.return.table",
              })}
            </option>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        sm={12}
        className={scriptStatus.valid ? "" : classes.invalidBorder}
      >
        <CodeMirror
          className={classes.code}
          value={data.script}
          options={{
            mode: "javascript",
            theme: "material",
            lineNumbers: true,
          }}
          onBeforeChange={(e, d, value) => onBeforeChange(e, d, value)}
        />
      </Grid>
      <Grid item sm={12}>
        {scriptStatus.valid ? (
          <Typography />
        ) : (
          <Typography variant="subtitle2" className={classes.invalid}>
            {`${scriptStatus.errorName}: ${scriptStatus.errorMessage}`}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

InputActionCode.propTypes = {
  setData: PropTypes.func.isRequired,
  data: PropTypes.exact({
    script: PropTypes.string,
    returnType: PropTypes.string,
  }),
};

InputActionCode.defaultProps = {
  data: {
    script: DEFAULT_SCRIPT_VALUE.TEXT,
    returnType: RETURN_TYPES.TEXT,
  },
};

export default InputActionCode;

import { Breadcrumbs, makeStyles, Button } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import React, { useState } from "react";
import { FormattedMessage } from 'react-intl';
import PropTypes, { number } from 'prop-types';

const useStyles = makeStyles(() => ({
  breadcrumb: {
  },
}));

function QueryBreadcrump(props) {
  const classes = useStyles();
  const {
    StepsChange, Right, Querystep5data, StepActive
  } = props;
  const [actualStep, setActualStep] = useState(0);

  const handleStepsChange = (index) => {
    setActualStep(index);
    StepsChange(index);
  };

  return (
    <Breadcrumbs
      className={classes.breadcrumb}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      <Button
        color={StepActive === 1 ? "secondary" : ""}
        onClick={() => { handleStepsChange(0); }}
        underline="none"
        disabled={!(Querystep5data.length > 0)}
      >
        <Typography>
          <FormattedMessage id="query.label.dataset" />
        </Typography>
      </Button>
      <Button
        color={StepActive === 2 ? "secondary" : ""}
        onClick={() => { handleStepsChange(1); }}
        underline="none"
        disabled={!(Right.length > 0)}
      >
        <Typography>
          <FormattedMessage id="query.label.list" />
        </Typography>
      </Button>
      <Button
        color={StepActive === 3 ? "secondary" : ""}
        onClick={() => { handleStepsChange(2); }}
        underline="none"
        disabled={!(Right.length > 0)}
      >
        <Typography>
          <FormattedMessage id="query.label.filter" />
        </Typography>
      </Button>
      <Button
        color={StepActive === 4 ? "secondary" : ""}
        onClick={() => { handleStepsChange(3); }}
        underline="none"
        disabled={!(Querystep5data.length > 0)}
      >
        <Typography>
          <FormattedMessage id="query.label.result" />
        </Typography>
      </Button>
    </Breadcrumbs>
  );
}

QueryBreadcrump.propTypes = {
  // Dataset: PropTypes.arrayOf(PropTypes.object),
  StepsChange: PropTypes.func,
  Right: PropTypes.arrayOf(PropTypes.object),
  Querystep5data: PropTypes.arrayOf(PropTypes.object),
  StepActive: number
};
QueryBreadcrump.defaultProps = {
  // Dataset: [],
  StepsChange: () => {},
  Right: [],
  Querystep5data: [],
  StepActive: number
};

export default QueryBreadcrump;

import React from 'react';
import PropTypes from 'prop-types';
import {
  CardContent, CardMedia, makeStyles, Tooltip, Typography,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  media: {
    maxHeight: '200px',
    margin: 'auto',
  },
}));

function ActionContent(props) {
  const classes = useStyles();
  const { content } = props;

  return (
    <>
      {content.text
        && (
        <CardContent>
          <Tooltip title={content.text}>
            <Typography noWrap variant="subtitle1">
              {content.text}
            </Typography>
          </Tooltip>
        </CardContent>
        )}
      <CardMedia
        className={classes.media}
        component="img"
        image={content.screenshot ? `data:image/png;base64, ${content.screenshot}` : ''}
      />
    </>
  );
}

ActionContent.propTypes = {
  content: PropTypes.shape(PropTypes.object.isRequired),
};

ActionContent.defaultProps = {
  content: { screenshot: undefined, text: undefined },
};

export default ActionContent;

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import LocalStorageService from "../services/LocalStorageService";
import HttpService from "../services/HttpService";

function Logout() {

  const history = useHistory();

  useEffect(() => {
    HttpService.logout(LocalStorageService.getRefreshToken()).finally(() => {
      LocalStorageService.clear();
      history.push("/login");
    });
  }, [history]);

  return <></>;
}

export default Logout;

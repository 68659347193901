import { Breadcrumbs, makeStyles, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import NavigationIcon from '@material-ui/icons/Navigation';
import NavigateNextOutlinedIcon from "@material-ui/icons/NavigateNextOutlined";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";
import StorageOutlinedIcon from "@material-ui/icons/StorageOutlined";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ExtractorService from "../../services/ExtractorService";


const useStyles = makeStyles((theme) => ({
  breadcrumb: {
    flexGrow: 1,
    marginLeft: 30,
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  link: {
    display: "flex",
  },
}));

function Breadcrumb() {
  const history = useHistory();

  const classes = useStyles();
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const finishExtractor = (event) => {
    if (event.target.innerText === "Home") {
      ExtractorService.finish().then(() => {
        history.push("/dashboard");
      });
    }
  };

  useEffect(() => {
    let pathname = '';

    if (location.pathname[location.pathname.length - 1] === "/") {
      pathname = location.pathname.slice(0, pathname.length - 1);
    } else {
      pathname = location.pathname;
    }

    const pathnames = pathname.split("/");
    const newBreadcrumbs = [];

    pathnames.forEach((p) => {
      switch (p) {
        case "":
          newBreadcrumbs.push({
            name: "Home",
            href: "/",
            icon: <HomeOutlinedIcon className={classes.icon} />,
          });
          break;

        case "dashboard":
          break;

        case "extractor":
          newBreadcrumbs.push({
            name: "Extractor",
            href: "/extractor",
            icon: <ListAltOutlinedIcon className={classes.icon} />,
          });
          break;

        case "insertor":
          newBreadcrumbs.push({
            name: "Insertor",
            href: "/insertor",
            icon: <ListAltOutlinedIcon className={classes.icon} />,
          });
          break;

        case "screen-share":
          newBreadcrumbs.push({
            name: "Screen Share",
            href: "/screen-share",
            icon: <NavigationIcon className={classes.icon} />,
          });
          break;

        case "dataset-extractor":
          newBreadcrumbs.push({
            name: "dataset-extractor",
            href: "/dataset-extractor",
            icon: <ListAltOutlinedIcon className={classes.icon} />,
          });
          break;
        case "dataset":
          newBreadcrumbs.push({
            name: "Dataset",
            href: "/dataset",
            icon: <StorageOutlinedIcon className={classes.icon} />,
          });
          break;
        case "query-extractor":
          newBreadcrumbs.push({
            name: "query-extractor",
            href: "/query-extractor",
            icon: <PageviewOutlinedIcon className={classes.icon} />,
          });
          break;
        case "Query":
          newBreadcrumbs.push({
            name: "Query",
            href: "/Query",
            icon: <PageviewOutlinedIcon className={classes.icon} />,
          });
          break;

        case "settings":
          newBreadcrumbs.push({
            name: "Settings",
            href: "/settings",
            icon: <ListAltOutlinedIcon className={classes.icon} />,
          });
          break;
        case "help":
          newBreadcrumbs.push({
            name: "Help",
            href: "/help",
            icon: <HelpOutlineOutlinedIcon className={classes.icon} />,
          });
          break;
        default:
          break;
      }
    });

    setBreadcrumbs(newBreadcrumbs);
  }, [location, classes]);

  return (
    <Breadcrumbs
      className={classes.breadcrumb}
      separator={<NavigateNextOutlinedIcon fontSize="small" />}
      onClick={finishExtractor}
    >
      {breadcrumbs.map((breadcrumb, i) => (
        <Link
          className={classes.link}
          key={breadcrumb.name}
          href={breadcrumb.href}
          underline="none"
          color={breadcrumbs.length === i + 1 ? "secondary" : "primary"}
        >
          {breadcrumb.icon}
          <Typography
            color={breadcrumbs.length === i + 1 ? "secondary" : "primary"}
          >
            {breadcrumb.name}
          </Typography>
        </Link>
      ))}
    </Breadcrumbs>
  );
}

export default Breadcrumb;

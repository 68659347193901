/* eslint-disable max-len */
const errors = {
  "error.100028": "Async Script Timeout",
  "error.100064": "Element Click Intercepted",
  "error.100060": "Element Not Interactable",
  "error.100015": "Element Not Selectable",
  "error.100011": "Element Not Visible",
  "error.100031": "Ime Engine Activation Failed",
  "error.100030": "Ime Not Available",
  "error.100061": "Invalid Argument",
  "error.100024": "Invalid Cookie Domain",
  "error.100029": "Invalid Element Coordinates",
  "error.100012": "Invalid Element State",
  "error.100032": "Invalid Selector Error",
  "error.100051": "Invalid Xpath Selector",
  "error.100052": "Invalid Xpath Selector Return Yyper",
  "error.100017": "Javascript Error",
  "error.100405": "Method Not Allowed",
  "error.100034": "Move Target Out of Bounds",
  "error.100027": "No Alert Present",
  "error.100062": "No Such Cookie",
  "error.100007": "No Such Element",
  "error.100008": "No Such Frame",
  "error.100006": "No Such Session",
  "error.100023": "No Such Window",
  "error.100033": "Session Not Created",
  "error.100010": "Stale Element Reference",
  "error.100021": "Timeout",
  "error.100063": "Unable to Capture Screen",
  "error.100025": "Unable to Set Cookie",
  "error.100026": "Unexpected Alert Present",
  "error.100013": "Unhandled Error",
  "error.100009": "Unknown Command",
  "error.100019": "Xpath Lookup Error",
  "error.100000": "Unexpected Error",
  "error.100001": "Invalid Sequence",
  "error.100002": "Maximum Number of Iterations Exceeded",
  "error.100003": "Max Wait Time Exceeded",
  "error.100004": "Invalid Script",
  "error.while.loading.browser.screenshot": "Error while trying to load the browser screenshot. Please contact the administrator.",
  "error.unknown": "Unknown Error. Please try to refresh the page ({errorMessage}).",
  "error.element.does.not.contain.download.link": "Selected element does not contains a download link. Please select a different type or element."
};

const common = {
  "input.required": "Required",
};

const login = {
  "login.title": "Peerveil",
  "login.subtitle": " ",
  "login.email": "Email Address",
  "login.password": "Password",
  "login.password.must.changed":
    "Your password has expired and must be changed",
  "login.password.will.expire": "Your password will expire in {days} days",
  "login.password.username.incorrect": "Password or username incorrect",
  "login.user.disabled":
    "You must confirm your sign up confirmation received via email before logging in",
  "login.user.expired": "Session has been expired, please sign in again.",
  "login.remember.me": "Remember Me",
  "login.forgot": "Forgot Password?",
  "login.sign.in": "Sign In",
  "login.register": "Not registered yet?",
  "login.registerLink": "Sign Up",
  "login.forgot.password": "Forgot Password?",
  "login.create.account": "Don't have an account? Sign Up",
  "login.back": "Back to login page",
};

const signUp = {
  "signUp.title": "SET",
  "signUp.subtitle": "Create your Account",
  "signUp.email": "Email Address",
  "signUp.password": "Password",
  "signUp.retypePassword": "Retype new password",
  "signUp.create": "Create Account",
  "signUp.firstName": "First Name",
  "signUp.lastName": "Last Name",
};

const changePassword = {
  "changePassword.title": "Change Password",
  "changePassword.newPassword": "New password",
  "changePassword.retypePassword": "Retype new password",
  "changePassword.changePassword": "Change Password",
};

const action = {
  "action.type.user.engine": "Type F-Engine User",
  "action.type.url.engine": "Type F-Engine URL",
  "action.type.password.engine": "Type F-Engine Password",
  "action.type.investor.engine": "Select F-Engine Investor",
  "action.click": "Click",
  "action.type.text": "Type Text",
  "action.type.password": "Type Password",
  "action.ask.f.engine.user": "Ask F-Engine User",
  "action.ask.f.engine.url": "Ask F-Engine URL",
  "action.ask.f.engine.password": "Ask F-Engine Password",
  "action.ask.f.engine.investor": "Ask F-Engine Investor",
  "action.type.f.engine.field": "Type F-Engine Field",
  "action.ask.id": "Ask ID",
  "action.ask.password": "Ask Password",
  "action.ask.code": "Ask Code",
  "action.ask.question": "Ask Question",
  "action.ask.captcha": "Ask Captcha",
  "action.ask.recaptcha": "Ask Recaptcha",
  "action.get.text": "Get Text",
  "action.get.date": "Get Date",
  "action.get.number": "Get Number",
  "action.get.table": "Get Table",
  "action.get.image": "Get Image",
  "action.get.file": "Get File",
  "action.scroll.up": "Scroll Up",
  "action.go.back": "Go Back",
  "action.go.forward": "Go Forward",
  "action.scroll.down": "Scroll Down",
  "action.scroll.top": "Scroll Top",
  "action.scroll.bottom": "Scroll Bottom",
  "action.end": "End",
  "action.mouse.click": "Mouse Click",
  "action.mouse.move": "Mouse Move",
  "action.wait": "Wait",
  "action.raise.error": "Raise Error",
  "action.wait.until.exists": "Wait Until Exists",
  "action.wait.until.not.exists": "Wait Until Not Exists",
  "action.wait.until.displayed": "Wait Until Displayed",
  "action.wait.until.not.displayed": "Wait Until Not Displayed",
  "action.wait.until.enabled": "Wait Until Enabled",
  "action.wait.until.not.enabled": "Wait Until Not Enabled",
  "action.click.if.exists": "Click If Exists",
  "action.click.if.enabled": "Click If Enabled",
  "action.click.select": "Click Select",
  "action.click.if.displayed": "Click If Displayed",
  "action.if.exists": "If Exists",
  "action.if.not.exists": "If Not Exists",
  "action.if.displayed": "If Displayed",
  "action.if.not.displayed": "If Not Displayed",
  "action.if.enabled": "If Enabled",
  "action.if.not.enabled": "If Not Enabled",
  "action.if.condition": "If Condition",
  "action.move": "Move",
  "action.unfinished.content": "Please click on an element with dotted border",
  "action.unfinished.content.default": " ",
  "action.unfinished.content.mouse": "Plese click on the desired position",
  "action.unfinished.content.investor": "Please Select an Option",
  "action.edit": "Edit Action",
  "action.editsequence": "Edit Sequence",
  "action.navigate": "Navigate",
  "action.script": "Script",
  "action.adaptable.click": "Adaptable Click",
  "action.remove": "Remove Action",
  "action.cancel": "Cancel Action",
  "action.mask.submit": "Test",
  "action.mask.result": "Result : ",
  "action.table.see.columns": "See Columns",
  "action.validate.warning": "This action has not been validated",
  "action.wait.seconds": "Seconds",
  "action.raise.error.message": "Message",
};

const extractor = {
  "extractor.finishconfirm":
    "Are you sure you want to close? If you don't save, changes will be lost.",
  "extractor.change.action.success": "Action has changed successfully",
  "extractor.change.action.error": "Error to change action",
  "extractor.reload.error": "Error to reload extractor",
  "extractor.screenshot.error": "Error to get screenshot",
  "extractor.normalized.error": "Error to get normalized data",
  "extractor.execute.error": "Error to execute action",
  "extractor.must.validate": "Please, validate the extractor",
  "extractor.finish.error": "Error to finish",
  "extractor.save.success": "Saved successfully",
  "extractor.save.error": "Error to save extractor",
  "extractor.first.action.delete.warning": "You can't delete the first action",
  "extractor.first.action.change.warning": "You can't change the first action",
  "extractor.edition.info": "Edition is not allowed for this action",
  "extractor.sequence.change.success": "Sequence changed successfully",
  "extractor.sequence.change.error": "Error to change sequence",
  "extractor.sequence.change.warning":
    "The new sequence must be greater than the first sequence",
  "extractor.resequence.error": "Error to resequence",
  "extractor.validate.success": "Validation complete successfully",
  "extractor.close": "Close",
  "extractor.save": "Save",
  "extractor.reload": "Refresh",
  "extractor.action.adaptable.click": "Adaptable Click",
  "extractor.action.other": "Adaptable Click",
  "extractor.action.inputable.text": "Adaptable Click",
  "extractor.action.inputable.password": "Adaptable Click",
  "extractor.action.navigate": "Navigate",
  "extractor.action.help.navigate": "Enter the url, starting with http:// or https:// otherwise the value will be considered a Google Search",
  "extractor.action.script": "Script",
  "extractor.action.click": "Click",
  "extractor.action.type.text": "Type Value",
  "extractor.action.help.type.text": "Select the field in which you want to type a value.\n\nFor text fields, the value will be typed directly into the field.\n\nFor radio buttons, click on any radio button in the group and the radio button matching the provided value will be selected.\n\nFor checkboxes, if the value is true or yes, it will be checked.",
  "extractor.action.type.password": "Type Password",
  "extractor.action.ask.f.engine.url": "Ask F-Engine URL",
  "extractor.action.ask.f.engine.user": "Ask F-Engine User",
  "extractor.action.ask.f.engine.password": "Ask F-Engine Password",
  "extractor.action.ask.f.engine.investor": "Ask F-Engine Investor",
  "extractor.action.help.ask.f.engine.investor": "On the next dialog, you'll be prompted to choose an investor",
  "extractor.action.type.f.engine.field": "Type F-Engine Field",
  "extractor.action.help.type.f.engine.field": "Select the field in which you want to type a value from F-Engine.\n\nFor text fields, the value will be typed directly into the field.\n\nFor radio buttons, click on any radio button in the group and the radio button matching the provided value will be selected.\n\nFor checkboxes, if the value is true or yes, it will be checked.",
  "extractor.action.ask.id": "Ask Id",
  "extractor.action.ask.password": "Ask Password",
  "extractor.action.ask.code": "Ask Code",
  "extractor.action.ask.question": "Ask Question",
  "extractor.action.ask.captcha": "Ask Captcha",
  "extractor.action.ask.recaptcha": "Ask ReCaptcha",
  "extractor.action.get.text": "Get Text",
  "extractor.action.get.date": "Get Date",
  "extractor.action.get.number": "Get Number",
  "extractor.action.get.table": "Get Table",
  "extractor.action.get.image": "Get Image",
  "extractor.action.get.file": "Get File",
  "extractor.action.scroll.up": "Scroll Up",
  "extractor.action.go.back": "Go Back",
  "extractor.action.go.forward": "Go Forward",
  "extractor.action.scroll.down": "Scroll Down",
  "extractor.action.scroll.top": "Scroll Top",
  "extractor.action.scroll.bottom": "Scroll Bottom",
  "extractor.action.end": "End",
  "extractor.action.mouse.click": "Mouse Click",
  "extractor.action.mouse.move": "Mouse Move",
  "extractor.action.wait": "Wait",
  "extractor.action.raise.error": "Raise Error",
  "extractor.action.move": "Move",
  "extractor.action.wait.until.exists": "Wait Until Exists",
  "extractor.action.wait.until.not.exists": "Wait Until Not Exists",
  "extractor.action.wait.until.displayed": "Wait Until Displayed",
  "extractor.action.wait.until.not.displayed": "Wait Until Not Displayed",
  "extractor.action.wait.until.enabled": "Wait Until Enabled",
  "extractor.action.wait.until.not.enabled": "Wait Until Not Enabled",
  "extractor.action.click.if.exists": "Click If Exists",
  "extractor.action.click.if.enabled": "Click If Enabled",
  "extractor.action.click.select": "Click Select",
  "extractor.action.click.if.displayed": "Click If Displayed",
  "extractor.action.modal.title.click": "Confirm Click?",
  "extractor.action.modal.title.type.text": "Type Text",
  "extractor.action.modal.title.type.password": "Type Password",
  "extractor.action.modal.title.ask.id": "Ask ID",
  "extractor.action.modal.title.ask.password": "Ask Password",
  "extractor.action.modal.title.ask.code": "Ask Code",
  "extractor.action.modal.title.get.text": "Confirm Get Text?",
  "extractor.action.if.exists": "If Exists",
  "extractor.action.if.not.exists": "If Not Exists",
  "extractor.action.if.displayed": "If Displayed",
  "extractor.action.if.not.displayed": "If Not Displayed",
  "extractor.action.if.enabled": "If Enabled",
  "extractor.action.if.not.enabled": "If Not Enabled",
  "extractor.action.if.condition": "If Condition",
  "extractor.action.if.condition.equal": "Equal",
  "extractor.action.if.condition.not_equal": "Not Equal",
  "extractor.action.if.condition.contains": "Contains",
  "extractor.action.if.condition.not_contains": "Not Contains",
  "extractor.action.if.condition.greater_than": "Greater Than",
  "extractor.action.if.condition.less_than": "Less Than",
  "extractor.action.if.condition.greater_equal_than": "Greater Equal Than",
  "extractor.action.if.condition.less_equal_than": "Less Equal Than",
  "extractor.action.modal.label": "Label",
  "extractor.action.modal.title.confirm": "Confirm ",
  "extractor.action.label.password": "Password",
  "extractor.action.get.table.label": "Label",
  "extractor.action.get.table.type": "Type",
  "extractor.action.get.table.text": "Text",
  "extractor.action.get.table.number": "Number",
  "extractor.action.get.table.datetime": "Datetime",
  "extractor.action.get.table.file": "File",
  "extractor.action.get.table.instructions.first":
    "Click on an element with dotted border and use Up and Down to select the table",
  "extractor.action.get.table.instructions.second":
    "Click on an element with dotted border and use Up and Down to select the initial row",
  "extractor.action.get.table.instructions.third":
    "Click on an element with dotted border to select the columns",
  "extractor.action.get.table.select.first": "Select table",
  "extractor.action.get.table.select.second": "Select row",
  "extractor.action.get.table.select.third": "Select columns",
  "extractor.action.get.table.column.selected": "Colunm is already selected",
  "extractor.action.get.table.column.another": "Please select another column",
  "extractor.list.add": "Add",
  "extractor.list.modal.title.add": "Add New Extractor",
  "extractor.list.modal.title.rename": "Rename",
  "extractor.list.modal.name": "Name",
  "extractor.list.modal.description": "Description",
  "extractor.list.error.get": "Error to get extractors",
  "extractor.list.error.execute": "Error to execute the extractor",
  "extractor.list.pending.ask": "You need wait and response Ask actions",
  "extractor.list.error.name": "Name is required",
  "extractor.list.error.description": "Description is required",
  "extractor.list.error.unique":
    "This name already exists, please choose a different one.",
  "extractor.list.info": "Info about Extractor",
  "extractor.list.add.new": "Add New",
  "extractor.list.success": "Success",
  "extractor.action.script.return.type": "Return Type",
  "extractor.action.script.return.text": "Text",
  "extractor.action.script.return.number": "Number",
  "extractor.action.script.return.table": "Table",
  "extractor.action.script.return.datetime": "Datetime",
  "extractor.action.script.return.void": "Void",
  "extractor.action.script.title": "Script",
  "extractor.modal.delete.text": "Are you sure you want to delete the selected extractor?",
  "extractor.modal.confirm.save.extractor": "If this extractor is referenced by a dataset, the reference will be removed and editing the dataset will be required. Would you like to proceed?"
};

const insertor = {
  "insertor.finishconfirm":
    "Are you sure you want to close? If you don't save, changes will be lost.",
  "insertor.change.action.success": "Action has changed successfully",
  "insertor.change.action.error": "Error to change action",
  "insertor.reload.error": "Error to reload insertor",
  "insertor.screenshot.error": "Error to get screenshot",
  "insertor.normalized.error": "Error to get normalized data",
  "insertor.execute.error": "Error to execute action",
  "insertor.must.validate": "Please, validate the insertor",
  "insertor.finish.error": "Error to finish",
  "insertor.save.success": "Saved successfully",
  "insertor.save.error": "Error to save insertor",
  "insertor.first.action.delete.warning": "You can't delete the first action",
  "insertor.first.action.change.warning": "You can't change the first action",
  "insertor.edition.info": "Edition is not allowed for this action",
  "insertor.sequence.change.success": "Sequence changed successfully",
  "insertor.sequence.change.error": "Error to change sequence",
  "insertor.sequence.change.warning":
    "The new sequence must be greater than the first sequence",
  "insertor.resequence.error": "Error to resequence",
  "insertor.validate.success": "Validation complete successfully",
  "insertor.close": "Close",
  "insertor.save": "Save",
  "insertor.reload": "Refresh",
  "insertor.action.adaptable.click": "Adaptable Click",
  "insertor.action.other": "Adaptable Click",
  "insertor.action.inputable.text": "Adaptable Click",
  "insertor.action.inputable.password": "Adaptable Click",
  "insertor.action.navigate": "Navigate",
  "insertor.action.script": "Script",
  "insertor.action.click": "Click",
  "insertor.action.type.text": "Type Text",
  "insertor.action.type.password": "Type Password",
  "insertor.action.ask.f.engine.url": "Ask F-Engine URL",
  "insertor.action.ask.f.engine.user": "Ask F-Engine User",
  "insertor.action.ask.f.engine.password": "Ask F-Engine Password",
  "insertor.action.ask.f.engine.investor": "Ask F-Engine Investor",
  "insertor.action.type.f.engine.field": "Type F-Engine Field",
  "insertor.action.ask.id": "Ask Id",
  "insertor.action.ask.password": "Ask Password",
  "insertor.action.ask.code": "Ask Code",
  "insertor.action.ask.question": "Ask Question",
  "insertor.action.ask.captcha": "Ask Captcha",
  "insertor.action.ask.recaptcha": "Ask ReCaptcha",
  "insertor.action.get.text": "Get Text",
  "insertor.action.get.date": "Get Date",
  "insertor.action.get.number": "Get Number",
  "insertor.action.get.table": "Get Table",
  "insertor.action.get.image": "Get Image",
  "insertor.action.get.file": "Get File",
  "insertor.action.scroll.up": "Scroll Up",
  "insertor.action.go.back": "Go Back",
  "insertor.action.go.forward": "Go Forward",
  "insertor.action.scroll.down": "Scroll Down",
  "insertor.action.scroll.top": "Scroll Top",
  "insertor.action.scroll.bottom": "Scroll Bottom",
  "insertor.action.end": "End",
  "insertor.action.mouse.click": "Mouse Click",
  "insertor.action.mouse.move": "Mouse Move",
  "insertor.action.wait": "Wait",
  "insertor.action.raise.error": "Raise Error",
  "insertor.action.move": "Move",
  "insertor.action.wait.until.exists": "Wait Until Exists",
  "insertor.action.wait.until.not.exists": "Wait Until Not Exists",
  "insertor.action.wait.until.displayed": "Wait Until Displayed",
  "insertor.action.wait.until.not.displayed": "Wait Until Not Displayed",
  "insertor.action.wait.until.enabled": "Wait Until Enabled",
  "insertor.action.wait.until.not.enabled": "Wait Until Not Enabled",
  "insertor.action.click.if.exists": "Click If Exists",
  "insertor.action.click.if.enabled": "Click If Enabled",
  "insertor.action.click.select": "Click Select",
  "insertor.action.click.if.displayed": "Click If Displayed",
  "insertor.action.modal.title.click": "Confirm Click?",
  "insertor.action.modal.title.type.text": "Type Text",
  "insertor.action.modal.title.type.password": "Type Password",
  "insertor.action.modal.title.ask.id": "Ask ID",
  "insertor.action.modal.title.ask.password": "Ask Password",
  "insertor.action.modal.title.ask.code": "Ask Code",
  "insertor.action.modal.title.get.text": "Confirm Get Text?",
  "insertor.action.if.exists": "If Exists",
  "insertor.action.if.not.exists": "If Not Exists",
  "insertor.action.if.displayed": "If Displayed",
  "insertor.action.if.not.displayed": "If Not Displayed",
  "insertor.action.if.enabled": "If Enabled",
  "insertor.action.if.not.enabled": "If Not Enabled",
  "insertor.action.if.condition": "If Condition",
  "insertor.action.if.condition.equal": "Equal",
  "insertor.action.if.condition.not_equal": "Not Equal",
  "insertor.action.if.condition.contains": "Contains",
  "insertor.action.if.condition.not_contains": "Not Contains",
  "insertor.action.if.condition.greater_than": "Greater Than",
  "insertor.action.if.condition.less_than": "Less Than",
  "insertor.action.if.condition.greater_equal_than": "Greater Equal Than",
  "insertor.action.if.condition.less_equal_than": "Less Equal Than",
  "insertor.action.modal.label": "Label",
  "insertor.action.modal.title.confirm": "Confirm ",
  "insertor.action.label.password": "Password",
  "insertor.action.get.table.label": "Label",
  "insertor.action.get.table.type": "Type",
  "insertor.action.get.table.text": "Text",
  "insertor.action.get.table.number": "Number",
  "insertor.action.get.table.datetime": "Datetime",
  "insertor.action.get.table.instructions.first":
    "Click on an element with dotted border and use Up and Down to select the table",
  "insertor.action.get.table.instructions.second":
    "Click on an element with dotted border and use Up and Down to select the initial row",
  "insertor.action.get.table.instructions.third":
    "Click on an element with dotted border to select the columns",
  "insertor.action.get.table.select.first": "Select table",
  "insertor.action.get.table.select.second": "Select row",
  "insertor.action.get.table.select.third": "Select columns",
  "insertor.action.get.table.column.selected": "Colunm is already selected",
  "insertor.action.get.table.column.another": "Please select another column",
  "insertor.list.add": "Add",
  "insertor.list.modal.title.add": "Add New Insertor",
  "insertor.list.modal.title.rename": "Rename",
  "insertor.list.modal.name": "Name",
  "insertor.list.modal.description": "Description",
  "insertor.list.error.get": "Error to get insertors",
  "insertor.list.error.execute": "Error to execute the insertor",
  "insertor.list.pending.ask": "You need wait and response Ask actions",
  "insertor.list.error.name": "Name is required",
  "insertor.list.error.description": "Description is required",
  "insertor.list.error.unique":
    "This name already exists, please choose a different one.",
  "insertor.list.info": "Info about Insertor",
  "insertor.list.add.new": "Add New",
  "insertor.list.success": "Success",
  "insertor.action.script.return.type": "Return Type",
  "insertor.action.script.return.text": "Text",
  "insertor.action.script.return.number": "Number",
  "insertor.action.script.return.table": "Table",
  "insertor.action.script.return.datetime": "Datetime",
  "insertor.action.script.return.void": "Void",
  "insertor.action.script.title": "Script",
  "insertor.modal.delete.text": "Are you sure you want to delete the selected insertor?"
};

const navigator = {
  "navigator.finishconfirm":
    "All steps were automatically saved. Do you want to stop the Screen Share now?",
  "navigator.change.action.success": "Action has changed successfully",
  "navigator.change.action.error": "Error to change action",
  "navigator.reload.error": "Error to reload Screen Share",
  "navigator.screenshot.error": "Error to get screenshot",
  "navigator.normalized.error": "Error to get normalized data",
  "navigator.execute.error": "Error to execute action",
  "navigator.must.validate": "Please, validate the Screen Share",
  "navigator.finish.error": "Error to finish",
  "navigator.save.success": "Saved successfully",
  "navigator.save.error": "Error to save Screen Share",
  "navigator.first.action.delete.warning": "You can't delete the first action",
  "navigator.first.action.change.warning": "You can't change the first action",
  "navigator.edition.info": "Edition is not allowed for this action",
  "navigator.sequence.change.success": "Sequence changed successfully",
  "navigator.sequence.change.error": "Error to change sequence",
  "navigator.sequence.change.warning":
    "The new sequence must be greater than the first sequence",
  "navigator.resequence.error": "Error to resequence",
  "navigator.validate.success": "Validation complete successfully",
  "navigator.close": "Close",
  "navigator.save": "Save",
  "navigator.reload": "Refresh",
  "navigator.action.adaptable.click": "Adaptable Click",
  "navigator.action.other": "Adaptable Click",
  "navigator.action.inputable.text": "Adaptable Click",
  "navigator.action.inputable.password": "Adaptable Click",
  "navigator.action.navigate": "Navigate",
  "navigator.action.help.navigate": "Enter the url, starting with http:// or https:// otherwise the value will be considered a Google Search",
  "navigator.action.script": "Script",
  "navigator.action.click": "Click",
  "navigator.action.type.text": "Type Value",
  "navigator.action.help.type.text": "Select the field in which you want to type a value.\n\nFor text fields, the value will be typed directly into the field.\n\nFor radio buttons, click on any radio button in the group and the radio button matching the provided value will be selected.\n\nFor checkboxes, if the value is true or yes, it will be checked.",
  "navigator.action.type.password": "Type Password",
  "navigator.action.ask.f.engine.url": "Ask F-Engine URL",
  "navigator.action.ask.f.engine.user": "Ask F-Engine User",
  "navigator.action.ask.f.engine.password": "Ask F-Engine Password",
  "navigator.action.ask.f.engine.investor": "Ask F-Engine Investor",
  "navigator.action.help.ask.f.engine.investor": "On the next dialog, you'll be prompted to choose an investor",
  "navigator.action.type.f.engine.field": "Type F-Engine Field",
  "navigator.action.help.type.f.engine.field": "Select the field in which you want to type a value from F-Engine.\n\nFor text fields, the value will be typed directly into the field.\n\nFor radio buttons, click on any radio button in the group and the radio button matching the provided value will be selected.\n\nFor checkboxes, if the value is true or yes, it will be checked.",
  "navigator.action.ask.id": "Ask Id",
  "navigator.action.ask.password": "Ask Password",
  "navigator.action.ask.code": "Ask Code",
  "navigator.action.ask.question": "Ask Question",
  "navigator.action.ask.captcha": "Ask Captcha",
  "navigator.action.ask.recaptcha": "Ask ReCaptcha",
  "navigator.action.get.text": "Get Text",
  "navigator.action.get.date": "Get Date",
  "navigator.action.get.number": "Get Number",
  "navigator.action.get.table": "Get Table",
  "navigator.action.get.image": "Get Image",
  "navigator.action.get.file": "Get File",
  "navigator.action.scroll.up": "Scroll Up",
  "navigator.action.go.back": "Go Back",
  "navigator.action.go.forward": "Go Forward",
  "navigator.action.scroll.down": "Scroll Down",
  "navigator.action.scroll.top": "Scroll Top",
  "navigator.action.scroll.bottom": "Scroll Bottom",
  "navigator.action.end": "End",
  "navigator.action.mouse.click": "Mouse Click",
  "navigator.action.mouse.move": "Mouse Move",
  "navigator.action.wait": "Wait",
  "navigator.action.raise.error": "Raise Error",
  "navigator.action.move": "Move",
  "navigator.action.wait.until.exists": "Wait Until Exists",
  "navigator.action.wait.until.not.exists": "Wait Until Not Exists",
  "navigator.action.wait.until.displayed": "Wait Until Displayed",
  "navigator.action.wait.until.not.displayed": "Wait Until Not Displayed",
  "navigator.action.wait.until.enabled": "Wait Until Enabled",
  "navigator.action.wait.until.not.enabled": "Wait Until Not Enabled",
  "navigator.action.click.if.exists": "Click If Exists",
  "navigator.action.click.if.enabled": "Click If Enabled",
  "navigator.action.click.select": "Click Select",
  "navigator.action.click.if.displayed": "Click If Displayed",
  "navigator.action.modal.title.click": "Confirm Click?",
  "navigator.action.modal.title.type.text": "Type Text",
  "navigator.action.modal.title.type.password": "Type Password",
  "navigator.action.modal.title.ask.id": "Ask ID",
  "navigator.action.modal.title.ask.password": "Ask Password",
  "navigator.action.modal.title.ask.code": "Ask Code",
  "navigator.action.modal.title.get.text": "Confirm Get Text?",
  "navigator.action.if.exists": "If Exists",
  "navigator.action.if.not.exists": "If Not Exists",
  "navigator.action.if.displayed": "If Displayed",
  "navigator.action.if.not.displayed": "If Not Displayed",
  "navigator.action.if.enabled": "If Enabled",
  "navigator.action.if.not.enabled": "If Not Enabled",
  "navigator.action.if.condition": "If Condition",
  "navigator.action.if.condition.equal": "Equal",
  "navigator.action.if.condition.not_equal": "Not Equal",
  "navigator.action.if.condition.contains": "Contains",
  "navigator.action.if.condition.not_contains": "Not Contains",
  "navigator.action.if.condition.greater_than": "Greater Than",
  "navigator.action.if.condition.less_than": "Less Than",
  "navigator.action.if.condition.greater_equal_than": "Greater Equal Than",
  "navigator.action.if.condition.less_equal_than": "Less Equal Than",
  "navigator.action.modal.label": "Label",
  "navigator.action.modal.title.confirm": "Confirm ",
  "navigator.action.label.password": "Password",
  "navigator.action.get.table.label": "Label",
  "navigator.action.get.table.type": "Type",
  "navigator.action.get.table.text": "Text",
  "navigator.action.get.table.number": "Number",
  "navigator.action.get.table.datetime": "Datetime",
  "navigator.action.get.table.file": "File",
  "navigator.action.get.table.instructions.first":
    "Click on an element with dotted border and use Up and Down to select the table",
  "navigator.action.get.table.instructions.second":
    "Click on an element with dotted border and use Up and Down to select the initial row",
  "navigator.action.get.table.instructions.third":
    "Click on an element with dotted border to select the columns",
  "navigator.action.get.table.select.first": "Select table",
  "navigator.action.get.table.select.second": "Select row",
  "navigator.action.get.table.select.third": "Select columns",
  "navigator.action.get.table.column.selected": "Column is already selected",
  "navigator.action.get.table.column.another": "Please select another column",
  "navigator.list.add": "Add",
  "navigator.list.modal.title.add": "Add New Screen Share",
  "navigator.list.modal.title.rename": "Rename",
  "navigator.list.modal.name": "Name",
  "navigator.list.modal.description": "Description",
  "navigator.list.modal.viewerEmail": "Viewer Email",
  "navigator.list.error.get": "Error to get Screen Shares",
  "navigator.list.error.execute": "Error to execute the Screen Share",
  "navigator.list.pending.ask": "You need wait and response Ask actions",
  "navigator.list.error.name": "Name is required",
  "navigator.list.error.description": "Description is required",
  "navigator.list.error.viewerEmail": "Viewer Email is required",
  "navigator.list.error.unique":
    "This name already exists, please choose a different one.",
  "navigator.list.info": "Info about Screen Share",
  "navigator.list.add.new": "Add New",
  "navigator.list.success": "Success",
  "navigator.action.script.return.type": "Return Type",
  "navigator.action.script.return.text": "Text",
  "navigator.action.script.return.number": "Number",
  "navigator.action.script.return.table": "Table",
  "navigator.action.script.return.datetime": "Datetime",
  "navigator.action.script.return.void": "Void",
  "navigator.action.script.title": "Script",
  "navigator.modal.delete.text": "Are you sure you want to delete the selected Screen Share?",
  "navigator.close.confirm": "Are you sure you want to close?",
  "navigator.viewer.screen": "Screen",
  "navigator.viewer.html": "HTML",
  "navigator.viewer.file": "File",
  "navigator.download": "Download file",
  "navigator.download.message": "This file can only be downloaded. Please click on the download button to download this file.",
  "navigator.attribute.user": "User:",
  "navigator.attribute.navigator": "Screen Share:",
  "navigator.attribute.timestamp": "Timestamp:",
  "navigator.attribute.url": "URL:",
};

const dataset = {
  "dataset.title": "Dataset",
  "dataset.list.modal.title.add": "Add New Dataset",
  "dataset.list.modal.title.rename": "Rename Dataset",
  "dataset.fields.Name": "Name",
  "dataset.fields.Type": "Type",
  "dataset.fields.key": "Key",
  "dataset.fields.Table.Name": "Dataset",
  "dataset.define": "Define the Dataset fields",
  "dataset.label": "Label and/or table-column",
  "dataset.label.text": "Text",
  "dataset.label.date": "Datetime",
  "dataset.label.number": "Number",
  "dataset.label.table": "Table",
  "dataset.label.new": "Add new Dataset",
  "dataset.key": "Key? :",
  "dataset.add": "Add",
  "dataset.next": "Next",
  "dataset.save": "Save",
  "dataset.cancel": "Cancel",
  "dataset.previous": "Previous",
  "dataset.add.selected": "Add Selected",
  "dataset.remove.selected": "Remove Selected",
  "dataset.name.error": "Enter Dataset Fields Name",
  "dataset.name.differ.error":
    "This name already exists, please choose a different one.",
  "dataset.list.error.get": "Error to get Datasets",
  "dataset.step1": "Define the Dataset fields",
  "dataset.step2": "Select the labels",
  "dataset.step3": "Map fields with Extractor",
  "dataset.steps.error": "Unable to go to next step",
  "dataset.modal.delete.text": "Are you sure you want to delete the selected dataset?",
  "dataset.list.modal.type": "Type",
  "dataset.list.modal.type.custom": "Custom",
  "dataset.list.modal.type.fEngine": "F-Engine",
  "dataset.list.modal.type.fEngineSubAccount": "Sub Account",
  "dataset.list.modal.type.fEngineFile": "File",
  "dataset.list.modal.type.fEngineTransaction": "Transaction",
  "dataset.list.modal.type.fEngineFund": "Fund",
  "dataset.list.modal.type.fEngineFundTransaction": "Fund Transaction",
};

const scheduler = {
  "scheduler.label.step1": "Step 1 - Identify your scheduler.",
  "scheduler.label.step2": "Step 2 - Scheduler parameters.",
  "scheduler.list.modal.title.rename": "Rename scheduler",
  "extractor.form.label.daymonth": "Day of Month",
  "extractor.form.label.hour": "Hour",
  "extractor.form.label.minute": "Minute",
  "extractor.form.label.intervaltime": "Interval",
  "scheduler.next": "Next",
  "scheduler.back": "Back",
  "scheduler.list.error.get": "Error to get Schedulers",
  "scheduler.save.success": "Saved successfully",
  "scheduler.save.error": "Error to save scheduler",
  "scheduler.save.conflict": "Scheduler already exists",
  "scheduler.modal.delete.text": "Are you sure you want to delete the selected scheduler?"
}

const Query = {
  "query.label.new": "Add new Query",
  "query.label.rename": "Rename Query",
  "query.label.dataset": "Select a Dataset",
  "query.label.list": "Dataset fields to list",
  "query.label.filter": "Dataset fields to filter",
  "query.label.result": "Query results",
  "query.label.selected": "Fields selected",
  "query.modal.delete.text": "Are you sure you want to delete the selected query?"
};

const getTable = {
  "getTable.allColumns": "View Columns",
  "get.table.up": "Up",
  "get.table.down": "Down",
  "get.table.next": "Next",
  "get.table.cancel": "Cancel",
  "get.table.drawer.title": "Get table - ",
  "get.table.drawer.title.table": "Select table",
  "get.table.drawer.title.row": "Select row",
  "get.table.drawer.title.columns": "Select columns",
  "get.table.finish": "Finish",
};

const dashboard = {
  "dashboard.alert": "Alert",
  "dashboard.dataset": "Dataset",
  "dashboard.scheduler": "Scheduler",
  "dashboard.extractor": "Data Extractor",
  "dashboard.insertor": "Data Insertor",
  "dashboard.navigator": "Screen Share",
  "dashboard.notification": "Notification",
  "dashboard.query": "Query",
  "dashboard.schedule": "Schedule",
  "dashboard.execute": "Execute",
  "dashboard.copy": "Copy",
  "dashboard.rename": "Rename",
  "dashboard.edit": "Edit",
  "dashboard.remove": "Remove",
  "dashboard.details": "Details",
  "dashboard.modal.ask.value": "Value",
  "dashboard.modal.ask.safe": "Save for next time",
  "dataset.action.get.text": "Text",
  "dataset.action.get.date": "Datetime",
  "dataset.action.get.number": "Number",
  "dataset.action.get.file": "File",
  "dataset.action.get.table": "Table",
};

const genericModal = {
  "generic.modal.cancel": "Cancel",
  "generic.modal.enter": "Enter",
  "generic.modal.confirm": "Confirm",
  "generic.modal.edition": "Edition",
  "generic.modal.next": "Next"
};

const help = {
  "help.release.note.peerveil.datainsertor": "Release Note Data Insertor",
  "help.release.note.peerveil.extractor": "Release Note Extractor",
  "help.release.note.peerveil.screenshare": "Release Note ScreenShare"
};

export default {
  ...errors,
  ...login,
  ...signUp,
  ...changePassword,
  ...action,
  ...extractor,
  ...insertor,
  ...navigator,
  ...getTable,
  ...dashboard,
  ...genericModal,
  ...common,
  ...dataset,
  ...scheduler,
  ...Query,
  ...help,
  "settings.changePassword": "Change Password",
  "forgotPassword.title": "Peerveil",
  "forgotPassword.subtitle": "Forgot Password",
  "forgotPassword.email": "Email Address",
  "forgotPassword.submit": "Confirm",
  "forgotPassword.message": "Please, check your email. We have sent to you a temporary password on your e-mail.",
  "forgotPassword.return": "Return to log in page",
  "app.toolbar.settings": "Settings",
  "app.toolbar.help": "Help",
  "app.toolbar.logout": "Logout",
  "dataset.title": "Dataset",
  "query.title": "Query",
  "list.actions.edit.sequence": "Edit sequence",
  "select.action.title": "Action",
  "select.action.add": "Add action",
  "select.action.validate": "Validate",
  "select.action.resequence": "Resequence",
  "validate.progress": "Validation in progress",
  "validate.success": "Validation finished successfully",
  "validate.error": "Validation finished with error",
  "select.sequence": "Sequence",
  "go.to": "Go To",
  "title.welcome": "Thank you for signing in",
  "verify.welcome": "Verify your email inbox",
};

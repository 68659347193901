import HttpService from './HttpService';

const QueryService = {

  createQuerystep: (QueryData) => HttpService.createQuery(QueryData),

  getDataset: () => HttpService.getDataset(),

  datasetFieldQuery: (QueryObject) => HttpService.datasetFieldQuery(QueryObject),

  datasetFieldQueryForFilter: (QueryObject) => HttpService.datasetFieldQueryForFilter(QueryObject),

  datasetoperator: () => HttpService.datasetoperator(),

  fieldselected: (Querystep3data) => HttpService.fieldselected(Querystep3data),

  fieldoperator: (Querystep4data) => HttpService.fieldoperator(Querystep4data),

  Queryresult: () => HttpService.Queryresult(),

  filterinputvalue: (filterobject) => HttpService.filterinputvalue(filterobject),

  cancelQuery: () => HttpService.cancelQuery(),

  showExtractorData: () => HttpService.showExtractorData(),

  QueryDataset: (Dataset) => HttpService.QueryDataset(Dataset),

  QuerySave: (id) => HttpService.QuerySave(id),

  QueryDashboard: () => HttpService.QueryDashboard(),

  QueryEdit: (id) => HttpService.QueryEdit(id),

  QueryRename: (queryObject) => HttpService.QueryRename(queryObject),

  QueryDelete: (Qid) => HttpService.QueryDelete(Qid),

  QueryRemove: (QueryId, FieldId) => HttpService.QueryRemove(QueryId, FieldId),

  QueryGetLinkExport: () => HttpService.QueryGetLinkExport(),

  getFile: (token) => HttpService.getFile(token),

};

export default QueryService;

import { CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';

function ActionError() {
  return (
    <CardContent>
      <Typography>
        <FormattedMessage id="action.validate.warning" />
      </Typography>
    </CardContent>
  );
}

export default ActionError;

import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import DatasetService from "../../services/DatasetService";
import Dataset from "../../models/enums/Dataset";
import DatasetType from "../../models/enums/DatasetType";

const defaultState = {
  type: DatasetType.CUSTOM,
  name: "",
  description: "",
  nameError: "",
  descriptionError: "",
  rename: false,
};

function DataSetExtractor() {
  const history = useHistory();
  const intl = useIntl();
  const [DatasetModal, setDatasetModal] = useState(defaultState);
  const [showFEngineType, setShowFEngineType] = useState(false);
  const [fEngineType, setFEngineType] = useState('SUB_ACCOUNT');

  const cancel = () => {
    history.goBack();
  };

  const handleFEngineTypeModal = (event) => {
     const fEngineTypeValue = event.target.value;
     setFEngineType(fEngineTypeValue);
     setDatasetModal((prevState) => ({
       ...prevState,
       type: 'FENGINE_' + fEngineTypeValue
     }));
  };
  const handleTypeModal = (event) => {
    const typeValue = event.target.value;
    if (typeValue === DatasetType.CUSTOM) {
        setDatasetModal((prevState) => ({
           ...prevState,
           type: DatasetType.CUSTOM,
         }));
         setShowFEngineType(false);
    } else {
        setShowFEngineType(true);
        setDatasetModal((prevState) => ({
           ...prevState,
           type: 'FENGINE_' + fEngineType,
         }));
    }
  };

  const handleNameModal = (event) =>
    setDatasetModal((prevState) => ({
      ...prevState,
      name: event.target.value,
    }));
  const handleDescriptionModal = (event) =>
    setDatasetModal((prevState) => ({
      ...prevState,
      description: event.target.value,
    }));

  const clickToDataset = () => {
    let success = true;
    let newState = { ...DatasetModal, nameError: "", descriptionError: "" };
    if (DatasetModal.name.trim().length === 0) {
      success = false;
      newState = {
        ...newState,
        nameError: intl.formatMessage({ id: "extractor.list.error.name" }),
      };
      setDatasetModal(newState);
    }

    if (DatasetModal.description.trim().length === 0) {
      success = false;
      newState = {
        ...newState,
        descriptionError: intl.formatMessage({
          id: "extractor.list.error.description",
        }),
      };
      setDatasetModal(newState);
    }

    if (success) {
      // history.push({
      //   pathname: "/dataset",
      //   dataProps: {
      //     name: DatasetModal.name,
      //     data: DatasetModal.description,
      //   },
      // });
      setDatasetModal(newState);
      const dataset = new Dataset(DatasetModal.type, DatasetModal.name, DatasetModal.description);
      DatasetService.createDataset(dataset)
        .then((res) => {
          history.push({
            pathname: "/dataset",
            dataProps: {
              id: res.data.id,
              type: DatasetModal.type,
              name: DatasetModal.name,
              data: DatasetModal.description,
            },
          });
        })
        .catch(() => {
          newState = {
            ...newState,
            nameError: intl.formatMessage({
              id: "extractor.list.error.unique",
            }),
          };
          setDatasetModal(newState);
        });
    }
  };
  return (
    <div style={{ margin: "auto", marginTop: "150px" }}>
      <Card style={{ marginTop: "2rem" }}>
        <CardContent>
          <Typography varinat="h1" noWrap style={{ paddingBottom: "20px" }}>
            <FormattedMessage id="dataset.label.new" />
          </Typography>
            <FormattedMessage id="dataset.list.modal.type">
                {(text) => (
                     <FormLabel style={{ marginTop: "30px" }} id="dataset-type-group">{text}</FormLabel>
                )}
           </FormattedMessage>
           <RadioGroup row name="dataset-type-group" defaultValue={DatasetModal.type} >
                <FormattedMessage id="dataset.list.modal.type.custom">
                {(text) => (
                    <FormControlLabel value="CUSTOM" control={<Radio onChange={handleTypeModal} />} label={text} />
                )}
                </FormattedMessage>
                <FormattedMessage id="dataset.list.modal.type.fEngine">
                {(text) => (
                    <>
                      <FormControlLabel value="FENGINE" control={<Radio onChange={handleTypeModal} />} label={text} />
                        {showFEngineType ? (
                        <Select
                          value={fEngineType}
                          onChange={handleFEngineTypeModal}
                        >
                          <MenuItem value={'SUB_ACCOUNT'}>Sub Account</MenuItem>
                          <MenuItem value={'TRANSACTION'}>Transaction</MenuItem>
                          <MenuItem value={'FUND'}>Fund</MenuItem>
                          <MenuItem value={'FUND_TRANSACTION'}>Fund Transaction</MenuItem>
                          <MenuItem value={'FILE'}>File</MenuItem>
                      </Select>
                      ) : (<></>)}
                    </>
                )}
                </FormattedMessage>
           </RadioGroup>

          <FormattedMessage id="extractor.list.modal.name">
            {(text) => (
              <TextField
                autoFocus
                margin="normal"
                required
                fullWidth
                id="nameModal"
                name="nameModal"
                value={DatasetModal.name}
                inputProps={{ maxLength: 128 }}
                label={text}
                error={DatasetModal.nameError !== ""}
                helperText={DatasetModal.nameError}
                onChange={handleNameModal}
              />
            )}
          </FormattedMessage>
          <FormattedMessage id="extractor.list.modal.description">
            {(text) => (
              <TextField
                multiline
                minRows={3}
                maxRows={4}
                margin="normal"
                required
                fullWidth
                id="descriptionModal"
                name="descriptionModal"
                value={DatasetModal.description}
                inputProps={{ maxLength: 1024 }}
                label={text}
                error={DatasetModal.descriptionError !== ""}
                helperText={DatasetModal.descriptionError}
                onChange={handleDescriptionModal}
              />
            )}
          </FormattedMessage>

          <Button
            onClick={clickToDataset}
            color="secondary"
            variant="contained"
          >
            <FormattedMessage id="generic.modal.next" />
          </Button>
          <Button
            onClick={cancel}
            color="secondary"
            variant="outlined"
            style={{ float: "right" }}
          >
            <FormattedMessage id="generic.modal.cancel" />
          </Button>
        </CardContent>
        <Divider />
      </Card>
    </div>
  );
}

export default DataSetExtractor;

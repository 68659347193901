class ExtractorActionGetTableColumn {
  constructor(label, selector, type, options) {
    this.id = undefined;
    this.label = label;
    this.selector = selector;
    this.type = type;
    this.options = options;
  }
}

export default ExtractorActionGetTableColumn;

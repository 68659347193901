import { Button, Typography, Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import StepBreadcrump from "./StepBreadcrumbs";
import Datasetstep3 from "./DatasetStep_3";
import Datasetstep4 from "./DatasetStep_4";
import GenericModal from "../shared/GenericModal";
import DatasetTransfer from "./DatsetTransfer";
import DatasetService from "../../services/DatasetService";
import DatasetStep2 from "./DatasetStep_2";
import DatasetType from "../../models/enums/DatasetType";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "hidden",
    margin: 0,
  },
  grid: {
    margin: 0,
    width: "100%",
  },
  extractorName: {
    marginLeft: 18,
  },
  actions: {
    maxHeight: "77vh",
    overflowY: "auto",
  },
  leftMenu: {
    maxHeight: "90vh",
  },
  normalized: {
    maxHeight: "60vh",
    overflow: "auto",
    overflowX: "hidden",
  },
  iconButton: {
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  table: {
    height: "50vh",
  },
  toolbar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.black,
  },
  typography: {
    fontWeight: "bolder",
  },
  buttonsGroup: {
    marginTop: "2rem",
    marginLeft: "66rem",
    float: "right",
  },
  button: {
    marginLeft: "2rem",
  },
}));

function Dataset(props) {
  const classes = useStyles();
  const { location } = props;
  const {
    dataProps,
    datasetFieldProps,
    datasetMapProps,
    LabelTablecolumnProps,
    labelTablesColumnsSelectProps,
    EditProps,
    ActionListProps,
  } = location;
  const intl = useIntl();
  const [rowstable, setRowsTable] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState();
  const [ShowCancelModel, setShowCancelModel] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [step, Setstep] = useState(1);
  const [checked, setChecked] = useState([]);
  const [Left, setLeft] = useState([]);
  const [Right, setRight] = useState([]);
  const [step4, setStep4] = useState([]);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const leftChecked = DatasetTransfer.intersection(checked, Left);
  const rightChecked = DatasetTransfer.intersection(checked, Right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) =>
    DatasetTransfer.intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(DatasetTransfer.not(checked, items));
    } else {
      setChecked(DatasetTransfer.union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(Right.concat(leftChecked));
    setLeft(DatasetTransfer.not(Left, leftChecked));
    setChecked(DatasetTransfer.not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(Left.concat(rightChecked));
    setRight(DatasetTransfer.not(Right, rightChecked));
    setChecked(DatasetTransfer.not(checked, rightChecked));
  };

  useEffect(() => {
    if (EditProps === false) {
      setLeft(ActionListProps);
      setRowsTable(datasetFieldProps);
      setRight(LabelTablecolumnProps);
      setStep4(labelTablesColumnsSelectProps);
    } else {
      DatasetService.getDataSetLabelColumn().then((response) => {
        setLeft(response.data);
      });
    }
  }, [
    datasetFieldProps,
    datasetMapProps,
    LabelTablecolumnProps,
    ActionListProps,
    labelTablesColumnsSelectProps,
    EditProps,
  ]);

  const getSelection = (e, row) => {
    setRowData(row);
  };

  const closeCancelModal = () => {
    setShowCancelModel(false);
  };

  const confirmClosefinish = () => {
    DatasetService.cancel().then(() => {
      history.push("/dashboard");
    });
  };
  const RowCheckChange = (e, row) => {
    const value = rowstable.findIndex((data) => data === row);
    rowstable[value].dataSetKey = e.target.checked;
    setRowsTable((prev) => [...prev]);
  };

  const DatasetSave = () => {
    const datasetId = dataProps.id || 0;
    DatasetService.dataSetSave(step4, datasetId).then(
      () => {
        enqueueSnackbar(intl.formatMessage({ id: "extractor.save.success" }), {
          variant: "success",
        });
        history.push("/dashboard");
      },
      () => {
        enqueueSnackbar(intl.formatMessage({ id: "extractor.save.error" }), {
          variant: "error",
        });
      }
    );
  };

  const componentConfirmFinish = (
    <Typography>
      <FormattedMessage id="extractor.finishconfirm" />
    </Typography>
  );

  const handleSelectionCheckboxChange = (index) => {
    if (selectedCheckbox === index) {
      setSelectedCheckbox(undefined);
    } else {
      setSelectedCheckbox(index);
    }
  };
  const handleMoveUpAction = () => {
    const findrow = rowstable.splice(selectedCheckbox, 1)[0];
    rowstable.splice(selectedCheckbox - 1, 0, findrow);
    setRowsTable((prev) => [...prev]);
    handleSelectionCheckboxChange(selectedCheckbox - 1);
  };

  const handleMoveDownAction = () => {
    const findrow = rowstable.splice(selectedCheckbox, 1)[0];
    rowstable.splice(selectedCheckbox + 1, 0, findrow);
    setRowsTable((prev) => [...prev]);
    handleSelectionCheckboxChange(selectedCheckbox + 1);
  };

  const handleDeleteAction = () => {
    // if (EditProps === false) {
    //   DatasetService.RemovedatasetField(rowData.datasetId, rowData.id, rowData.name).then(() => {
    //     const handleDelete = rowstable.filter((e) => e !== rowData);
    //     setRowsTable(handleDelete);
    //     setSelectedCheckbox(undefined);
    //   });
    // } else {
    const handleDelete = rowstable.filter((e) => e !== rowData);
    setRowsTable(handleDelete);
    setSelectedCheckbox(undefined);
  };

  const nextStep1 = () => {
    DatasetService.dataSetField(rowstable)
      .then(() => {
        Setstep(step + 1);
      })
      .catch(() => {
        enqueueSnackbar(intl.formatMessage({ id: "dataset.steps.error" }), {
          variant: "error",
        });
      });
  };

  const getLabeltablecolumnlist = () => {
    DatasetService.labeltablecolumnlist(Right).then((response) => {
      setStep4(response.data.labelAndTableColumnDtoList);
    });
  };

  const nextStep2 = () => {
    getLabeltablecolumnlist();
    Setstep(step + 1);
  };

  const StepsChange = (index) => {
    Setstep(index + 1);
  };

  const handlesubmit = (DatasetValues) => {
    setRowsTable((prev) => [...prev, DatasetValues]);
  };

  const Checkboxvalues = (e, row, data) => {
    const findindex = step4.findIndex((datas) => datas === row);
    step4[findindex].checkBoxes[data] = e.target.checked;
    setStep4((prev) => [...prev]);
  };

  function Stepscomponent(steps) {
    switch (steps) {
      case 1:
        return (
          <>
            <DatasetStep2
              handlesubmit={handlesubmit}
              rowstable={rowstable}
              selectedCheckbox={selectedCheckbox}
              handleMoveUpAction={handleMoveUpAction}
              handleDeleteAction={handleDeleteAction}
              handleMoveDownAction={handleMoveDownAction}
              handleSelectionCheckboxChange={handleSelectionCheckboxChange}
              RowCheckChange={RowCheckChange}
              getSelection={getSelection}
            />
          </>
        );
      case 2:
        return (
          <>
            <Datasetstep3
              Left={Left}
              Right={Right}
              checked={checked}
              leftChecked={leftChecked}
              rightChecked={rightChecked}
              handleToggle={handleToggle}
              numberOfChecked={numberOfChecked}
              handleToggleAll={handleToggleAll}
              handleCheckedRight={handleCheckedRight}
              handleCheckedLeft={handleCheckedLeft}
            />
          </>
        );
      case 3:
        return (
          <>
            <Datasetstep4
              rows={step4}
              rowstable={rowstable}
              Checkboxvalues={Checkboxvalues}
            />
          </>
        );
      default:
        break;
    }
  }

  useEffect(() => {
    if (!dataProps) {
      history.push("/dashboard");
    } else if (dataProps.type !== 'CUSTOM') {
      DatasetService.datasetfieldlist()
            .then((response) => {
              setRowsTable(response.data);
              Setstep(2);
            })
            .catch(() => {
              enqueueSnackbar(intl.formatMessage({ id: "dataset.steps.error" }), {
                variant: "error",
              });
            });

    }
  }, [dataProps, history]);

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        className={classes.container}
        alignItems="flex-start"
      >
        <Grid item sm={3} mt={2}>
          <Typography className={classes.extractorName} variant="h6" noWrap>
            {dataProps ? dataProps.name : ""}
            {dataProps ? " - " : ""}
            <Typography component="span" variant="subtitle1">
              {dataProps ? dataProps.data || dataProps.description : ""}
            </Typography>
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <StepBreadcrump
            rowstable={rowstable}
            Right={Right}
            StepsChange={StepsChange}
            StepActive={step}
            datasetType={dataProps.type}
          />
        </Grid>
        <Grid item sm={3} mt={2}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            mr={2}
          >
            {(step === 3 || (dataProps.type === "CUSTOM" && step === 2)) && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => Setstep(step - 1)}
                className={classes.button}
              >
                <FormattedMessage id="dataset.previous" />
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => setShowCancelModel(true)}
            >
              <FormattedMessage id="dataset.cancel" />
            </Button>
            {step === 1 && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={nextStep1}
                disabled={!(rowstable.length > 0)}
              >
                <FormattedMessage id="dataset.next" />
              </Button>
            )}
            {step === 2 && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={nextStep2}
                disabled={!(Right.length > 0)}
              >
                <FormattedMessage id="dataset.next" />
              </Button>
            )}
            {step === 3 && (
              <Button
                variant="contained"
                color="secondary"
                onClick={DatasetSave}
                className={classes.button}
              >
                <FormattedMessage id="dataset.save" />
              </Button>
            )}
          </Box>
        </Grid>
        {Stepscomponent(step)}
        <Grid>
          {ShowCancelModel && (
            <GenericModal
              title={intl.formatMessage({ id: "generic.modal.confirm" })}
              component={componentConfirmFinish}
              click={confirmClosefinish}
              handleClose={closeCancelModal}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

Dataset.propTypes = {
  location: PropTypes.shape(PropTypes.object.isRequired),
  dataProps: PropTypes.shape(PropTypes.object.isRequired),
};

Dataset.defaultProps = {
  location: {},
  dataProps: { type: DatasetType.CUSTOM, name: "", data: "" },
};

export default Dataset;

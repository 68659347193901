import HttpService from "./HttpService";

const UserService = {
  create: (userDTO) => HttpService.createUser(userDTO),

  isEmailValid: (email) => HttpService.isEmailValid(email),

  update: (userDTO) => HttpService.updateUser(userDTO),

  saveSetting: (userSettingDTO, key) =>
    HttpService.saveSetting(userSettingDTO, key),

  getSetting: (Key) => HttpService.getSetting(Key),

  forgot: (userDTO) => HttpService.forgotPasswordUser(userDTO),
};

export default UserService;

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  Toolbar,
  Tooltip,
  Typography,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
} from "@material-ui/core";
import PropTypes from "prop-types";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import StorageOutlinedIcon from "@material-ui/icons/StorageOutlined";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import { useSnackbar } from "notistack";
import GenericModal from "../shared/GenericModal";
import SchedulerService from "../../services/SchedulerService";
import Rename from "../../models/enums/RenameDataset";

const defaultState = {
  name: "",
  description: "",
  show: false,
  nameError: "",
  descriptionError: "",
  rename: false,
};

const useStyles = makeStyles(() => ({
  title: {
    marginTop: -3,
    marginLeft: 5,
  },
  add: {
    marginRight: -7,
  },
}));

function SchedulerList(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState();
  const [schedulerDataModal, setSchedulerDataModal] = useState(defaultState);
  const [rows, setRows] = useState([]);
  const [currentScheduler, setCurrentScheduler] = useState();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    SchedulerService.schedulerDashboard().then(
      (response) => {
        const { data } = response;
        setRows(data);
      },
      () => {
        enqueueSnackbar(intl.formatMessage({ id: "scheduler.list.error.get" }), {
          variant: "error",
        });
      }
    );
  }, [enqueueSnackbar, intl]);

  const handleClick = (event, scheduler) => {
    setAnchorEl(event.currentTarget);
    setCurrentScheduler(scheduler);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNameModal = (event) =>
    setSchedulerDataModal((prevState) => ({
      ...prevState,
      name: event.target.value,
    }));

  const handleDescriptionModal = (event) =>
    setSchedulerDataModal((prevState) => ({
      ...prevState,
      description: event.target.value,
    }));

  const handleModalClose = () => {
    setSchedulerDataModal((prevState) => ({ ...prevState, show: false }));
  };

  const clickToExtractor = () => {
    let success = true;
    let newState = { ...schedulerDataModal, nameError: "", descriptionError: "" };

    setSchedulerDataModal((prevState) => ({
      ...prevState,
      nameError: "",
      descriptionError: "",
    }));

    if (schedulerDataModal.name.trim().length === 0) {
      success = false;
      newState = {
        ...newState,
        nameError: intl.formatMessage({ id: "extractor.list.error.name" }),
      };
      setSchedulerDataModal(newState);
    }

    if (schedulerDataModal.description.trim().length === 0) {
      success = false;
      newState = {
        ...newState,
        descriptionError: intl.formatMessage({
          id: "extractor.list.error.description",
        }),
      };
      setSchedulerDataModal(newState);
    }

    const findById = (element) => element.id === currentScheduler.id;

    if (success) {
      if (schedulerDataModal.rename) {
        const renameData = new Rename(
            currentScheduler.id,
            schedulerDataModal.name,
            schedulerDataModal.description
        );
        SchedulerService.schedulerRename(renameData)
            .then(() => {
              const findindex = rows.findIndex(
                  (data) => data.id === currentScheduler.id
              );
              rows[findindex].name = schedulerDataModal.name;
              rows[findindex].description = schedulerDataModal.description;
              setRows((prev) => [...prev]);
              enqueueSnackbar(
                  intl.formatMessage({ id: "extractor.list.success" }),
                  { variant: "success" }
              );
              setSchedulerDataModal({ ...defaultState, show: false });
            })
            .catch(() => {
              newState = {
                ...newState,
                nameError: intl.formatMessage({
                  id: "extractor.list.error.unique",
                }),
              };
              setSchedulerDataModal(newState);
            });
      }
    }
  };

  const handleEdit = (row) => {
    SchedulerService.schedulerEdit(row.id).then((response) => {
      const { data } = response;
      history.push({
        SchedulerProps: data,
        EditProps: false,
        pathname: "/scheduler-extractor",
      });
      /*
      {
    "id": 32,
    "extractorIds": null,
    "scheduleType": "MINUTE_INTERVAL",
    "minuteInterval": 1,
    "time": "00:00:00",
    "daysOfWeek": [
        "0"
    ],
    "dayOfMonth": 1,
    "name": "teste name",
    "description": "desc name"
}
       */
    });
  };

  const handleRename = () => {
    setSchedulerDataModal({
      ...defaultState,
      show: true,
      rename: true,
      name: currentScheduler.name,
      description: currentScheduler.description,
    });
    setAnchorEl(null);
  };

  const handleDelete = (row) => {
    SchedulerService.schedulerDelete(row.id)
      .then(() => {
        const deletrow = rows.filter((data) => data.id !== row.id);
        setRows(deletrow);
        setAnchorEl(null);
      })
      .catch((error) => {
        handleErrorDelete(error);
      });
  };

  function handleErrorDelete(error) {
    if (error.response.data.message.includes("ConstraintViolationException")) {
      props.setOpen(true);
      props.setMessage(
        "Could not delete this data set, it is associated with a query"
      );
    }
  }

  const componentSelectAction = (
    <>
      <FormattedMessage id="extractor.list.modal.name">
        {(text) => (
          <TextField
            autoFocus
            margin="normal"
            required
            fullWidth
            id="nameModal"
            name="nameModal"
            value={schedulerDataModal.name}
            inputProps={{ maxLength: 128 }}
            label={text}
            error={schedulerDataModal.nameError !== ""}
            helperText={schedulerDataModal.nameError}
            onChange={handleNameModal}
          />
        )}
      </FormattedMessage>
      <FormattedMessage id="extractor.list.modal.description">
        {(text) => (
          <TextField
            multiline
            minRows={3}
            maxRows={4}
            margin="normal"
            required
            fullWidth
            id="descriptionModal"
            name="descriptionModal"
            value={schedulerDataModal.description}
            inputProps={{ maxLength: 1024 }}
            label={text}
            error={schedulerDataModal.descriptionError !== ""}
            helperText={schedulerDataModal.descriptionError}
            onChange={handleDescriptionModal}
          />
        )}
      </FormattedMessage>
    </>
  );

  return (
    <>
      <Toolbar variant="dense">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Box display="flex" ml="-10px">
              <Tooltip title="Info about Scheduler">
                <StorageOutlinedIcon color="secondary" />
              </Tooltip>
              <Typography variant="h6" className={classes.title}>
                <FormattedMessage id="dashboard.scheduler" />
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Link to="/scheduler-extractor">
              <IconButton
                size="small"
                color="secondary"
                className={classes.add}
              >
                <Tooltip title="Add New">
                  <AddCircleOutlinedIcon />
                </Tooltip>
              </IconButton>
            </Link>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider />
      <TableContainer>
        <Table size="small">
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell align="left" scope="row">
                  <Typography variant="body2">{row.name}</Typography>
                </TableCell>
                <TableCell align="right">
                  <EditIcon
                    onClick={() => {
                      handleEdit(row);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <IconButton
                    onClick={(e) => handleClick(e, row)}
                    size="small"
                    style={{ marginTop: "-15px" }}
                  >
                    <MoreVertOutlinedIcon />
                  </IconButton>
                  <DeleteIcon
                    onClick={() => {
                      handleDelete(row);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>
                      <FormattedMessage id="dashboard.copy" />
                    </MenuItem>
                    <MenuItem onClick={handleRename}>
                      <FormattedMessage id="dashboard.rename" />
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {schedulerDataModal.show && (
        <FormattedMessage id="scheduler.list.modal.title.rename">
          {(text) => (
            <GenericModal
              title={text}
              component={componentSelectAction}
              click={clickToExtractor}
              handleClose={handleModalClose}
              text
            />
          )}
        </FormattedMessage>
      )}
    </>
  );
}

SchedulerList.propTypes = {
  setMessage: PropTypes.func,
  setOpen: PropTypes.func,
};

SchedulerList.defaultProps = {
  setMessage: () => {},
  setOpen: () => {},
};

export default SchedulerList;

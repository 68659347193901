import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import {
  Button, Card, CardContent, Divider, Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import QueryService from '../../services/QueryService';
// import Dataset from '../../models/enums/Dataset';

const defaultState = {
  name: '',
  description: '',
  nameError: '',
  descriptionError: '',
  rename: false,
};

function QueryExtractor() {
  const history = useHistory();
  const intl = useIntl();
  const [QueryModal, setQueryModal] = useState(defaultState);

  const cancel = () => {
    history.goBack()
  }

  const handleNameModal = (event) => setQueryModal(
    (prevState) => ({ ...prevState, name: event.target.value }),
  );
  const handleDescriptionModal = (event) => setQueryModal(
    (prevState) => ({ ...prevState, description: event.target.value }),
  );
  const clickToQuery = () => {
    let success = true;
    let newState = { ...QueryModal, nameError: '', descriptionError: '' };
    if (QueryModal.name.trim().length === 0) {
      success = false;
      newState = { ...newState, nameError: intl.formatMessage({ id: 'extractor.list.error.name' }) };
      setQueryModal(newState);
    }

    if (QueryModal.description.trim().length === 0) {
      success = false;
      newState = { ...newState, descriptionError: intl.formatMessage({ id: 'extractor.list.error.description' }) };
      setQueryModal(newState);
    }

    if (success) {
      setQueryModal(newState);
      const dataset = {
        name: QueryModal.name,
        description: QueryModal.description,
      };
      QueryService.createQuerystep(dataset).then(() => {
        history.push({
          pathname: '/Query',
          QueryProps: {
            name: QueryModal.name,
            data: QueryModal.description,
          },
        });
      }).catch(() => {
        newState = { ...newState, nameError: intl.formatMessage({ id: 'extractor.list.error.unique' }) };
        setQueryModal(newState);
      });
    }
  };
  return (
    <div style={{ margin: "auto", marginTop: "250px" }}>
      <Card style={{ marginTop: '2rem' }}>
        <CardContent>
          <Typography varinat="h1" noWrap>
            <FormattedMessage id="query.label.new" />
          </Typography>
          <FormattedMessage id="extractor.list.modal.name">
            {(text) => (
              <TextField
                autoFocus
                margin="normal"
                required
                fullWidth
                id="nameModal"
                name="nameModal"
                value={QueryModal.name}
                inputProps={{ maxLength: 128 }}
                label={text}
                error={QueryModal.nameError !== ''}
                helperText={QueryModal.nameError}
                onChange={handleNameModal}
              />
            )}
          </FormattedMessage>
          <FormattedMessage id="extractor.list.modal.description">
            {(text) => (
              <TextField
                multiline
                minRows={3}
                maxRows={4}
                margin="normal"
                required
                fullWidth
                id="descriptionModal"
                name="descriptionModal"
                value={QueryModal.description}
                inputProps={{ maxLength: 1024 }}
                label={text}
                error={QueryModal.descriptionError !== ''}
                helperText={QueryModal.descriptionError}
                onChange={handleDescriptionModal}
              />
            )}
          </FormattedMessage>
          <Button onClick={clickToQuery} color="secondary" variant="contained">
            <FormattedMessage id="generic.modal.next" />
          </Button>
          <Button onClick={cancel} color="secondary" variant="outlined" style={{ float: 'right' }}>
            <FormattedMessage id="generic.modal.cancel" />
          </Button>
        </CardContent>
        <Divider />
      </Card>
    </div>
  );
}

export default QueryExtractor;

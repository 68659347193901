import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  Toolbar,
  Tooltip,
  Typography,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
} from "@material-ui/core";
import PropTypes from "prop-types";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import StorageOutlinedIcon from "@material-ui/icons/StorageOutlined";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import { useSnackbar } from "notistack";
import GenericModal from "../shared/GenericModal";
import DatasetService from "../../services/DatasetService";
import Rename from "../../models/enums/RenameDataset";

const defaultState = {
  name: "",
  description: "",
  show: false,
  nameError: "",
  descriptionError: "",
  rename: false,
};

const useStyles = makeStyles(() => ({
  title: {
    marginTop: -3,
    marginLeft: 5,
  },
  add: {
    marginRight: -7,
  },
}));

function DatasetList(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState();
  const [extractorDataModal, setextractorDataModal] = useState(defaultState);
  const [rows, setRows] = useState([]);
  const [currentDataset, setCurrentDataset] = useState();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    DatasetService.datasetDashboard().then(
      (response) => {
        const { data } = response;
        setRows(data);
      },
      () => {
        enqueueSnackbar(intl.formatMessage({ id: "dataset.list.error.get" }), {
          variant: "error",
        });
      }
    );
  }, [enqueueSnackbar, intl]);

  const handleClick = (event, Dataset) => {
    setAnchorEl(event.currentTarget);
    setCurrentDataset(Dataset);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNameModal = (event) =>
    setextractorDataModal((prevState) => ({
      ...prevState,
      name: event.target.value,
    }));

  const handleDescriptionModal = (event) =>
    setextractorDataModal((prevState) => ({
      ...prevState,
      description: event.target.value,
    }));

  const handleModalClose = () => {
    setextractorDataModal((prevState) => ({ ...prevState, show: false }));
  };

  const clickToExtractor = () => {
    let success = true;
    let newState = {
      ...extractorDataModal,
      nameError: "",
      descriptionError: "",
    };
    if (extractorDataModal.name.trim().length === 0) {
      success = false;
      newState = {
        ...newState,
        nameError: intl.formatMessage({ id: "extractor.list.error.name" }),
      };
      setextractorDataModal(newState);
    }

    if (extractorDataModal.description.trim().length === 0) {
      success = false;
      newState = {
        ...newState,
        descriptionError: intl.formatMessage({
          id: "extractor.list.error.description",
        }),
      };
      setextractorDataModal(newState);
    }

    if (success) {
      if (extractorDataModal.rename) {
        const renameData = new Rename(
          currentDataset.id,
          extractorDataModal.name,
          extractorDataModal.description
        );
        DatasetService.datasetRename(renameData)
          .then(() => {
            const findindex = rows.findIndex(
              (data) => data.id === currentDataset.id
            );
            rows[findindex].name = extractorDataModal.name;
            rows[findindex].description = extractorDataModal.description;
            setRows((prev) => [...prev]);
            enqueueSnackbar(
              intl.formatMessage({ id: "extractor.list.success" }),
              { variant: "success" }
            );
            setextractorDataModal({ ...defaultState, show: false });
          })
          .catch(() => {
            newState = {
              ...newState,
              nameError: intl.formatMessage({
                id: "extractor.list.error.unique",
              }),
            };
            setextractorDataModal(newState);
          });
      }
    }
  };

  const handleEdit = (row) => {
    DatasetService.datasetEdit(row.id).then((response) => {
      const { data } = response;
      history.push({
        dataProps: data.datasetDto,
        datasetFieldProps: data.datasetFieldDtoList,
        datasetMapProps: data.datasetMap,
        LabelTablecolumnProps: data.labelTablesColumnsDto,
        labelTablesColumnsSelectProps: data.labelTablesColumnsSelect,
        ActionListProps: data.actionList,
        EditProps: false,
        pathname: "/dataset",
      });
    });
  };

  const handleRename = () => {
    setextractorDataModal({
      ...defaultState,
      show: true,
      rename: true,
      name: currentDataset.name,
      description: currentDataset.description,
    });
    setAnchorEl(null);
  };

  const handleDelete = (row) => {
    DatasetService.datasetDelete(row.id)
      .then(() => {
        const deletrow = rows.filter((data) => data.id !== row.id);
        setRows(deletrow);
        setAnchorEl(null);
      })
      .catch((error) => {
        handleErrorDelete(error);
      });
  };

  function handleErrorDelete(error) {
    if (error.response.data.message.includes("ConstraintViolationException")) {
      props.setOpen(true);
      props.setMessage(
        "Could not delete this data set, it is associated with a query"
      );
    }
  }

  const componentSelectAction = (
    <>
      <FormattedMessage id="extractor.list.modal.name">
        {(text) => (
          <TextField
            autoFocus
            margin="normal"
            required
            fullWidth
            id="nameModal"
            name="nameModal"
            value={extractorDataModal.name}
            inputProps={{ maxLength: 128 }}
            label={text}
            error={extractorDataModal.nameError !== ""}
            helperText={extractorDataModal.nameError}
            onChange={handleNameModal}
          />
        )}
      </FormattedMessage>
      <FormattedMessage id="extractor.list.modal.description">
        {(text) => (
          <TextField
            multiline
            minRows={3}
            maxRows={4}
            margin="normal"
            required
            fullWidth
            id="descriptionModal"
            name="descriptionModal"
            value={extractorDataModal.description}
            inputProps={{ maxLength: 1024 }}
            label={text}
            error={extractorDataModal.descriptionError !== ""}
            helperText={extractorDataModal.descriptionError}
            onChange={handleDescriptionModal}
          />
        )}
      </FormattedMessage>
    </>
  );

  const showConfirmDeleteModal = (row) => {
      setSelectedRow(row);
      setShowModalConfirmDelete(true);
    }

    const componentConfirmDelete = (
      <Typography>
        <FormattedMessage id="dataset.modal.delete.text" />
      </Typography>
    );

    const clickConfirmDelete = () => {
      handleDelete(selectedRow);
      setShowModalConfirmDelete(false);
    };

    const closeModalConfirmDelete = () => {
      setShowModalConfirmDelete(false);
    };

  return (
    <>
    {showModalConfirmDelete && (
              <GenericModal
                title={intl.formatMessage({ id: "generic.modal.confirm" })}
                component={componentConfirmDelete}
                click={clickConfirmDelete}
                handleClose={closeModalConfirmDelete}
              />
            )}
      <Toolbar variant="dense">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Box display="flex" ml="-10px">
              <Tooltip title="Info about Dataset">
                <StorageOutlinedIcon color="secondary" />
              </Tooltip>
              <Typography variant="h6" className={classes.title}>
                <FormattedMessage id="dashboard.dataset" />
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Link to="/dataset-extractor">
              <IconButton
                size="small"
                color="secondary"
                className={classes.add}
              >
                <Tooltip title="Add New">
                  <AddCircleOutlinedIcon />
                </Tooltip>
              </IconButton>
            </Link>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider />
      <TableContainer>
        <Table size="small">
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name} style={{ backgroundColor: row.dirty ? 'lightyellow' : '' }}>
                <TableCell align="left" scope="row">
                  <Typography variant="body2">{row.name}</Typography>
                </TableCell>
                <TableCell align="right">
                  <EditIcon
                    onClick={() => {
                      handleEdit(row);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <IconButton
                    onClick={(e) => handleClick(e, row)}
                    size="small"
                    style={{ marginTop: "-15px" }}
                  >
                    <MoreVertOutlinedIcon />
                  </IconButton>
                  <DeleteIcon
                    onClick={() => {
                      showConfirmDeleteModal(row);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>
                      <FormattedMessage id="dashboard.copy" />
                    </MenuItem>
                    <MenuItem onClick={handleRename}>
                      <FormattedMessage id="dashboard.rename" />
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {extractorDataModal.show && (
        <FormattedMessage id="dataset.list.modal.title.rename">
          {(text) => (
            <GenericModal
              title={text}
              component={componentSelectAction}
              click={clickToExtractor}
              handleClose={handleModalClose}
              text
            />
          )}
        </FormattedMessage>
      )}
    </>
  );
}

DatasetList.propTypes = {
  setMessage: PropTypes.func,
  setOpen: PropTypes.func,
};

DatasetList.defaultProps = {
  setMessage: () => {},
  setOpen: () => {},
};

export default DatasetList;

class ExtractorActionNumberMaskDto {
  constructor(
    beforePositive,
    afterPositive,
    beforeNegative,
    afterNegative,
    decimalSeparator,
    groupSeparator,
    selectorValue,
    xpathValue
  ) {
    this.beforePositive = beforePositive;
    this.afterPositive = afterPositive;
    this.beforeNegative = beforeNegative;
    this.afterNegative = afterNegative;
    this.decimalSeparator = decimalSeparator;
    this.groupSeparator = groupSeparator;
    this.selectorValue = selectorValue;
    this.xpathValue = xpathValue;
  }
}

export default ExtractorActionNumberMaskDto;

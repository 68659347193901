import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Paper,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2rem",
  },
  table: {
    maxWidth: 1200,
    minWidth: 1200,
    maxHeight: "80vh",
    overflow: "auto",
  },
  IconCheckColor: {
    color: theme.palette.secondary.main,
  },
  ClearIconColor: {
    color: "#c62828",
  },
  head: {
    minWidth: "80px",
  },
  tableCell: {
    border: `1px solid ${theme.palette.grey.light}`,
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    fontWeight: "bolder",
    color: "black",
    minWidth: "50px",
    border: `1px solid ${theme.palette.grey.light}`,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function QueryStep5(props) {
  const classes = useStyles();
  const { Right, Querystep5data } = props;
  const [rowsPerPage, setrowsPerpage] = useState(5);
  const [page, setPage] = useState(0);
  const [perPage] = useState([5, 10, 25]);
  const [header, setHeader] = useState([]);

  useEffect(() => {

    const newHeader = [];
      
    Right.forEach((q) => {
      if (q.type === 'FILE') {
        newHeader.push({ ...q, name: q.name + ' - file name' });
        newHeader.push({ ...q, name: q.name + ' - file link' }); 
      } else {
        newHeader.push(q);
      }
    });
    setHeader(newHeader);

  }, [Right]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const queryData = () => {
    const begin = (page + 1 - 1) * rowsPerPage;
    const end = begin + rowsPerPage;
    return Querystep5data.rows.slice(begin, end) || [];
  };

  const handleChangeRowsPerPage = (event) => {
    setrowsPerpage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container className={classes.root}>
      <Grid container spacing={2} justifyContent="center" direction="row">
        <Paper elevation={3}>
          <TableContainer component={Paper} className={classes.table}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  {header.map((data, i) => (
                    <StyledTableCell key={i} align="center">
                      {data.name}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {queryData().map((data, i) => (
                  <TableRow key={i} hover>
                    {data.fields.map((column) => (
                      column == null ? (
                        <TableCell className={classes.tableCell} />
                      ) : (
                        <TableCell 
                          className={classes.tableCell}
                          style={
                            header[data.fields.indexOf(column)]?.type === "NUMBER"
                            ? { textAlign: "end" }
                            : { textAlign: "start" }
                          }
                        >
                        {
                          column.indexOf('https://') === -1 ? 
                            column :
                            (<a href={column} target="_blank" rel="noreferrer">{column}</a>)
                        }
                      </TableCell> 
                      )
                    ))}
                  </TableRow>
                ))}
              </TableBody>
              <TablePagination
                rowsPerPageOptions={perPage}
                count={Querystep5data.totalOfRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Container>
  );
}

QueryStep5.propTypes = {
  Querystep5data: PropTypes.arrayOf(PropTypes.array),
  Right: PropTypes.arrayOf(PropTypes.object),
};

QueryStep5.defaultProps = {
  Querystep5data: [],
  Right: [],
};
export default QueryStep5;

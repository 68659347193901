/* eslint-disable react/jsx-boolean-value */
import {
  CardContent, CardHeader, Fade, InputAdornment, Typography, IconButton
} from '@material-ui/core';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/es/Button/Button';
import Container from '@material-ui/core/es/Container/Container';
import TextField from '@material-ui/core/esm/TextField/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import User from '../../models/User';
import UserService from '../../services/UserService';
import LocalStorageService from "../../services/LocalStorageService";


const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  background: {
    height: '100%',
    paddingTop: theme.spacing(12),
  },
  card: {
    width: '100%',
  },
  cardHeader: {
    marginTop: theme.spacing(6),
  },
  cardContent: {
    margin: theme.spacing(2, 2, 2, 2),
  },
  submit: {
    marginTop: theme.spacing(6),
  },
  textField: {
    fullWidth: true,
  },
}));

function ChangePassword() {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [retypePasswordError, setRetypePasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);

  const handleNewPassword = (event) => {
    setNewPasswordError('');
    setNewPassword(event.target.value);
  };

  const handleRetypePassword = (event) => {
    setRetypePasswordError('');
    setRetypePassword(event.target.value);
  };

  const changePassword = () => {
    const user = new User(
      '', newPassword,
    );
    UserService
      .update(user)
      .then(() => {
        enqueueSnackbar('Password changed', { variant: 'success' });
        LocalStorageService.setTempPasswordSet(false);
        history.push('/dashboard');
      })
      .catch((error) => {

        if (error.response.status === 422) {
          enqueueSnackbar(error.response.data, { variant: 'error' });
          return;
        }


        if (error.response.status === 400) {
          enqueueSnackbar('The new password is not valid.', { variant: 'error' });
          return;
        }

        enqueueSnackbar('There was an error on the server.', { variant: 'error' });
      });
  };

  const handleClick = () => {
    let passwordMessage = 'Password must contain a minimum of 9 characters.';
        passwordMessage += ' Being 4 letters, 4 numbers, 1 special character and no white spaces.';
    if (!(/(?=.*?[A-Za-z]{4,})/.test(newPassword))) {
      setNewPasswordError(passwordMessage);
    } else if (!/(?=.*?[0-9]{4,})/.test(newPassword)) {
      setNewPasswordError(passwordMessage);
    } else if (/(?=.*?[\s]{1,})/.test(newPassword)) {
      setNewPasswordError(passwordMessage);
    } else if (!/.{9,}/.test(newPassword)) {
      setNewPasswordError(passwordMessage);
    } else if (newPassword !== retypePassword) {
      setRetypePasswordError(passwordMessage);
    } else {
      changePassword();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') { changePassword(); }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRetypeClickShowPassword = () => {
      setShowRetypePassword(!showRetypePassword);
    };

  return (
    <>
      <Container component="main" className={classes.background}>
        <Container component="main" maxWidth="xs">
          <Fade in timeout={500}>
            <Card className={classes.card} onKeyDown={handleKeyDown}>
              <CardHeader
                className={classes.cardHeader}
                align="center"
                title={<FormattedMessage id="changePassword.title" />}
              />
              <CardContent className={classes.cardContent}>
                <form noValidate>
                  <FormattedMessage id="changePassword.newPassword">
                    {(text) => (
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={text}
                        type={showPassword ? "text" : "password"}
                        id="password"
                        value={newPassword}
                        error={newPasswordError !== ''}
                        helperText={newPasswordError}
                        onChange={handleNewPassword}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockOutlinedIcon color={newPassword ? 'secondary' : 'inherit'} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleClickShowPassword}>
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                        }}
                      />
                    )}
                  </FormattedMessage>
                  <FormattedMessage id="changePassword.retypePassword">
                    {(text) => (
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={text}
                        type={showRetypePassword ? "text" : "password"}
                        id="password"
                        value={retypePassword}
                        onChange={handleRetypePassword}
                        error={retypePasswordError !== ''}
                        helperText={retypePasswordError}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockOutlinedIcon color={retypePassword ? 'secondary' : 'inherit'} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleRetypeClickShowPassword}>
                                {showRetypePassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </FormattedMessage>

                  <Button
                    type="button"
                    fullWidth
                    size="large"
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    onClick={handleClick}
                  >
                    <Typography>
                      <FormattedMessage id="changePassword.changePassword" />
                    </Typography>
                  </Button>
                </form>
              </CardContent>
            </Card>
          </Fade>
        </Container>
      </Container>
    </>
  );
}

export default ChangePassword;

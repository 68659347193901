import {
  Typography, Grid, Button, Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import GenericModal from '../shared/GenericModal';
import QueryService from '../../services/QueryService';
import QueryStep2 from './QueryStep_2';
import QueryStep3 from './QueryStep_3';
import QueryStep4 from './QueryStep_4';
import QueryStep5 from './QueryStep_5';
import QueryExportExcel from './QueryExportExcel';
import QueryBreadcrump from './QueryBreadcrumbs';
import DatasetTransfer from '../dataset/DatsetTransfer';
import DatasetType from "../../models/enums/DatasetType";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    overflow: 'hidden',
    margin: 0,
  },
  grid: {
    margin: 0,
    width: '100%',
  },
  extractorName: {
    marginLeft: 18,
  },
  actions: {
    maxHeight: '77vh',
    overflowY: 'auto',
  },
  leftMenu: {
    maxHeight: '90vh',
  },
  normalized: {
    maxHeight: '60vh',
    overflow: 'auto',
    overflowX: 'hidden',
  },
  iconButton: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  table: {
    height: '50vh',
  },
  toolbar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.black,
  },
  typography: {
    fontWeight: 'bolder',
  },
  buttonsGroup: {
    marginTop: '2rem',
    marginLeft: '66rem',
    float: 'right',
  },
  button: {
    marginLeft: '2rem',
  },
  steps: {
    marginTop: '2rem',
  },
}));

function Query(props) {
  const classes = useStyles();
  const [step, setStep] = useState(1);
  const [Dataset, setDataset] = useState([]);
  const [checked, setChecked] = useState([]);
  const [Querystep4Rightcheck, setQuerystep4Rightcheck] = useState([]);
  const [Querystep4check, setQuerystep4check] = useState();
  const [Querystep4checkrow, setQUerystep4chechrow] = useState({});
  const [Querystep3checkrow, setQuerystep3checkrow] = useState({});
  const [Querystep3check, setQuerystep3check] = useState();
  const [Left, setLeft] = useState([]);
  const [Right, setRight] = useState([]);
  const [operator, setOperator] = useState();
  const [QueryDatasetobject, setQueryDatasetobject] = useState({});
  const [QueryLeftData, setQueryleftData] = useState([]);
  const [QueryRightData, setQueryRightData] = useState([]);
  const [Querystep5data, setQuerystep5data] = useState([]);
  const [ShowCancelModel, setShowCancelModel] = useState(false);
  const [QuerySaveId, setQuerySaveId] = useState(0);
  const [error, setError] = useState('');

  const { location } = props;
  const {
    QueryProps,
    QueryDatasetProps,
    QueryFieldlistProps,
    QueryFilterlistProps,
    EditProp,
    executeActionFromDashboard,
    QuerystepProp,
  } = location;
  const history = useHistory();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);


  useEffect(() => {
    if (EditProp === false) {
      setDataset([]);
    } else {
      QueryService.getDataset().then((response) => {
        setDataset(response.data);
      });
    }
    QueryService.datasetoperator().then((response) => {
      setOperator(response.data);
    });
  }, [EditProp]);

  useEffect(() => {
    if (QuerystepProp === 3) {
      QueryService.fieldoperator(QueryRightData).then(() => {
        QueryService.showExtractorData().then((response) => {
          setQuerystep5data(response.data);
          setStep(4);
        });
      });
    }
  }, [QuerystepProp]);

  useEffect(() => { }, []);

  useEffect(() => {
    if (QueryProps && QueryProps.name && QueryProps.description) {
      QueryService.datasetFieldQuery(QueryDatasetProps).then((response) => {
        setQueryleftData(response.data);
        setLeft(response.data);
      });
      
      setRight(QueryFieldlistProps);
      // eslint-disable-next-line array-callback-return
      QueryFilterlistProps.map((data) => {
        data.operator = `${data.operatorId},${data.operatorType}`;
      });

      setQueryRightData(QueryFilterlistProps);
      setQuerySaveId(QueryProps.id);
      setStep(QuerystepProp);
    } else if (!QueryProps) {
      history.push('/dashboard');
    }
  }, [
    QuerystepProp, QueryProps, QueryFieldlistProps, QueryDatasetProps, history,
    setRight, setQueryRightData, QueryFilterlistProps,
  ]);

  const handleMessageNotification = useCallback(
    (message, errorProps) => {
      if (errorProps.variant === "error") {
        setErrorMessage(message);
        setShowErrorMessage(true);
      } else {
        enqueueSnackbar(message, errorProps);
      }
    },
    [errorMessage]
  );

  const setQueryData = (data) => {
    setQueryDatasetobject(data);
    setRight([]);
    setQueryRightData([]);
    setQuerystep5data([]);
  };

  const queryStep1 = () => {
    QueryService.datasetFieldQuery(QueryDatasetobject).then((response) => {
      if (QueryDatasetobject.type === DatasetType.CUSTOM) {
        setLeft(response.data);
        setStep(step + 1);
        setQueryleftData(response.data);
      } else {
        //for f-engine queries we just show the results
        setRight(response.data);
        setQueryRightData(response.data);
        response.data.forEach((data) => {
          if (data.datasetFieldId === 0) {
            data.datasetFieldId = data.id;
          }
        });
        QueryService.fieldselected(response.data).then(() => {
            QueryService.fieldoperator([]).then(() => {
                        QueryService.showExtractorData().then((response2) => {
                          setQuerystep5data(response2.data);
                          setStep(4);
                        });
                  });
              });

      }
      // Add herer request to validate if dataset has any extractor with data
      // handleMessageNotification(
      //   intl.formatMessage({ id: "extractor.sequence.change.warning" }),
      //   { variant: "warning" }
      // );
    });
  };

  const Querysave = () => {
    const saveid = QuerySaveId === undefined ? 0 : QuerySaveId;
    QueryService.QuerySave(saveid).then(() => {
      enqueueSnackbar(intl.formatMessage({ id: 'extractor.save.success' }), { variant: 'success' });
      history.push('/dashboard');
    }, () => {
      enqueueSnackbar(intl.formatMessage({ id: 'extractor.save.error' }), { variant: 'error' });
    });
  };

  const queryStep2 = () => {
    if (EditProp === false) {
      // eslint-disable-next-line array-callback-return
      Right.map((data) => {
        if (data.datasetFieldId === 0) {
          data.datasetFieldId = data.id;
        }
      });
      QueryService.fieldselected(Right).then(() => {
        setStep(step + 1);
      });
      QueryService.datasetFieldQueryForFilter(QueryDatasetProps).then((response) => {
        // eslint-disable-next-line array-callback-return
        response.data.map((data) => {
          if (data.datasetFieldId === 0) {
            data.datasetFieldId = data.id;
          }
        });
        setQueryleftData(response.data);
        setLeft(response.data);
      });
    } else {
      // eslint-disable-next-line array-callback-return
      Right.map((data) => {
        data.queryFieldId = 0;
        data.datasetFieldId = data.id;
      });
      setRight((prev) => [...prev]);
      QueryService.fieldselected(Right).then(() => {
        setStep(step + 1);
      });
    }
  };

  const queryStep3 = () => {
    const resquery3 = QueryRightData.map((q) => {
      if (q.inputValue === '') {
        setError('All fields are mandatory');
        return false;
      } return true;
    });
    if (!resquery3.includes(false)) {
      QueryService.fieldoperator(QueryRightData).then(() => {
        QueryService.showExtractorData().then((response) => {
          setQuerystep5data(response.data);
          setStep(step + 1);
        });
      });
    }
  };

  const handlestep4Checkbox = (e, index, row) => {
    setQuerystep4check(index);
    setQUerystep4chechrow(row);
  };

  const handleToggle = (value, data) => () => {
    setQueryDatasetobject(data);
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleTogglestep4 = (value) => () => {
    const currentIndex = Querystep4Rightcheck.indexOf(value);
    const newChecked = [...Querystep4Rightcheck];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setQuerystep4Rightcheck(newChecked);
  };

  const leftChecked = DatasetTransfer.intersection(checked, Left);

  const QueryLeftChecked = DatasetTransfer.intersection(Querystep4Rightcheck, QueryLeftData);

  const numberOfChecked = (items) => DatasetTransfer.intersection(checked, items).length;

  const updateSequence = (element, index) => { element.sequence = index + 1; };

  const handleCheckedLeft = () => {
    if (EditProp === false) {
      setLeft(Left.concat(Querystep3checkrow));
      Right.splice(Querystep3check, 1);
      setRight((prev) => [...prev]);
      setQuerystep3checkrow({});
      setQuerystep3check(undefined);
    } else {
      const step3row = [];
      delete Querystep3checkrow.sort;
      delete Querystep3checkrow.orderSequence;
      step3row.push(Querystep3checkrow);
      setLeft(Left.concat(step3row));
      Right.splice(Querystep3check, 1);
      setRight((prev) => [...prev]);
      setQuerystep3checkrow({});
      setQuerystep3check(undefined);
    }
    Right.map(updateSequence);
  };

  const handlestep3Checkbox = (e, index, row) => {
    setQuerystep3check(index);
    setQuerystep3checkrow(row);
  };

  const handleCheckedRight = () => {
    setRight(Right.concat(leftChecked));
    setLeft(DatasetTransfer.not(Left, leftChecked));
    setChecked(DatasetTransfer.not(checked, leftChecked));
    Right.map(updateSequence);
  };

  const handleQueryCheckRight = () => {
    setQueryleftData(DatasetTransfer.not(QueryLeftData, Querystep4Rightcheck));
    // eslint-disable-next-line array-callback-return
    Querystep4Rightcheck.map((data) => {
      data.queryFilterId = 0;
      data.datasetFieldId = data.id;
    });
    setQuerystep4Rightcheck((prev) => [...prev]);
    QueryService.filterinputvalue(Querystep4Rightcheck).then((response) => {
      setQueryRightData(QueryRightData.concat(response.data));
      setQuerystep4Rightcheck([]);
    });
  };

  const handleQueryCheckLeft = () => {
    setQueryleftData(QueryLeftData.concat(Querystep4checkrow));
    QueryRightData.splice(Querystep4check, 1);
    setQueryRightData((prev) => [...prev]);
    setQUerystep4chechrow({});
    setQuerystep4check(undefined);
  };

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(DatasetTransfer.not(checked, items));
    } else {
      setChecked(DatasetTransfer.union(checked, items));
    }
  };

  const handleselection = (e, row) => {
    const findindex = Right.findIndex((data) => data === row);
    Right[findindex].orderSequence = e.target.value;
    setRight([...Right]);
  };

  const handlesort = (e, index) => {
    Right[index].orderType = e.target.value;
    setRight([...Right]);
  };

  const cancelQuery = () => {
    setShowCancelModel(true);
  };

  const handlesetoperator = (e, index) => {
    const operatorvalue = e.target.value;
    const [operatorid, operatortype] = operatorvalue.split(',');
    QueryRightData[index].operator = e.target.value;
    QueryRightData[index].operatorId = operatorid;
    QueryRightData[index].operatorType = operatortype;
    setQueryRightData((prev) => [...prev]);
  };
  const handleoperatorvalue = (e, index) => {
    QueryRightData[index].inputValue = e.target.value;
    setQueryRightData([...QueryRightData]);
  };

  const QueryMoveUpAction = () => {
    const findrow = QueryRightData.splice(Querystep4check, 1)[0];
    QueryRightData.splice(Querystep4check - 1, 0, findrow);
    setQueryRightData((prev) => ([...prev]));
    setQuerystep4check(Querystep4check - 1);
  };

  const QueryMoveDownAction = () => {
    const findrow = QueryRightData.splice(Querystep4check, 1)[0];
    QueryRightData.splice(Querystep4check + 1, 0, findrow);
    setQueryRightData((prev) => ([...prev]));
    setQuerystep4check(Querystep4check + 1);
  };

  const handleStep3MoveUpAction = () => {
    const findrow = Right.splice(Querystep3check, 1)[0];
    Right.splice(Querystep3check - 1, 0, findrow);
    setRight((prev) => ([...prev]));
    setQuerystep3check(Querystep3check - 1);
    Right.map(updateSequence);
  };

  const handleStep3MoveDownAction = () => {
    const findrow = Right.splice(Querystep3check, 1)[0];
    Right.splice(Querystep3check + 1, 0, findrow);
    setRight((prev) => ([...prev]));
    setQuerystep3check(Querystep3check + 1);
    Right.map(updateSequence);
  };

  const componentConfirmFinish = (
    <Typography>
      <FormattedMessage id="extractor.finishconfirm" />
    </Typography>
  );

  const confirmClosefinish = () => {
    QueryService.cancelQuery().then(() => {
      history.push('/dashboard');
    });
  };

  const StepsChange = (index) => {
    setStep(index + 1);
    setQueryDatasetobject({});
  };

  const closeCancelModal = () => {
    setShowCancelModel(false);
  };

  function StepsComponent(steps) {
    switch (steps) {
      case 1:
        return (
          <>
            <QueryStep2
              Dataset={Dataset}
              setQueryData={setQueryData}
            />
          </>
        );
      case 2:
        return (
          <>
            <QueryStep3
              Left={Left}
              Right={Right}
              checked={checked}
              Querystep3check={Querystep3check}
              handlestep3Checkbox={handlestep3Checkbox}
              leftChecked={leftChecked}
              Querystep3checkrow={Querystep3checkrow}
              handleToggle={handleToggle}
              numberOfChecked={numberOfChecked}
              handleToggleAll={handleToggleAll}
              handleCheckedRight={handleCheckedRight}
              handleCheckedLeft={handleCheckedLeft}
              handleselection={handleselection}
              handlesort={handlesort}
              handleStep3MoveUpAction={handleStep3MoveUpAction}
              handleStep3MoveDownAction={handleStep3MoveDownAction}
            />
          </>
        );
      case 3:
        return (
          <>
            <QueryStep4
              QueryLeftData={QueryLeftData}
              QueryRightData={QueryRightData}
              Querystep4Rightcheck={Querystep4Rightcheck}
              operator={operator}
              QueryLeftChecked={QueryLeftChecked}
              handleTogglestep4={handleTogglestep4}
              handleQueryCheckRight={handleQueryCheckRight}
              handleQueryCheckLeft={handleQueryCheckLeft}
              handleCheckedLeft={handleCheckedLeft}
              Querystep4check={Querystep4check}
              handlestep4Checkbox={handlestep4Checkbox}
              QueryMoveDownAction={QueryMoveDownAction}
              QueryMoveUpAction={QueryMoveUpAction}
              handlesetoperator={handlesetoperator}
              handleoperatorvalue={handleoperatorvalue}
              Querystep4checkrow={Querystep4checkrow}
              error={error}
            />
          </>
        );
      case 4:
        return (
          <>
            <QueryStep5
              Right={Right}
              Querystep5data={Querystep5data}
            />
          </>
        );

      default:
        break;
    }
  }

  return (
    <Grid container spacing={2} direction="row" className={classes.container} alignItems="flex-start">
      <Grid item sm={3} mt={2}>
        <Typography className={classes.extractorName} variant="h6" noWrap>
          {QueryProps ? QueryProps.name : ''}
          {QueryProps ? ' - ' : ''}
          <Typography component="span" variant="subtitle1">
            {QueryProps ? QueryProps.description || QueryProps.data : ''}
          </Typography>
        </Typography>
      </Grid>
      <Grid item sm={7}>
        {!executeActionFromDashboard && (QueryDatasetobject === undefined || QueryDatasetobject.type === DatasetType.CUSTOM) && (
          <QueryBreadcrump
            QueryDatasetobject={QueryDatasetobject}
            StepsChange={StepsChange}
            Right={QuerystepProp === 3 ? [] : Right}
            Querystep5data={Querystep5data}
            StepActive={step}
          />
       )}
      </Grid>
      <Grid item sm={2} mt={2}>
        <Box display="flex" flexDirection="row" justifyContent="flex-end" mr={2}>
          {!executeActionFromDashboard && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={cancelQuery}
            >
              <FormattedMessage id="dataset.cancel" />
            </Button>
          )}
          {step === 4
            && (
              <QueryExportExcel
                Querystep5data={QueryProps ? QueryProps.name : ''}
              />
            )}
          {step === 1
            && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={Object.keys(QueryDatasetobject).length === 0}
                onClick={queryStep1}
              >
                <FormattedMessage id="dataset.next" />
              </Button>
            )}
          {step === 2
            && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={!(Right.length > 0)}
                onClick={queryStep2}
              >
                <FormattedMessage id="dataset.next" />
              </Button>
            )}
          {step === 3
            && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={!(Right.length > 0)}
                onClick={queryStep3}
              >
                <FormattedMessage id="dataset.next" />
              </Button>
            )}
          {step === 4 && !executeActionFromDashboard
            && (
              <Button
                variant="contained"
                color="secondary"
                disabled={QuerystepProp === 3 ? 1 : 0}
                className={classes.button}
                onClick={Querysave}
              >
                <FormattedMessage id="dataset.save" />
              </Button>
            )}
        </Box>
      </Grid>
      {StepsComponent(step)}
      <Grid>
        {ShowCancelModel && (
          <GenericModal
            title={intl.formatMessage({ id: 'generic.modal.confirm' })}
            component={componentConfirmFinish}
            click={confirmClosefinish}
            handleClose={closeCancelModal}
          />
        )}
      </Grid>
    </Grid>
  );
}

Query.propTypes = {
  location: PropTypes.shape(PropTypes.object.isRequired),
  QueryProps: PropTypes.shape(PropTypes.object.isRequired),
};

Query.defaultProps = {
  location: {},
  QueryProps: { name: '', data: '' },
};

export default Query;

import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import HttpService from "../services/HttpService";

function ConfirmRegister(props) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  useEffect(() => {
    HttpService.cofirmRegister(props.match.params.token)
      .then(
        (res) => {
          if (res.data.includes("Success! Please try to login now.")) {
            enqueueSnackbar(res.data, { variant: "success" });
          } else {
            enqueueSnackbar(res.data, { variant: "error" });
          }
        },
        () => {
          enqueueSnackbar("Please try again later", { variant: "error" });
        }
      )
      .catch((err) => {
        throw new Error(err);
      })
      .finally(() => {
        history.push("/login");
      });
  }, []);

  return <></>;
}

export default ConfirmRegister;

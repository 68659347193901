import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Paper,
  makeStyles,
  Checkbox,
} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2rem",
  },
  table: {
    maxWidth: 1200,
    minWidth: 1200,
    maxHeight: "80vh",
    overflow: "auto",
  },
  IconCheckColor: {
    color: theme.palette.secondary.main,
  },
  ClearIconColor: {
    color: "#c62828",
  },
  head: {
    minWidth: "80px",
  },
  Table: {
    width: "100%",
    overflow: "auto",
    padding: "50px 20px !important",
  },
  Column: {
    border: `1px solid ${theme.palette.grey.light}`,
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 5px",
  },
  ColumnBlue: {
    border: `1px solid ${theme.palette.grey.light}`,
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 5px",
    overflow: "hidden",
    backgroundColor: theme.palette.secondary.main,
    fontWeight: "bolder",
  },
  Row: {
    height: "50px",
    padding: "0px !important",
  },
}));

function DatasetStep4(props) {
  const classes = useStyles();
  const { rows, rowstable, Checkboxvalues } = props;

  return (
    <Container className={classes.root}>
      <Paper elevation={3}>
        <Grid container spacing={2} justifyContent="center" direction="row">
          <Grid container item sm={12} className={classes.Table}>
            <Grid container item sm={12}>
              <Grid container item className={classes.Row} sm={6}>
                <Grid
                  item
                  className={classes.ColumnBlue}
                  sm={3}
                  justifyContent="center"
                >
                  Extractor
                </Grid>
                <Grid
                  item
                  className={classes.ColumnBlue}
                  sm={3}
                  justifyContent="center"
                >
                  Label
                </Grid>
                <Grid
                  item
                  className={classes.ColumnBlue}
                  sm={3}
                  justifyContent="center"
                >
                  Table
                </Grid>
                <Grid
                  item
                  className={classes.ColumnBlue}
                  sm={3}
                  justifyContent="center"
                >
                  Column
                </Grid>
              </Grid>
              <Grid
                container
                item
                className={classes.Row}
                sm={6}
                justifyContent="center"
              >
                {rowstable.map((data) => (
                  <Grid
                    justifyContent="center"
                    item
                    className={classes.ColumnBlue}
                    sm
                  >
                    {data.name}
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {rows.map((row) => (
              <Grid container item sm={12}>
                <Grid container item className={classes.Row} sm={6}>
                  <Grid item className={classes.Column} sm={3}>
                    {`${row.extractorLabelTableColumnDto.extractorName}`}
                  </Grid>
                  <Grid item className={classes.Column} sm={3}>
                    {row.extractorLabelTableColumnDto.actionLabel !== null
                      ? row.extractorLabelTableColumnDto.actionLabel
                      : ""}
                  </Grid>
                  <Grid item className={classes.Column} sm={3}>
                    {row.extractorLabelTableColumnDto.tableName !== null
                      ? row.extractorLabelTableColumnDto.tableName
                      : ""}
                  </Grid>
                  <Grid item className={classes.Column} sm={3}>
                    {row.extractorLabelTableColumnDto.tableColumnName !== null
                      ? row.extractorLabelTableColumnDto.tableColumnName
                      : ""}
                  </Grid>
                </Grid>
                <Grid container item className={classes.Row} sm={6}>
                  {rowstable.map((data, index) => (
                    <Grid
                      item
                      justifyContent="center"
                      className={classes.Column}
                      sm
                      key={`${index}`}
                    >
                      {(data.type === row.actionType || data.type === 'FILE') && (
                        <Checkbox
                          checked={
                            row.checkBoxes[data.name] === undefined
                              ? false
                              : row.checkBoxes[data.name]
                          }
                          onChange={(e) => {
                            Checkboxvalues(e, row, data.name);
                          }}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

DatasetStep4.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  rowstable: PropTypes.arrayOf(PropTypes.object),
  Checkboxvalues: PropTypes.func,
};

DatasetStep4.defaultProps = {
  rows: [],
  rowstable: [],
  Checkboxvalues: () => {},
};
export default DatasetStep4;

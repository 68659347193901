import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Grid,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
  },
  cardSelect: {
    width: "100%",
    background: theme.palette.secondary.dark,
  },
  avatarSelect: {
    background: theme.palette.secondary.light,
  },
  helpDisplay: {
    height: "100vh",
    width: "100%",
  },
  fileContent: {
    width: "100%",
    height: "100%",
    pt: "20px"
  }
}));

function Help(props) {
  const classes = useStyles();
  const listHelps = ["release.note.peerveil.extractor",
                     "release.note.peerveil.datainsertor",
                     "release.note.peerveil.screenshare"];
  const [selectHelp, setSelectHelp] = useState("release.note.peerveil.extractor");
  const [selectedPdf, setSelectedPdf] = useState("/Release Note_PeerVeil Extractor.pdf#view=FitW");

  const changeSelectHelp = (value) => {
    if (selectHelp === value) {
      setSelectHelp("");
    } else {
      setSelectHelp(value);
    }
  };

  useEffect(() => {
    let pdf = "";
    switch (selectHelp) {
        case "release.note.peerveil.extractor":
            pdf = "/Release Note_PeerVeil Extractor.pdf#view=FitW";
            break;
        case "release.note.peerveil.datainsertor":
            pdf = "/Release Note_PeerVeil Data Insertor.pdf#view=FitW";   
            break;
        case "release.note.peerveil.screenshare":
            pdf = "/Release Note_PeerVeil ScreenShare.pdf#view=FitW";
            break;
        default:
            pdf = "";    
    }
    setSelectedPdf(pdf);
  }, [selectHelp]);

  return (
    <>
      <Grid container>
        <Grid item sm={3}>
          {listHelps.map((item) => (
            <Box onClick={() => changeSelectHelp(item)}>
              <List>
                <ListItem>
                  <Box
                    className={
                      item === selectHelp
                        ? classes.cardSelect
                        : classes.card
                    }
                    component={Card}
                    raised
                  >
                    <FormattedMessage id={`help.${item}`} >
                    {(text) => (
                        <CardHeader
                          title={
                                <Tooltip
                                  title={`${text}.pdf`}
                                  placement="bottom-start"
                                >
                                <Typography
                                  className={classes.subheader}
                                  variant="subtitle1"
                                  noWrap
                                >
                                    <FormattedMessage id={text} />
                                </Typography>
                                </Tooltip>
                            }
                          avatar={
                                <Avatar
                                  className={
                                    item === selectHelp ? classes.avatarSelect : ""
                                  }
                                  variant="rounded"
                                >
                                <HelpOutlineOutlinedIcon />
                                </Avatar>
                            }
                        />
                        )}
                    </FormattedMessage>
                  </Box>
                </ListItem>
              </List>
            </Box>
          ))}
        </Grid>
        <Grid item sm={9} className={classes.helpDisplay}>
            <iframe
              title="Show PDF"
              src={selectedPdf}
              className={classes.fileContent}
            >
            </iframe>
        </Grid>
      </Grid>
    </>
  );
}

export default Help;


import { Breadcrumbs, makeStyles, Button } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import React from "react";
import { FormattedMessage } from 'react-intl';
import PropTypes, { number } from 'prop-types';

const useStyles = makeStyles(() => ({
  breadcrumb: {
  },
}));

function StepBreadcrump(props) {
  const classes = useStyles();
  const { StepsChange, Right, rowstable, StepActive, datasetType } = props;

  const handleStepsChange = (index) => {
    StepsChange(index);
  };

  return (
    <Breadcrumbs
      className={classes.breadcrumb}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {datasetType !== "CUSTOM" ? (
        <Button
          color={""}
          underline="none"
          disabled
        >
          <Typography>
            <FormattedMessage id="dataset.step1" />
          </Typography>
        </Button>
      ) : (
          <Button
            color={StepActive === 1 ? "secondary" : ""}
            onClick={() => { handleStepsChange(0); }}
            underline="none"
          >
            <Typography>
                <FormattedMessage id="dataset.step1" />
            </Typography>
          </Button>
      )}

      <Button
        color={StepActive === 2 ? "secondary" : ""}
        onClick={() => { handleStepsChange(1); }}
        underline="none"
        disabled={!(rowstable.length > 0)}
      >
        <Typography>
          <FormattedMessage id="dataset.step2" />
        </Typography>
      </Button>
      <Button
        color={StepActive === 3 ? "secondary" : ""}
        onClick={() => { handleStepsChange(2); }}
        underline="none"
        disabled={!(Right.length > 0) || !(rowstable.length > 0)}
      >
        <Typography>
          <FormattedMessage id="dataset.step3" />
        </Typography>
      </Button>
    </Breadcrumbs>
  );
}

StepBreadcrump.propTypes = {
  StepsChange: PropTypes.func,
  Right: PropTypes.arrayOf(PropTypes.object),
  rowstable: PropTypes.arrayOf(PropTypes.object),
  StepActive: number,
  datasetType: PropTypes.string
};

StepBreadcrump.defaultProps = {
  StepsChange: () => {},
  Right: [],
  rowstable: [],
  StepActive: 1,
  datasetType: "CUSTOM"
};
export default StepBreadcrump;

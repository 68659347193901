import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { alpha } from '@material-ui/core/styles'
import TextField from "@material-ui/core/TextField";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ExtractorActionStatus from "../../../../models/enums/ExtractorActionStatus";
import ExtractorActionType from "../../../../models/enums/ExtractorActionType";
import ExtractorActionValidation from "../../../../models/enums/ExtractorActionValidation";
import ExtractorStatus from "../../../../models/enums/ExtractorStatus";
import GenericModal from "../../GenericModal";
import ActionError from "./ActionError";
import ActionContent from "./ActionContent";
import ActionTable from "./ActionTable";
import ActionUnfinished from "./ActionUnfinished";
import ActionWarning from "./ActionWarning";
import AppContext from "../../../../context/AppContext";


const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
  },
  screenshot: {
    height: 0,
    paddingTop: "25%",
  },
  navigateContent: {
    paddingTop: "25%",
  },
  avatar: {
    fontSize: "15px",
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  avatarError: {
    fontSize: "15px",
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: theme.palette.error.dark,
  },
  avatarWarning: {
    fontSize: "15px",
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: theme.palette.yellow.dark,
  },
  avatarUnfinished: {
    fontSize: "15px",
    width: theme.spacing(4),
    height: theme.spacing(4),
    animationName: "$pulse",
    animationDuration: "1000ms",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: "ease-in-out",
  },
  "@keyframes pulse": {
    from: {
      backgroundColor: alpha(theme.palette.secondary.dark, 0.75),
    },
    to: {
      backgroundColor: alpha(theme.palette.secondary.light, 1),
    },
  },
  subheader: {
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  ediInput: {
    width: "100%",
  },
  ediSequence: {
    width: "100%",
  },
}));

function ListActions(props) {
  const classes = useStyles();
  const {
    actions,
    removeAction,
    editAction,
    editSequence,
    cancelAction,
    extractorStatus
  } = props;
  const [anchorEl, setAnchorEl] = useState();
  const [anchorElUnfinished, setAnchorElUnfinished] = useState();
  const [currentAction, setCurrentAction] = useState();
  const [showModalEditSequence, setShowModalEditSequence] = useState(false);
  const [editInputSequence, setEditInputSequence] = useState();
  const lastEnd = useRef();
  const intl = useIntl();
  const [state] = useContext(AppContext);

  useEffect(() => {}, [actions, lastEnd]);

  const handleClick = (event, action) => {
    setAnchorEl(event.currentTarget);
    setCurrentAction(action);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteAction = () => {
    setAnchorEl(null);
    removeAction(currentAction.sequence);
  };

  const cancelActionbySequence = () => {
    const { sequence } = currentAction;
    setAnchorElUnfinished(null);
    cancelAction({ sequence });
  };

  const handleEditAction = () => {
    editAction(currentAction);
    setAnchorEl(null);
  };

  const openModalEditSequence = () => {
    setEditInputSequence(currentAction.sequence);
    setShowModalEditSequence(true);
    setAnchorEl(null);
  };

  const handleClickUnfinished = (event, action) => {
    setAnchorElUnfinished(event.currentTarget);
    setCurrentAction(action);
  };

  const handleCloseUnfinished = () => {
    setAnchorElUnfinished(null);
  };

  const getContent = (action) => {
    let content;

    if (action.status === ExtractorActionStatus.NONE) {
      if (action.validation === ExtractorActionValidation.ERROR) {
        content = <ActionError action={action} />;
      } else if (action.validation === ExtractorActionValidation.NOTVALIDATED) {
        content = <ActionWarning action={action} />;
      } else if (action.type === ExtractorActionType.NAVIGATE) {
        content = (
          <ActionContent
            className={classes.screenshot}
            content={{
              text: action.options.text,
              screenshot: action.elementScreenshot,
            }}
          />
        );
      } else if (
        action.type === ExtractorActionType.CLICK ||
        action.type === ExtractorActionType.GET_TEXT ||
        action.type === ExtractorActionType.GET_DATE ||
        action.type === ExtractorActionType.GET_NUMBER ||
        action.type === ExtractorActionType.GET_IMAGE ||
        action.type === ExtractorActionType.GET_FILE ||
        action.type === ExtractorActionType.TYPE_PASSWORD ||
        action.type === ExtractorActionType.TYPE_TEXT ||
        action.type === ExtractorActionType.MOUSE_CLICK ||
        action.type === ExtractorActionType.MOUSE_MOVE ||
        action.type === ExtractorActionType.WAIT_UNTIL_EXISTS ||
        action.type === ExtractorActionType.WAIT_UNTIL_NOT_EXISTS ||
        action.type === ExtractorActionType.WAIT_UNTIL_DISPLAYED ||
        action.type === ExtractorActionType.WAIT_UNTIL_NOT_DISPLAYED ||
        action.type === ExtractorActionType.WAIT_UNTIL_ENABLED ||
        action.type === ExtractorActionType.WAIT_UNTIL_NOT_ENABLED ||
        action.type === ExtractorActionType.CLICK_IF_EXISTS ||
        action.type === ExtractorActionType.CLICK_IF_DISPLAYED ||
        action.type === ExtractorActionType.CLICK_IF_ENABLED ||
        action.type === ExtractorActionType.MOVE ||
        action.type === ExtractorActionType.ASK_ID ||
        action.type === ExtractorActionType.ASK_PASSWORD ||
        action.type === ExtractorActionType.ASK_CODE ||
        action.type === ExtractorActionType.ASK_QUESTION ||
        action.type === ExtractorActionType.ASK_CAPTCHA ||
        action.type === ExtractorActionType.ASK_RECAPTCHA ||
        action.type === ExtractorActionType.CLICK_SELECT
      ) {
        content = (
          <ActionContent
            className={classes.screenshot}
            content={{
              screenshot: action.elementScreenshot,
            }}
          />
        );
      } else if (action.type === ExtractorActionType.GET_TABLE) {
        content = (
          <ActionTable className={classes.navigateContent} action={action} />
        );
      } else if (
        action.type === ExtractorActionType.IF_EXISTS ||
        action.type === ExtractorActionType.IF_NOT_EXISTS ||
        action.type === ExtractorActionType.IF_NOT_EXISTS ||
        action.type === ExtractorActionType.IF_ENABLED ||
        action.type === ExtractorActionType.IF_NOT_ENABLED ||
        action.type === ExtractorActionType.IF_DISPLAYED ||
        action.type === ExtractorActionType.IF_NOT_DISPLAYED
      ) {
        content = (
          <ActionContent
            className={classes.screenshot}
            content={{
              text: `${intl.formatMessage({ id: "go.to" })}: ${
                action.options.number ? action.options.number : "?"
              }`,
              screenshot: action.elementScreenshot,
            }}
          />
        );
      } else if (action.type === ExtractorActionType.WAIT) {
        content = (
          <ActionContent
            className={classes.screenshot}
            content={{
              text: `${intl.formatMessage({ id: "action.wait.seconds" })}: ${
                action.options.number
              }`,
            }}
          />
        );
      } else if (action.type === ExtractorActionType.RAISE_ERROR) {
        content = (
          <ActionContent
            className={classes.screenshot}
            content={{
              text: `${intl.formatMessage({
                id: "action.raise.error.message",
              })}: ${action.options.text}`,
            }}
          />
        );
      } else if (action.type === ExtractorActionType.TYPE_F_ENGINE_FIELD) {
        content = (
          <ActionContent
            className={classes.screenshot}
            content={{
              text: action.options.select.option.split("-")[0].replace(":", ""),
            }}
          />
        );
      } else {
        content = <></>;
      }
    } else {
      content = <ActionUnfinished actionType={action.type} />;
    }

    return content;
  };

  const getStyle = (action) => {
    let style;

    if (action.status === ExtractorActionStatus.NONE) {
      if (action.validation === ExtractorActionValidation.ERROR) {
        style = classes.avatarError;
      } else if (action.validation === ExtractorActionValidation.NOTVALIDATED) {
        style = classes.avatarWarning;
      } else {
        style = classes.avatar;
      }
    } else {
      style = classes.avatarUnfinished;
    }

    return style;
  };

  const getTitle = (action) => {
    let title;
    if (action === ExtractorActionType.NAVIGATE) title = "action.navigate";
    else if (action === ExtractorActionType.SCRIPT) title = "action.script";
    else if (action === ExtractorActionType.CLICK) title = "action.click";
    else if (action === ExtractorActionType.IF_EXISTS)
      title = "action.if.exists";
    else if (action === ExtractorActionType.IF_ENABLED)
      title = "action.if.enabled";
    else if (action === ExtractorActionType.IF_NOT_ENABLED)
      title = "action.if.not.enabled";
    else if (action === ExtractorActionType.IF_NOT_EXISTS)
      title = "action.if.not.exists";
    else if (action === ExtractorActionType.IF_DISPLAYED)
      title = "action.if.displayed";
    else if (action === ExtractorActionType.IF_NOT_DISPLAYED)
      title = "action.if.not.displayed";
    else if (action === ExtractorActionType.IF_DISPLAYED)
      title = "action.if.enabled";
    else if (action === ExtractorActionType.IF_NOT_DISPLAYED)
      title = "action.if.not.enabled";
    else if (action === ExtractorActionType.IF_CONDITION)
      title = "action.if.condition";
    else if (action === ExtractorActionType.TYPE_TEXT)
      title = "action.type.text";
    else if (action === ExtractorActionType.TYPE_PASSWORD)
      title = "action.type.password";
    else if (action === ExtractorActionType.ASK_F_ENGINE_URL)
      title = "action.ask.f.engine.url";
    else if (action === ExtractorActionType.ASK_F_ENGINE_USER)
      title = "action.ask.f.engine.user";
    else if (action === ExtractorActionType.ASK_F_ENGINE_PASSWORD)
      title = "action.ask.f.engine.password";
    else if (action === ExtractorActionType.ASK_F_ENGINE_INVESTOR)
      title = "action.ask.f.engine.investor";
    else if (action === ExtractorActionType.TYPE_F_ENGINE_FIELD)
      title = "action.type.f.engine.field";
    else if (action === ExtractorActionType.ASK_ID) title = "action.ask.id";
    else if (action === ExtractorActionType.ASK_PASSWORD)
      title = "action.ask.password";
    else if (action === ExtractorActionType.ASK_CODE) title = "action.ask.code";
    else if (action === ExtractorActionType.ASK_QUESTION)
      title = "action.ask.question";
    else if (action === ExtractorActionType.ASK_CAPTCHA)
      title = "action.ask.captcha";
    else if (action === ExtractorActionType.ASK_RECAPTCHA)
      title = "action.ask.recaptcha";
    else if (action === ExtractorActionType.GET_TEXT) title = "action.get.text";
    else if (action === ExtractorActionType.GET_IMAGE)
      title = "action.get.image";
    else if (action === ExtractorActionType.GET_FILE) title = "action.get.file";
    else if (action === ExtractorActionType.GET_DATE) title = "action.get.date";
    else if (action === ExtractorActionType.GET_NUMBER)
      title = "action.get.number";
    else if (action === ExtractorActionType.GET_TABLE)
      title = "action.get.table";
    else if (action === ExtractorActionType.SCROLL_UP)
      title = "action.scroll.up";
    else if (action === ExtractorActionType.GO_BACK)
      title = "action.go.back";
    else if (action === ExtractorActionType.GO_FORWARD)
      title = "action.go.forward";
    else if (action === ExtractorActionType.SCROLL_DOWN)
      title = "action.scroll.down";
    else if (action === ExtractorActionType.SCROLL_TOP)
      title = "action.scroll.top";
    else if (action === ExtractorActionType.SCROLL_BOTTOM)
      title = "action.scroll.bottom";
    else if (action === ExtractorActionType.MOUSE_CLICK)
      title = "action.mouse.click";
    else if (action === ExtractorActionType.MOUSE_MOVE)
      title = "action.mouse.move";
    else if (action === ExtractorActionType.WAIT) title = "action.wait";
    else if (action === ExtractorActionType.RAISE_ERROR)
      title = "action.raise.error";
    else if (action === ExtractorActionType.WAIT_UNTIL_EXISTS)
      title = "action.wait.until.exists";
    else if (action === ExtractorActionType.WAIT_UNTIL_NOT_EXISTS)
      title = "action.wait.until.not.exists";
    else if (action === ExtractorActionType.WAIT_UNTIL_DISPLAYED)
      title = "action.wait.until.displayed";
    else if (action === ExtractorActionType.WAIT_UNTIL_NOT_DISPLAYED)
      title = "action.wait.until.not.displayed";
    else if (action === ExtractorActionType.WAIT_UNTIL_ENABLED)
      title = "action.wait.until.enabled";
    else if (action === ExtractorActionType.WAIT_UNTIL_NOT_ENABLED)
      title = "action.wait.until.not.enabled";
    else if (action === ExtractorActionType.CLICK_IF_EXISTS)
      title = "action.click.if.exists";
    else if (action === ExtractorActionType.CLICK_IF_ENABLED)
      title = "action.click.if.enabled";
    else if (action === ExtractorActionType.CLICK_IF_DISPLAYED)
      title = "action.click.if.displayed";
    else if (action === ExtractorActionType.CLICK_SELECT)
      title = "action.click.select";
    else if (action === ExtractorActionType.MOVE) title = "action.move";
    else if (action === ExtractorActionType.END) title = "action.end";
    else title = "";

    return title;
  };

  const clickEditSequence = () => {
    const action = currentAction;
    editSequence({ action, editInputSequence });
    setShowModalEditSequence(false);
  };

  const closeModalEditSequence = () => {
    setShowModalEditSequence(false);
  };

  const onChangeSequenceInput = (event) => {
    setEditInputSequence(event.target.value);
  };

  const componentEditSequence = () => {
    const label = "Sequence";
    return (
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <TextField
            className={classes.ediInput}
            label={label}
            required
            value={editInputSequence}
            onChange={onChangeSequenceInput}
          />
        </Grid>
      </Grid>
    );
  };

  const getCardSubHeader = (action) => {
    let subHeader;
    if (
      action.type === ExtractorActionType.TYPE_TEXT ||
      action.type === ExtractorActionType.NAVIGATE
    ) {
      subHeader = (
        <Tooltip
          title={action.options.text ? action.options.text : ""}
          placement="bottom-start"
        >
          <Typography
            className={classes.subheader}
            variant="subtitle2"
            noWrap
            color="textSecondary"
          >
            {action.options.text}
          </Typography>
        </Tooltip>
      );
    } else if (action.type === ExtractorActionType.CLICK_SELECT) {
      subHeader = (
        <Tooltip
          title={action.options.option ? action.options.option : ""}
          placement="bottom-start"
        >
          <Typography
            className={classes.subheader}
            variant="subtitle2"
            noWrap
            color="textSecondary"
          >
            {action.options.option}
          </Typography>
        </Tooltip>
      );
    } else if (action.type === ExtractorActionType.GET_TABLE) {
      subHeader = (
        <Tooltip
          title={action.options.text ? action.options.text : ""}
          placement="bottom-start"
        >
          <Typography
            className={classes.subheader}
            variant="subtitle2"
            noWrap
            color="textSecondary"
          >
            {action.extractorActionGetTableDto.name}
          </Typography>
        </Tooltip>
      );
    }

    return subHeader;
  };

  const getMenuIcon = (action) => {
    let icon;

    if (action.id != null && state.readOnlyEdit) {
      icon = (<></>);
    } else if (
      extractorStatus === ExtractorStatus.VALIDATING ||
      extractorStatus === ExtractorStatus.VALIDATING_EDITING
    ) {
      icon = <></>;
    } else if (extractorStatus === ExtractorStatus.NONE) {
      icon = (
        <IconButton
          onClick={(e) => {
            handleClick(e, action);
          }}
          size="small"
        >
          <MoreVertOutlinedIcon />
        </IconButton>
      );
    } else if (action.status === ExtractorActionStatus.NONE) {
      icon = <></>;
    } else if (action.status === ExtractorActionStatus.CREATING) {
      icon = (
        <IconButton
          onClick={(e) => {
            handleClickUnfinished(e, action);
          }}
          size="small"
        >
          <MoreVertOutlinedIcon />
        </IconButton>
      );
    } else if (action.status === ExtractorActionStatus.EDITING) {
      icon = (
        <IconButton
          onClick={(e) => {
            handleClickUnfinished(e, action);
          }}
          size="small"
        >
          <MoreVertOutlinedIcon />
        </IconButton>
      );
    }

    return icon;
  };

  return (
    <>
      <List>
        {actions
          .sort((a, b) => a.sequence - b.sequence)
          .map((action) => (
            <ListItem key={action.sequence}>
              <Box className={classes.card} component={Card} raised>
                <CardHeader
                  subheader={getCardSubHeader(action.type)}
                  avatar={
                    <Avatar className={getStyle(action)} variant="rounded">
                      {action.sequence}
                    </Avatar>
                  }
                  title={<FormattedMessage id={getTitle(action.type)} />}
                  action={getMenuIcon(action)}
                />
                {getContent(action)}
              </Box>
            </ListItem>
          ))}
        <ListItem ref={lastEnd} />
      </List>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEditAction}>
          <FormattedMessage id="action.edit" />
        </MenuItem>
        <MenuItem onClick={openModalEditSequence}>
          <FormattedMessage id="action.editsequence" />
        </MenuItem>
        <MenuItem onClick={deleteAction}>
          <FormattedMessage id="action.remove" />
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={anchorElUnfinished}
        keepMounted
        open={Boolean(anchorElUnfinished)}
        onClose={handleCloseUnfinished}
      >
        <MenuItem onClick={cancelActionbySequence}>
          <FormattedMessage id="action.cancel" />
        </MenuItem>
      </Menu>

      {showModalEditSequence && (
        <GenericModal
          title={intl.formatMessage({ id: "list.actions.edit.sequence" })}
          component={componentEditSequence()}
          click={clickEditSequence}
          handleClose={closeModalEditSequence}
        />
      )}
    </>
  );
}

ListActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      sequence: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      data: PropTypes.string,
      selectorValue: PropTypes.string,
    })
  ),
  removeAction: PropTypes.func,
  editAction: PropTypes.func,
  editSequence: PropTypes.func,
  cancelAction: PropTypes.func,
  extractorStatus: PropTypes.number,
};

ListActions.defaultProps = {
  actions: [],
  removeAction: () => {},
  editAction: () => {},
  editSequence: () => {},
  cancelAction: () => {},
  extractorStatus: ExtractorStatus.NONE,
};

export default ListActions;

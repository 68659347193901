import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import FileSaver from "file-saver";
import QueryService from "../../services/QueryService";


function FileDownloader(props) {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const getExtension = (filename) => {
        const re = /(?:\.([^.]+))?$/;
        return re.exec(filename)[1];
      }
    
      const getContentType = (fileName) => {
        const ext = getExtension(fileName);
    
        switch (ext) {
            case "bmp": return "image/bmp";
            case "gif": return "image/gif";
            case "ico": return "image/vnd.microsoft.icon";
            case "jpeg": return "image/jpeg";
            case "jpg": return "image/jpeg";
            case "png": return "image/png";
            case "svg": return "image/svg+xml";
            case "tif": return "image/tiff";
            case "webp": return "image/webp";
    
            case "mp3": return "audio/mp3";
            case "wav": return "audio/wav";
            case "weba": return "audio/webm";
    
            case "mp4": return "video/mp4";
            case "mpeg": return "video/mpeg";
            case "ts": return "video/mp2t";
            case "webm": return "video/webm";
    
    
            case "csv": return "text/csv";
            case "htm": return "text/html";
            case "html": return "text/html";
            case "js": return "text/javascript";
            case "txt": return "text/plain";
    
            case "ttf": return "font/ttf";
    
            case "json": return "application/json";
            case "doc": return "application/msword";
            case "docx": return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            case "pdf": return "application/pdf";
            case "ppt": return "application/vnd.ms-powerpoint";
            case "pptx": return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
            case "bz": return "application/x-bzip";
            case "bz2": return "application/x-bzip2";
            case "gz": return "application/gzip";
            case "jar": return "application/java-archive";
            case "rar": return "application/vnd.rar";
            case "rtf": return "application/rtf";
            case "tar": return "application/x-tar";
            case "xhtml": return "application/xhtml+xml";
            case "xls": return "application/vnd.ms-excel";
            case "xlsx": return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            case "xml": return "application/xml";
            case "zip": return "application/zip";
            case "7z": return "application/x-7z-compressed";
            default: return "text/plain";
        }
      };
    
   
    const downloadFile = (fileName, fileContent) => {
        const linkSource = "data:" + getContentType(fileName) + ";base64," + fileContent;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        document.body.removeChild(downloadLink);
    };

    const getFile = () => {
        QueryService.getFile(props.match.params.token).then((response) => {
        downloadFile(response.data.filename, response.data.fileContent);
        enqueueSnackbar("File saved sucessfully. Please check your download folder for file: " + 
                        response.data.filename, { variant: "info" });
      }).catch((error) => {
        enqueueSnackbar(error, { variant: "error" });
      });
     }

    useEffect(() => { 
        getFile();
     }, []); 

     return (<></>);
}

export default FileDownloader;

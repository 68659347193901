import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import en from '../i18n/en';
import theme from '../style/MuiTheme';
import AppContext from './AppContext';

function AppProvider(props) {
  const { children } = props;

  const [state, setState] = useState({
    i18nConfig: {
      locale: 'en',
      messages: en,
    },
    theme,
    languages: ['en'],
    showDataExtractor: true,
    showDataInserter: true,
    showNavigator: true,
    showDatasetAndQueries: true,
    showScheduler: false,
    readOnlyEdit: false,
    listRefreshInterval: 5000,
    checkImageRefreshInterval: 4000,
    checkPendingAskInterval: 3000
  });

  useEffect(() => {
  }, []);

  return (
    <AppContext.Provider value={[state, setState]}>
      {children}
    </AppContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.shape(PropTypes.object.isRequired),
};

AppProvider.defaultProps = {
  children: {},
};

export default AppProvider;

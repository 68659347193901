class SchedulerDTO {
  constructor(
    id,
    extractorIds,
    scheduleType,
    intervaltime,
    timeVal,
    dayweek,
    dayOfMonth,
    name,
    description
  ) {
    this.id = id;
    this.extractorIds = extractorIds;
    this.scheduleType = scheduleType;
    this.minuteInterval = intervaltime;
    this.time = timeVal;

    this.daysOfWeek = Array.isArray(dayweek) ? dayweek : [dayweek];

    this.dayOfMonth = dayOfMonth;
    this.name = name;
    this.description = description;
  }

}

export default SchedulerDTO;

import ReactDOM from "react-dom";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import GetAppOutlined from '@material-ui/icons/GetAppOutlined';
import ReplayOutlinedIcon from "@material-ui/icons/ReplayOutlined";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useContext, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import ExtractorActionStatus from "../../models/enums/ExtractorActionStatus";
import ExtractorActionType from "../../models/enums/ExtractorActionType";
import ExtractorActionValidation from "../../models/enums/ExtractorActionValidation";
import ExtractorStatus from "../../models/enums/ExtractorStatus";
import ExtractorAction from "../../models/ExtractorAction";
import ExtractorActionGetTable from "../../models/ExtractorActionGetTable";
import ExtractorActionGetTableColumn from "../../models/ExtractorActionGetTableColumn";
import ExtractorService from "../../services/ExtractorService";
import GenericModal from "../shared/GenericModal";
import ListNavigatorActions from "../shared/extractor/actions/ListNavigatorActions";
import SelectAction from "../shared/extractor/actions/SelectAction";
import FieldSelect from "../shared/extractor/actions/FieldSelect";
import InvestorSelect from "../shared/extractor/actions/InvestorSelect";
import Validate from "../shared/extractor/actions/Validate";
import ResponsiveDialog from "../shared/ResponsiveDialog";
import ExtractorActionAskType from "../../models/enums/ExtractorActionAskType";
import AppContext from "../../context/AppContext";
import Normalized from "../shared/extractor/normalized/Normalized";
import HttpService from "../../services/HttpService";

const { lightBlue } = require("@material-ui/core/colors");

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "hidden",
    margin: 0,
  },
  grid: {
    margin: 0,
    width: "100%",
    padding: "10px"
  },
  navigatorField: {
    marginLeft: 18,
    color: "#ffb74d"
  },
  navigatorValue: {
    marginLeft: 10,
    color: "black"
  },
  actions: {
    maxHeight: "77vh",
    overflowY: "auto",
  },
  leftMenu: {
    maxHeight: "77vh",
  },
  normalized: {
    height: "85.4vh",
    maxHeight: "85.4vh",
    overflowY: "scroll",
    userSelect: "none",
  },
  iconButton: {
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  htmlContent: {
    width: "100%"
  },
  fileContent: {
      width: "100%",
      height: "100%"
    }
}));

function NavigatorViewer(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const history = useHistory();
  const [actions, setActions] = useState([]);
  const [currentAction, setCurrentAction] = useState(new ExtractorAction());
  const [showModalConfirmFinish, setShowModalConfirmFinish] = useState(false);
  const [isAdaptable, setIsAdaptable] = useState(true);
  const [image, setImage] = useState(null);
  const [htmlContent, setHtmlContent] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [sections, setSections] = useState([]);
  const [state] = useContext(AppContext);

  const [navigatorData, setNavigatorData] = useState({});
  const [imageGridSize, setImageGridSize] = useState(12);
  const [whatToShow, setWhatToShow] = useState("image");

  const handleMessageNotification = useCallback(
    (message, errorProps) => {
      if (errorProps.variant === "error") {
        setErrorMessage(message);
        setShowErrorMessage(true);
      } else {
        enqueueSnackbar(message, errorProps);
      }
    },
    [errorMessage]
  );

  const componentConfirmFinish = (
      <Typography>
        <FormattedMessage id="navigator.close.confirm" />
      </Typography>
    );

  const clickConfirmFinish = () => {
    history.push("/dashboard");
  };

  const closeModalConfirmFinish = () => {
    setShowModalConfirmFinish(false);
  };

  const checkNavigator = () => {
     HttpService.getExtractorByToken(props.match.params.token)
    .then(
      (res) => {
         if (res.data) {
            setNavigatorData(res.data);
            if (res.data.status === "EXECUTING") setTimeout(() => checkNavigator(), 3000);
         }
      },
      () => {
        enqueueSnackbar("Image for navigator not available yet.", { variant: "error" });
        setTimeout(() => checkNavigator(), 5000);
      }
    )
    .catch((err) => {
      throw new Error(err);
    })
  }


  useEffect(() => {
    checkNavigator();
  }, []);

  useEffect(() => {
    const length = navigatorData?.actions?.length;
    if (length > 0) {
        setActions(navigatorData.actions);
        if (navigatorData.status === "EXECUTING") {
            setImage(navigatorData.actions[length - 1].pageScreenshot);
            setCurrentAction(navigatorData.actions[length - 1]);
        } else {
            setImage(navigatorData.actions[0].pageScreenshot);
            setCurrentAction(navigatorData.actions[0]);
            setImageGridSize(9);
        }
    }
    if (navigatorData.errorMessage != null) enqueueSnackbar(navigatorData.errorMessage, { variant: "error" });
  }, [navigatorData]);

  const showScreen = (action) => {
     setImage(action.pageScreenshot);
     setWhatToShow("image");
     setHtmlContent(null);
     setFileContent(null);
     setFileName(null);
  };

  const showHtmlContent = (action) => {
    setHtmlContent(action.pageHtml);
    setWhatToShow("html");
    setImage(null);
    setFileContent(null);
    setFileName(null);
  };

  const showFileContent = (action) => {
    setFileContent(action.fileContent);
    setWhatToShow("file");
    setFileName(action.fileName);
    setHtmlContent(null);
    setImage(null);
  };

  const getExtension = (filename) => {
    const re = /(?:\.([^.]+))?$/;
    return re.exec(filename)[1];
  }

  const getContentType = () => {
    const ext = getExtension(fileName);

    switch (ext) {
        case "bmp": return "image/bmp";
        case "gif": return "image/gif";
        case "ico": return "image/vnd.microsoft.icon";
        case "jpeg": return "image/jpeg";
        case "jpg": return "image/jpeg";
        case "png": return "image/png";
        case "svg": return "image/svg+xml";
        case "tif": return "image/tiff";
        case "webp": return "image/webp";

        case "mp3": return "audio/mp3";
        case "wav": return "audio/wav";
        case "weba": return "audio/webm";

        case "mp4": return "video/mp4";
        case "mpeg": return "video/mpeg";
        case "ts": return "video/mp2t";
        case "webm": return "video/webm";


        case "csv": return "text/csv";
        case "htm": return "text/html";
        case "html": return "text/html";
        case "js": return "text/javascript";
        case "txt": return "text/plain";

        case "ttf": return "font/ttf";

        case "json": return "application/json";
        case "doc": return "application/msword";
        case "docx": return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        case "pdf": return "application/pdf";
        case "ppt": return "application/vnd.ms-powerpoint";
        case "pptx": return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        case "bz": return "application/x-bzip";
        case "bz2": return "application/x-bzip2";
        case "gz": return "application/gzip";
        case "jar": return "application/java-archive";
        case "rar": return "application/vnd.rar";
        case "rtf": return "application/rtf";
        case "tar": return "application/x-tar";
        case "xhtml": return "application/xhtml+xml";
        case "xls": return "application/vnd.ms-excel";
        case "xlsx": return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        case "xml": return "application/xml";
        case "zip": return "application/zip";
        case "7z": return "application/x-7z-compressed";
        default: return "text/plain";
    }
  };

  const isFileViewable = () => {
    const ext = getExtension(fileName);
    const viewableExtensions = ["bmp", "gif", "jpeg", "jpg", "png", "svg", "tif", "webp",
                                "csv", "htm", "html", "js", "txt", "json", "doc", "docx",
                                "pdf", "ppt", "pptx", "xhtml", "xls", "xlsx", "xml"];
    return viewableExtensions.includes(ext);
  }

  const downloadFile = () => {
      const linkSource = "data:" + getContentType() + ";base64," + fileContent;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

      document.body.removeChild(downloadLink);
  };

  const convertTZ = (date) => {
    return new Date(date).toLocaleString();
  };

  const backdropASK = (
    <Backdrop open style={{ zIndex: "100", color: "#fff" }}>
      <CircularProgress style={{ color: lightBlue[300] }} />
    </Backdrop>
  );

  //ReactDOM.render(backdropASK, document.getElementById("backdropASK"));
  const setLoadingASK = (value) => {
    if (value) {
      ReactDOM.render(backdropASK, document.getElementById("backdropASK"));
    } else {
      ReactDOM.render(<></>, document.getElementById("backdropASK"));
    }
  };
  
  return (
    <>
    {showModalConfirmFinish && (
      <GenericModal
        title={intl.formatMessage({ id: "generic.modal.confirm" })}
        component={componentConfirmFinish}
        click={clickConfirmFinish}
        handleClose={closeModalConfirmFinish}
      />
    )}
      <Grid container spacing={2} direction="row" className={classes.container}>
        <Grid item sm={3}>
          <Typography className={classes.navigatorField} variant="h6" noWrap>
            {intl.formatMessage({ id: "navigator.attribute.user" })}
            <Typography className={classes.navigatorValue} component="span" variant="subtitle1">
              {navigatorData?.userFullName || ''}
            </Typography>
          </Typography>
        </Grid>
        <Grid item sm={7}>
          <Typography className={classes.navigatorField} variant="h6" noWrap>
            {intl.formatMessage({ id: "navigator.attribute.navigator" })}
            <Typography className={classes.navigatorValue} component="span" variant="subtitle1">
              {navigatorData?.name || ''}
            </Typography>
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <Box display="flex" flexDirection="row-reverse">
            <IconButton
              onClick={() => setShowModalConfirmFinish(true)}
              className={classes.iconButton}
            >
              <Tooltip title={intl.formatMessage({ id: "extractor.close" })}>
                <CloseOutlinedIcon />
              </Tooltip>
            </IconButton>
          </Box>
        </Grid>
        <Grid item sm={3}>
          <Typography className={classes.navigatorField} variant="h6" noWrap>
            {intl.formatMessage({ id: "navigator.attribute.timestamp" })}
            <Typography className={classes.navigatorValue} component="span" variant="subtitle1">
              {convertTZ(currentAction?.timestamp) || ''}
            </Typography>
          </Typography>
        </Grid>
        <Grid item sm={7}>
            <Typography className={classes.navigatorField} variant="h6" noWrap>
             {intl.formatMessage({ id: "navigator.attribute.url" })}
             <Typography className={classes.navigatorValue} component="span" variant="subtitle1">
               {currentAction?.pageURL || ''}
             </Typography>
            </Typography>
        </Grid>
        <Grid item sm={2}>
          {fileContent != null ? (
          <Box display="flex" flexDirection="row-reverse">
            <IconButton
              onClick={() => downloadFile()}
              className={classes.iconButton}
            >
              <Tooltip title={intl.formatMessage({ id: "navigator.download" })}>
                <GetAppOutlined />
              </Tooltip>
            </IconButton>
          </Box>
          ) : (<></>)}
        </Grid>


        {navigatorData?.status !== "EXECUTING" ? (
            <Grid item sm={3} className={classes.leftMenu}>
              <Box className={classes.actions}>
                <ListNavigatorActions
                  actions={actions}
                  setCurrentAction={setCurrentAction}
                  showHtmlContent={showHtmlContent}
                  showFileContent={showFileContent}
                  showScreen={showScreen}
                />
              </Box>
            </Grid>
        ) : (<></>)}
        <Grid item sm={imageGridSize}>
          <Box component={Paper} className={classes.normalized}>
            {whatToShow === 'image' && image != null ? (
            <Normalized
              setImage={setImage}
              image={image}
              isAdaptable={isAdaptable}
              displayLines={false}
            />) : (<></>)}
            {whatToShow === 'html' && htmlContent != null ? (
            <TextField className={classes.htmlContent} multiline defaultValue={htmlContent} />
            ) : (<></>)}
            {whatToShow === 'file' && fileContent !== null && isFileViewable() ? (
                  <iframe
                    title="Show PDF"
                    src={"data:" + getContentType() + ";base64," + fileContent}
                    className={classes.fileContent}
                  >
                  </iframe>
            ) : whatToShow === 'file' ? (
                <Typography
                  className={classes.navigatorValue}
                  component="span"
                  variant="subtitle1"
                  style={{ marginLeft: "30px", marginTop: "30px" }}
                >
                    {intl.formatMessage({ id: "navigator.download.message" })}
                </Typography>
                ) : (<></>)}
          </Box>
        </Grid>
        <ResponsiveDialog
          message={errorMessage}
          isOpen={showErrorMessage}
          setOpen={setShowErrorMessage}
        />
      </Grid>
      <ResponsiveDialog
        message={errorMessage}
        isOpen={showErrorMessage}
        setOpen={setShowErrorMessage}
      />
    </>
  );
}

export default NavigatorViewer;



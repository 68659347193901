import HttpService from "./HttpService";

const DatasetService = {
  getDataSetLabelColumn: () => HttpService.getDataSetLabelColumn(),

  createDataset: (dataset) => HttpService.createDataset(dataset),

  dataSetField: (setFields) => HttpService.dataSetField(setFields),

  datasetfieldlist: () => HttpService.datasetfieldlist(),

  labeltablecolumnlist: (LabellistData) =>
    HttpService.labeltablecolumnlist(LabellistData),

  dataSetSave: (step4Data, datasetId) =>
    HttpService.dataSetSave(step4Data, datasetId),

  cancel: () => HttpService.cancel(),

  datasetDashboard: () => HttpService.datasetDashboard(),

  verifyDatasetHasData: (id) => HttpService.verifyDatasetHasData(id),

  datasetDelete: (id) => HttpService.datasetDelete(id),

  datasetRename: (datasetDTO) => HttpService.datasetRename(datasetDTO),

  datasetEdit: (datasetId) => HttpService.datasetEdit(datasetId),

  // eslint-disable-next-line max-len
  RemovedatasetField: (datasetId, datasetFieldId, datasetName) =>
    HttpService.RemovedatasetField(datasetId, datasetFieldId, datasetName),
};

export default DatasetService;

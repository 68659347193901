import { Typography, Grid, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import GenericModal from "../shared/GenericModal";
import QueryService from "../../services/QueryService";
import SchedulerService from "../../services/SchedulerService";
import SchedulerStep2 from "./SchedulerStep_2";
import SchedulerStep3 from "./SchedulerStep_3";
import DatasetTransfer from "../dataset/DatsetTransfer";
import ExtractorService from "../../services/ExtractorService";
import SchedulerDTO from "../../models/enums/SchedulerDTO";
import ExtractorType from "../../models/enums/ExtractorType";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    overflow: "hidden",
    margin: 0,
  },
  grid: {
    margin: 0,
    width: "100%",
  },
  extractorName: {
    marginLeft: 18,
  },
  actions: {
    maxHeight: "77vh",
    overflowY: "auto",
  },
  leftMenu: {
    maxHeight: "90vh",
  },
  normalized: {
    maxHeight: "60vh",
    overflow: "auto",
    overflowX: "hidden",
  },
  iconButton: {
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  table: {
    height: "50vh",
  },
  toolbar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.black,
  },
  typography: {
    fontWeight: "bolder",
  },
  buttonsGroup: {
    marginTop: "2rem",
    marginLeft: "66rem",
    float: "right",
  },
  button: {
    marginLeft: "2rem",
  },
  steps: {
    marginTop: "2rem",
  },
}));

function Scheduler(props) {
  const classes = useStyles();
  const [step, setStep] = useState(1);
  const [Dataset, setDataset] = useState({});
  const [checked, setChecked] = useState([]);
  const [Schedulerstep3checkrow, setSchedulerstep3checkrow] = useState({});
  const [Schedulerstep3check, setSchedulerstep3check] = useState();
  const [Left, setLeft] = useState([]);
  const [Right, setRight] = useState([]);
  const [operator, setOperator] = useState();
  const [QueryDatasetobject, setQueryDatasetobject] = useState({});
  const [QueryLeftData, setQueryleftData] = useState([]);
  const [QueryRightData, setQueryRightData] = useState([]);
  const [Querystep5data, setQuerystep5data] = useState([]);
  const [ShowCancelModel, setShowCancelModel] = useState(false);
  const [SchedulerSaveId, setSchedulerSaveId] = useState(0);
  const [error, setError] = useState("");

  const { location } = props;
  const {
    SchedulerProps,
    SchedulerDatasetProps,
    QueryFieldlistProps,
    QueryFilterlistProps,
    EditProp,
    executeActionFromDashboard,
    QuerystepProp,
  } = location;
  const history = useHistory();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  /*
  useEffect(() => {
    if (EditProp === false) {
      setDataset({});
    } else {
      console.log('dataset useeffect..');
      console.log(QueryDatasetobject);
      // TODO
      // setDataset({ intervaltime: 19, frequency: 'INTERVAL' });
      //QueryService.getDataset().then((response) => {
      //  setDataset(response.data);
      //});
    }
    QueryService.datasetoperator().then((response) => {
      setOperator(response.data);
    });
  }, [EditProp]);
   */

  useEffect(() => {
    if (QuerystepProp === 3) {
      QueryService.fieldoperator(QueryRightData).then(() => {
        QueryService.showExtractorData().then((response) => {
          setQuerystep5data(response.data);
          setStep(4);
        });
      });
    }
  }, [QuerystepProp]);

  // useEffect(() => {}, []);

  useEffect(() => {
    ExtractorService.getExtractors(ExtractorType.EXTRACTOR).then((response) => {
      setQueryleftData(response.data);
      setLeft(response.data);
    });

    ExtractorService.getExtractors(ExtractorType.INSERTOR).then((response) => {
      setQueryleftData(response.data);
      setLeft(response.data);
    });

    if (SchedulerProps) {
      // setRight(QueryFieldlistProps);
      // eslint-disable-next-line array-callback-return
      // QueryFilterlistProps.map((data) => {
      //   data.operator = `${data.operatorId},${data.operatorType}`;
      // });

      // setQueryRightData(QueryFilterlistProps);
      // setSchedulerSaveId(SchedulerProps.id);
      // setStep(QuerystepProp);

    } else if (!SchedulerProps) {
      history.push("/dashboard");
    }
  }, [
    QuerystepProp,
    SchedulerProps,
    QueryFieldlistProps,
    SchedulerDatasetProps,
    history,
    setRight,
    setQueryRightData,
    QueryFilterlistProps,
  ]);

  const handleMessageNotification = useCallback(
    (message, errorProps) => {
      if (errorProps.variant === "error") {
        setErrorMessage(message);
        setShowErrorMessage(true);
      } else {
        enqueueSnackbar(message, errorProps);
      }
    },
    [errorMessage]
  );

  const setQueryData = (data) => {
    setQueryDatasetobject({ ...QueryDatasetobject, ...data });
  };

  /*
  useEffect(() => {
    // eslint-disable-next-line max-len
    console.log(`#${QueryDatasetobject.frequency}
     ${QueryDatasetobject.daymonth}/${QueryDatasetobject.dayweek}
     -${QueryDatasetobject.hour}:${QueryDatasetobject.minute}/${QueryDatasetobject.intervaltime}`);
  }, [QueryDatasetobject])
   */
  const backStep = () => {
    setStep(step - 1);
  };

  const validarCampos = () => {
    const scheduleType = getScheduleType();
    if (scheduleType) {
      if (scheduleType === "MINUTE_INTERVAL") {
        if (!QueryDatasetobject.intervaltime) {
          enqueueSnackbar("Please select an interval", {
            variant: "error",
          });
        } else {
          return true;
        }
      } else if (scheduleType === "DAILY") {
        if (!QueryDatasetobject.hour && QueryDatasetobject.hour !== 0) {
          enqueueSnackbar("Please type the hour", {
            variant: "error",
          });
        } else if (
          !QueryDatasetobject.minute &&
          QueryDatasetobject.minute !== 0
        ) {
          enqueueSnackbar("Please type the minute", {
            variant: "error",
          });
        } else {
          return true;
        }
      } else if (scheduleType === "WEEKLY") {
        if (!QueryDatasetobject?.dayweek[0]) {
          enqueueSnackbar("Please type the day of the week", {
            variant: "error",
          });
        } else if (!QueryDatasetobject.hour && QueryDatasetobject.hour !== 0) {
          enqueueSnackbar("Please type the hour", {
            variant: "error",
          });
        } else if (
          !QueryDatasetobject.minute &&
          QueryDatasetobject.minute !== 0
        ) {
          enqueueSnackbar("Please type the minute", {
            variant: "error",
          });
        } else {
          return true;
        }
      } else if (scheduleType === "MONTHLY") {
        if (!QueryDatasetobject.daymonth) {
          enqueueSnackbar("Please type the day of the month", {
            variant: "error",
          });
        } else if (!QueryDatasetobject.hour && QueryDatasetobject.hour !== 0) {
          enqueueSnackbar("Please type the hour", {
            variant: "error",
          });
        } else if (
          !QueryDatasetobject.minute &&
          QueryDatasetobject.minute !== 0
        ) {
          enqueueSnackbar("Please type the minute", {
            variant: "error",
          });
        } else {
          return true;
        }
      }
    } else {
      enqueueSnackbar("Please select the frequency", {
        variant: "error",
      });
    }
    return false;
  };

  const queryStep1 = () => {
    if (validarCampos()) {
      setStep(2);
    }
  };

  const backToExtractor = () => {
    history.push({
      pathname: "/scheduler-extractor",
    });
  };

  const getScheduleType = () => {
    if (QueryDatasetobject.frequency === "INTERVAL") {
      return "MINUTE_INTERVAL";
    }
    return QueryDatasetobject.frequency;
  };

  const schedulerSave = () => {
    const extractorIds = Right.map((data) => {
      return data.id;
    });

    const scheduleType = getScheduleType();
    const hour =
      QueryDatasetobject.hour < 10
        ? "0" + QueryDatasetobject.hour
        : QueryDatasetobject.hour;
    const minute =
      QueryDatasetobject.minute < 10
        ? "0" + QueryDatasetobject.minute
        : QueryDatasetobject.minute;
    const timeVal = `${hour}:${minute}:00`;

    const schedulerDTO = new SchedulerDTO(
      SchedulerProps.id,
      extractorIds,
      scheduleType,
      QueryDatasetobject.intervaltime,
      timeVal,
      QueryDatasetobject.dayweek,
      QueryDatasetobject.daymonth,
      SchedulerProps.name,
      SchedulerProps.description
    );

    SchedulerService.SchedulerSave(schedulerDTO).then(() => {
        enqueueSnackbar(intl.formatMessage({ id: "scheduler.save.success" }), {
          variant: "success",
        });
        history.push("/dashboard");
      })
      .catch((errorScheduler) => {
        if (errorScheduler.response.status === 409) {
          enqueueSnackbar(intl.formatMessage({ id: "scheduler.save.conflict" }), {
            variant: "error",
          });
        } else {
          enqueueSnackbar(intl.formatMessage({ id: "scheduler.save.error" }), {
            variant: "error",
          });
        }
      });
  };

  const queryStep2 = () => {
    if (EditProp === false) {
      // eslint-disable-next-line array-callback-return
      Right.map((data) => {
        if (data.datasetFieldId === 0) {
          data.datasetFieldId = data.id;
        }
      });
      QueryService.fieldselected(Right).then(() => {
        setStep(step + 1);
      });
      QueryService.datasetFieldQueryForFilter(SchedulerDatasetProps).then(
        (response) => {
          // eslint-disable-next-line array-callback-return
          response.data.map((data) => {
            if (data.datasetFieldId === 0) {
              data.datasetFieldId = data.id;
            }
          });
          setQueryleftData(response.data);
          setLeft(response.data);
        }
      );
    } else {
      // eslint-disable-next-line array-callback-return
      Right.map((data) => {
        data.queryFieldId = 0;
        data.datasetFieldId = data.id;
      });
      setRight((prev) => [...prev]);
      QueryService.fieldselected(Right).then(() => {
        setStep(step + 1);
      });
    }
  };

  const queryStep3 = () => {
    /*
    const resquery3 = QueryRightData.map((q) => {
      if (q.inputValue === '') {
        setError('All fields are mandatory');
        return false;
      } return true;
    });
    if (!resquery3.includes(false)) {
      QueryService.fieldoperator(QueryRightData).then(() => {
        QueryService.showExtractorData().then((response) => {
          setQuerystep5data(response.data);
          setStep(step + 1);
        });
      });
    }
     */
  };

  const handleToggle = (value, data) => () => {
    // setQueryDatasetobject(data);
    setQueryDatasetobject({ ...QueryDatasetobject, ...data });
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const leftChecked = DatasetTransfer.intersection(checked, Left);

  // const QueryLeftChecked = DatasetTransfer.intersection(Querystep4Rightcheck, QueryLeftData);

  const numberOfChecked = (items) =>
    DatasetTransfer.intersection(checked, items).length;

  const updateSequence = (element, index) => {
    element.sequence = index + 1;
  };

  const handleCheckedLeft = () => {
    if (EditProp === false) {
      setLeft(Left.concat(Schedulerstep3checkrow));
      Right.splice(Schedulerstep3check, 1);
      setRight((prev) => [...prev]);
      setSchedulerstep3checkrow({});
      setSchedulerstep3check(undefined);
    } else {
      const step3row = [];
      delete Schedulerstep3checkrow.sort;
      delete Schedulerstep3checkrow.orderSequence;
      step3row.push(Schedulerstep3checkrow);
      setLeft(Left.concat(step3row));
      Right.splice(Schedulerstep3check, 1);
      setRight((prev) => [...prev]);
      setSchedulerstep3checkrow({});
      setSchedulerstep3check(undefined);
    }
    Right.map(updateSequence);
  };

  const handlestep3Checkbox = (e, index, row) => {
    setSchedulerstep3check(index);
    setSchedulerstep3checkrow(row);
  };

  const handleCheckedRight = () => {
    setRight(Right.concat(leftChecked));
    setLeft(DatasetTransfer.not(Left, leftChecked));
    setChecked(DatasetTransfer.not(checked, leftChecked));
    Right.map(updateSequence);
  };

  const handleQueryCheckRight = () => {};

  const handleQueryCheckLeft = () => {};

  const handleToggleAll = (items) => () => {};

  const handleselection = (e, row) => {};

  const handlesort = (e, index) => {
    Right[index].orderType = e.target.value;
    setRight([...Right]);
  };

  const cancelQuery = () => {
    setShowCancelModel(true);
  };

  const handlesetoperator = (e, index) => {
    const operatorvalue = e.target.value;
    const [operatorid, operatortype] = operatorvalue.split(",");
    QueryRightData[index].operator = e.target.value;
    QueryRightData[index].operatorId = operatorid;
    QueryRightData[index].operatorType = operatortype;
    setQueryRightData((prev) => [...prev]);
  };
  const handleoperatorvalue = (e, index) => {
    QueryRightData[index].inputValue = e.target.value;
    setQueryRightData([...QueryRightData]);
  };

  const QueryMoveUpAction = () => {};

  const QueryMoveDownAction = () => {};

  const handleStep3MoveUpAction = () => {
    const findrow = Right.splice(Schedulerstep3check, 1)[0];
    Right.splice(Schedulerstep3check - 1, 0, findrow);
    setRight((prev) => [...prev]);
    setSchedulerstep3check(Schedulerstep3check - 1);
    Right.map(updateSequence);
  };

  const handleStep3MoveDownAction = () => {
    const findrow = Right.splice(Schedulerstep3check, 1)[0];
    Right.splice(Schedulerstep3check + 1, 0, findrow);
    setRight((prev) => [...prev]);
    setSchedulerstep3check(Schedulerstep3check + 1);
    Right.map(updateSequence);
  };

  const componentConfirmFinish = (
    <Typography>
      <FormattedMessage id="extractor.finishconfirm" />
    </Typography>
  );

  const confirmClosefinish = () => {
    QueryService.cancelQuery().then(() => {
      history.push("/dashboard");
    });
  };

  const closeCancelModal = () => {
    setShowCancelModel(false);
  };

  function StepsComponent(steps) {
    switch (steps) {
      case 1:
        return (
          <>
            <SchedulerStep2
              Dataset={SchedulerProps}
              setQueryData={setQueryData}
            />
          </>
        );
      case 2:
        return (
          <>
            <SchedulerStep3
              Left={Left}
              Right={Right}
              checked={checked}
              Schedulerstep3check={Schedulerstep3check}
              handlestep3Checkbox={handlestep3Checkbox}
              leftChecked={leftChecked}
              Schedulerstep3checkrow={Schedulerstep3checkrow}
              handleToggle={handleToggle}
              numberOfChecked={numberOfChecked}
              handleToggleAll={handleToggleAll}
              handleCheckedRight={handleCheckedRight}
              handleCheckedLeft={handleCheckedLeft}
              handleselection={handleselection}
              handlesort={handlesort}
              handleStep3MoveUpAction={handleStep3MoveUpAction}
              handleStep3MoveDownAction={handleStep3MoveDownAction}
            />
          </>
        );
      default:
        break;
    }
  }

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      className={classes.container}
      alignItems="flex-start"
    >
      <Grid item sm={3} mt={2}>
        <Typography className={classes.extractorName} variant="h6" noWrap>
          {SchedulerProps ? SchedulerProps.name : ""}
          {SchedulerProps ? " - " : ""}
          <Typography component="span" variant="subtitle1">
            {SchedulerProps
              ? SchedulerProps.description || SchedulerProps.data
              : ""}
          </Typography>
        </Typography>
      </Grid>
      <Grid item sm={7}></Grid>
      <Grid item sm={2} mt={2}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          mr={2}
        >
          {!executeActionFromDashboard && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={cancelQuery}
            >
              <FormattedMessage id="dataset.cancel" />
            </Button>
          )}
          {step === 1 && (
            <>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={backToExtractor}
              >
                <FormattedMessage id="scheduler.back" />
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={queryStep1}
              >
                <FormattedMessage id="scheduler.next" />
              </Button>
            </>
          )}
          {step === 2 && (
            <>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={backStep}
              >
                <FormattedMessage id="scheduler.back" />
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={!(Right.length > 0)}
                className={classes.button}
                onClick={schedulerSave}
              >
                <FormattedMessage id="dataset.save" />
              </Button>
            </>
          )}
        </Box>
      </Grid>
      {StepsComponent(step)}
      <Grid>
        {ShowCancelModel && (
          <GenericModal
            title={intl.formatMessage({ id: "generic.modal.confirm" })}
            component={componentConfirmFinish}
            click={confirmClosefinish}
            handleClose={closeCancelModal}
          />
        )}
      </Grid>
    </Grid>
  );
}

Scheduler.propTypes = {
  location: PropTypes.shape(PropTypes.object.isRequired),
  SchedulerProps: PropTypes.shape(PropTypes.object.isRequired),
};

Scheduler.defaultProps = {
  location: {},
  SchedulerProps: { name: "", data: "" },
};

export default Scheduler;

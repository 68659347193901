const request = (path, method, body) => fetch(`${process.env.REACT_APP_API_BASE_URL}/${path}`, {
  method,
  body: JSON.stringify(body),
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json;charset=utf-8',
  },
});

const FetchInstance = {
  request,
};

export default FetchInstance;

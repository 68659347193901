import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import {
  Button, Card, CardContent, Divider, Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const defaultState = {
  name: '',
  description: '',
  nameError: '',
  descriptionError: '',
  rename: false,
};

function SchedulerExtractor(props) {
  const history = useHistory();
  const intl = useIntl();
  const [SchedulerModal, setSchedulerModal] = useState(defaultState);

  const { location } = props;
  const {
    SchedulerProps,
  } = location;

  useEffect(() => {
    // if (SchedulerProps && SchedulerProps.name && SchedulerProps.description) {
    if (SchedulerProps && SchedulerProps.name && SchedulerProps.description) {
      // eslint-disable-next-line max-len
      let newState = { nameError: '', descriptionError: '', id: SchedulerProps.id, name: SchedulerProps.name, description: SchedulerProps.description, data: SchedulerProps };
      setSchedulerModal(newState);
    }
  }, [SchedulerProps]);
  const cancel = () => {
    history.goBack()
  }

  const handleNameModal = (event) => setSchedulerModal(
      (prevState) => ({ ...prevState, name: event.target.value }),
  );
  const handleDescriptionModal = (event) => setSchedulerModal(
      (prevState) => ({ ...prevState, description: event.target.value }),
  );
  const clickToScheduler = () => {
    let success = true;
    let newState = { ...SchedulerModal, nameError: '', descriptionError: '' };
    if (SchedulerModal.name.trim().length === 0) {
      success = false;
      newState = { ...newState, nameError: intl.formatMessage({ id: 'extractor.list.error.name' }) };
      setSchedulerModal(newState);
    }

    if (SchedulerModal.description.trim().length === 0) {
      success = false;
      newState = { ...newState, descriptionError: intl.formatMessage({ id: 'extractor.list.error.description' }) };
      setSchedulerModal(newState);
    }

    if (success) {
      setSchedulerModal(newState);
      history.push({
        pathname: '/Scheduler',
        SchedulerProps: {
          id: SchedulerProps?.id ?? null,
          name: SchedulerModal.name,
          description: SchedulerModal.description,
          data: SchedulerModal,
        },
      });
    }
  };
  return (
      <div>
        <Card style={{ marginTop: '2rem' }}>
          <CardContent>
            <Typography varinat="h1" noWrap>
              <FormattedMessage id="scheduler.label.step1" />
            </Typography>
            <FormattedMessage id="extractor.list.modal.name">
              {(text) => (
                  <TextField
                    autoFocus
                    margin="normal"
                    required
                    fullWidth
                    id="nameModal"
                    name="nameModal"
                    value={SchedulerModal.name}
                    inputProps={{ maxLength: 128 }}
                    label={text}
                    error={SchedulerModal.nameError !== ''}
                    helperText={SchedulerModal.nameError}
                    onChange={handleNameModal}
                  />
              )}
            </FormattedMessage>
            <FormattedMessage id="extractor.list.modal.description">
              {(text) => (
                  <TextField
                    multiline
                    minRows={3}
                    maxRows={4}
                    margin="normal"
                    required
                    fullWidth
                    id="descriptionModal"
                    name="descriptionModal"
                    value={SchedulerModal.description}
                    inputProps={{ maxLength: 1024 }}
                    label={text}
                    error={SchedulerModal.descriptionError !== ''}
                    helperText={SchedulerModal.descriptionError}
                    onChange={handleDescriptionModal}
                  />
              )}
            </FormattedMessage>
            <Button onClick={clickToScheduler} color="secondary" variant="contained">
              <FormattedMessage id="generic.modal.next" />
            </Button>
            <Button onClick={cancel} color="secondary" variant="outlined" style={{ float: 'right' }}>
              <FormattedMessage id="generic.modal.cancel" />
            </Button>
          </CardContent>
          <Divider />
        </Card>
      </div>
  );
}

SchedulerExtractor.propTypes = {
  location: PropTypes.shape(PropTypes.object.isRequired),
  SchedulerProps: PropTypes.shape(PropTypes.object.isRequired),
};

SchedulerExtractor.defaultProps = {
  location: {},
  SchedulerProps: { name: '', data: '' },
};

export default SchedulerExtractor;

import {
  TextField, Grid,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import GenericModal from './GenericModal';
import ExtractorActionType from '../../models/enums/ExtractorActionType';

function GenericIf(props) {
  const {
    click, type,
  } = props;
  const [showConfirmationModal, setShowConfirmationModal] = useState(true);
  const [selectSequence, setSelectSequence] = useState('');

  const intl = useIntl();

  useEffect(() => {
  });

  const onChangeSelectSequence = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '') {
      setSelectSequence('');
    } else if (e.target.value.length <= 10 && re.test(e.target.value)) {
      const parsedInt = parseInt(e.target.value, 10);
      setSelectSequence(parsedInt);
    }
  };

  const getConfirmationModalTitle = () => intl.formatMessage({ id: 'extractor.action.modal.title.confirm' });
  const getConfirmationModalContent = () => {
    const modalContent = (
      <Grid container spacing={1}>
        {(type === ExtractorActionType.IF_EXISTS || type === ExtractorActionType.IF_NOT_EXISTS
          || type === ExtractorActionType.IF_DISPLAYED || type === ExtractorActionType.IF_NOT_DISPLAYED
          || type === ExtractorActionType.IF_ENABLED || type === ExtractorActionType.IF_NOT_ENABLED) && (
          <Grid item sm={12}>
            <TextField
              fullWidth
              label={intl.formatMessage({ id: 'select.sequence' })}
              value={selectSequence}
              onChange={onChangeSelectSequence}
              type="text"
            />
          </Grid>
        )}
      </Grid>
    );
    return modalContent;
  };

  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const clickInGenericModal = () => {
    click(selectSequence);
  };

  return (
    <div>
      {showConfirmationModal && (
        <GenericModal
          title={getConfirmationModalTitle()}
          click={clickInGenericModal}
          component={getConfirmationModalContent()}
          handleClose={handleConfirmationModalClose}
        />
      )}
    </div>
  );
}

GenericIf.propTypes = {
  click: PropTypes.func,
  type: PropTypes.string,
};

GenericIf.defaultProps = {
  click: undefined,
  type: ExtractorActionType.IF_EXISTS,
};

export default GenericIf;

import { Link, makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#1a237e',
  },
}));

function AppToolbar() {
  const classes = useStyles();
  const location = useLocation();
  const pathnames = location.pathname.split('/');
  let path = '';
  pathnames.forEach((pathname) => {
    switch (pathname) {
      case 'settings':
        path = 'settings';
        break;
      case 'help':
        path = 'help';
        break;
      default:
        break;
    }
  });
  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar variant="dense">
        <Typography variant="h6">
          <Link color="primary" href="/" underline="none">
            <Typography variant="h5">
              Peerveil
            </Typography>
          </Link>
        </Typography>
        <Breadcrumb />
        {path !== 'help' ? (
          <Button color="primary" startIcon={<HelpOutlineOutlinedIcon />} variant="text" href="/help">
            <FormattedMessage id="app.toolbar.help" />
          </Button>
           )
           : ( 
            <Button color="secondary" startIcon={<HelpOutlineOutlinedIcon color="secondary" />} variant="text" href="/help">
            <FormattedMessage id="app.toolbar.help" />
          </Button>
        )}
        {path !== 'settings'
          ? (
            <Button color="primary" startIcon={<SettingsOutlinedIcon />} variant="text" href="/settings">
              <FormattedMessage id="app.toolbar.settings" />
            </Button>
          )
          : (
            <Button
              color="secondary"
              startIcon={<SettingsOutlinedIcon color="secondary" />}
              variant="text"
              href="/settings"
            >
              <FormattedMessage id="app.toolbar.settings" />
            </Button>
          )}
        <Button color="primary" startIcon={<ExitToAppOutlinedIcon />} variant="text" href="/logout">
          <FormattedMessage id="app.toolbar.logout" />
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default AppToolbar;

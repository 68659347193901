import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef } from "react";

function ImageMapper(props) {
  const { img, areas, onClick } = props;
  const imgRef = useRef(null);
  const canvasRef = useRef(null);

  const getContext = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.setLineDash([]);
    ctx.lineWidth = 3;
    ctx.fillStyle = "rgba(255, 255, 255, 0.5)";

    return ctx;
  }, []);

  const drawAreas = (arr) => {
    const canvas = canvasRef.current;
    const ctx = getContext();
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.setLineDash([5, 3]);
    ctx.lineWidth = 2;
    ctx.strokeStyle = "green";

    arr.forEach((a) => {
      ctx.strokeRect(a.x, a.y, a.width, a.height);
    });
  };

  const drawRect = (rect) => {
    const ctx = getContext();
    ctx.fillRect(rect.x, rect.y, rect.width, rect.height);
    ctx.strokeRect(rect.x, rect.y, rect.width, rect.height);
  };

  useEffect(() => {
    const ctx = getContext();
    ctx.clearRect(0, 0, imgRef.current.width, imgRef.current.height);
    if (img.includes("ndex")) {
      imgRef.current.setAttribute("src", img);
    } else {
      imgRef.current.setAttribute("src", `data:image/png;base64, ${img}`);
    }
    if (areas && areas.length > 0) {
      drawAreas(areas);
    }
  }, [img, areas, getContext]);

  const handleOnLoad = (event) => {
    canvasRef.current.width = event.currentTarget.width;
    canvasRef.current.height = event.currentTarget.height;
    if (areas && areas.length > 0) drawAreas(areas);
  };

  const handleMouseEnter = (e) => {
    const element = e.target;
    const coords = element.getAttribute("coords").split(",");
    drawRect(
      {
        x: coords[0],
        y: coords[1],
        width: coords[2] - coords[0],
        height: coords[3] - coords[1],
      },
      true
    );
  };

  const handleMouseLeave = () => {
    drawAreas(areas);
  };

  const handleOnClick = (e) => {
    onClick(e);
  };

  return (
    <div style={{ position: "relative" }}>
      <img src="" alt="" useMap="#map" ref={imgRef} onLoad={handleOnLoad} />

      <canvas
        ref={canvasRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          pointerEvents: "none",
          zIndex: 2,
        }}
      />

      <map name="map">
        {areas &&
          areas
            .filter((e) => e !== undefined)
            .map((e, index) => (
              <area
                key={index}
                alt={index}
                shape="rect"
                coords={`${e.x}, 
              ${e.y}, 
              ${e.x + e.width}, 
              ${e.y + e.height} `}
                target="_self"
                href="#"
                onClick={handleOnClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                selector={e.selector}
                xpath={e.xpath}
                textcontent={e.textContent}
              />
            ))}
      </map>
    </div>
  );
}

ImageMapper.propTypes = {
  img: PropTypes.string,
  areas: PropTypes.arrayOf(PropTypes.shape({})),
  onClick: PropTypes.func,
};

ImageMapper.defaultProps = {
  img: "",
  areas: [],
  onClick: () => {},
};

export default ImageMapper;

class ExtractorActionGetTable {
  constructor(name, rowSelector, columns) {
    this.id = undefined;
    this.name = name;
    this.rowSelector = rowSelector;
    this.columns = columns;
  }
}

export default ExtractorActionGetTable;

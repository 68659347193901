import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Box,
  Divider,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  TextField,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import GenericModal from "../shared/GenericModal";
import QueryService from "../../services/QueryService";
import Rename from "../../models/enums/RenameDataset";

const defaultState = {
  name: "",
  description: "",
  show: false,
  nameError: "",
  descriptionError: "",
  rename: false,
};

const useStyles = makeStyles(() => ({
  title: {
    marginTop: -3,
    marginLeft: 5,
  },
  add: {
    marginRight: -7,
  },
}));

function QueryList() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState();
  const [QueryModal, setQueryModal] = useState(defaultState);
  const [CurrentQuery, setCurrentQuery] = useState();
  const [rows, setRows] = useState([]);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    QueryService.QueryDashboard().then(
      (response) => {
        const { data } = response;
        setRows(data);
      },
      () => {
        enqueueSnackbar(intl.formatMessage({ id: "dataset.list.error.get" }), {
          variant: "error",
        });
      }
    );
  }, [enqueueSnackbar, intl]);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentQuery(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickToExtractor = () => {
    let success = true;
    let newState = { ...QueryModal, nameError: "", descriptionError: "" };
    if (QueryModal.name.trim().length === 0) {
      success = false;
      newState = {
        ...newState,
        nameError: intl.formatMessage({ id: "extractor.list.error.name" }),
      };
      setQueryModal(newState);
    }

    if (QueryModal.description.length === 0) {
      success = false;
      newState = {
        ...newState,
        descriptionError: intl.formatMessage({
          id: "extractor.list.error.description",
        }),
      };
      setQueryModal(newState);
    }

    if (success) {
      if (QueryModal.rename) {
        const rename = new Rename(
          CurrentQuery.id,
          QueryModal.name,
          QueryModal.description
        );
        QueryService.QueryRename(rename)
          .then(() => {
            const findindex = rows.findIndex(
              (data) => data.id === CurrentQuery.id
            );
            rows[findindex].name = QueryModal.name;
            rows[findindex].description = QueryModal.description;
            setRows((prev) => [...prev]);
            enqueueSnackbar("Query renamed successfully.", {
              variant: "success",
            });
            setQueryModal({ ...defaultState, show: false });
          })
          .catch(() => {
            setQueryModal(newState);
          });
      }
    }
  };

  const handleNameModal = (event) =>
    setQueryModal((prevState) => ({ ...prevState, name: event.target.value }));

  const handleDescriptionModal = (event) =>
    setQueryModal((prevState) => ({
      ...prevState,
      description: event.target.value,
    }));

  const handleModalClose = () => {
    setQueryModal((prevState) => ({ ...prevState, show: false }));
  };

  const handleRename = () => {
    setQueryModal({
      ...defaultState,
      show: true,
      rename: true,
      name: CurrentQuery.name,
      description: CurrentQuery.description,
    });
    setAnchorEl(null);
  };

  const handleEdit = (row) => {
    QueryService.QueryEdit(row.id).then((response) => {
      const { data } = response;
      history.push({
        pathname: "/Query",
        QueryProps: data.query,
        QueryDatasetProps: data.queryDataset,
        QueryFieldlistProps: data.queryFieldList,
        QueryFilterlistProps: data.queryFilterList,
        QuerystepProp: 2,
        EditProp: false,
      });
    });
  };

  const handleEditExecute = () => {
    QueryService.QueryEdit(CurrentQuery.id).then((response) => {
      const { data } = response;
      history.push({
        pathname: "/Query",
        QueryProps: data.query,
        QueryDatasetProps: data.queryDataset,
        QueryFieldlistProps: data.queryFieldList,
        QueryFilterlistProps: data.queryFilterList,
        QuerystepProp: 3,
        EditProp: false,
        executeActionFromDashboard: true,
      });
    });
  };

  const handleDelete = (row) => {
    QueryService.QueryDelete(row.id).then(() => {
      const deletrow = rows.filter((data) => data.id !== row.id);
      setRows(deletrow);
      setAnchorEl(null);
    });
  };

  const componentSelectAction = (
    <>
      <FormattedMessage id="extractor.list.modal.name">
        {(text) => (
          <TextField
            autoFocus
            margin="normal"
            required
            fullWidth
            id="nameModal"
            name="nameModal"
            value={QueryModal.name}
            inputProps={{ maxLength: 128 }}
            label={text}
            error={QueryModal.nameError !== ""}
            helperText={QueryModal.nameError}
            onChange={handleNameModal}
          />
        )}
      </FormattedMessage>
      <FormattedMessage id="extractor.list.modal.description">
        {(text) => (
          <TextField
            multiline
            minRows={3}
            maxRows={4}
            margin="normal"
            required
            fullWidth
            id="descriptionModal"
            name="descriptionModal"
            value={QueryModal.description}
            inputProps={{ maxLength: 1024 }}
            label={text}
            error={QueryModal.descriptionError !== ""}
            helperText={QueryModal.descriptionError}
            onChange={handleDescriptionModal}
          />
        )}
      </FormattedMessage>
    </>
  );

    const showConfirmDeleteModal = (row) => {
      setSelectedRow(row);
      setShowModalConfirmDelete(true);
    }

    const componentConfirmDelete = (
      <Typography>
        <FormattedMessage id="query.modal.delete.text" />
      </Typography>
    );

    const clickConfirmDelete = () => {
      handleDelete(selectedRow);
      setShowModalConfirmDelete(false);
    };

    const closeModalConfirmDelete = () => {
      setShowModalConfirmDelete(false);
    };

  return (
    <>
    {showModalConfirmDelete && (
              <GenericModal
                title={intl.formatMessage({ id: "generic.modal.confirm" })}
                component={componentConfirmDelete}
                click={clickConfirmDelete}
                handleClose={closeModalConfirmDelete}
              />
            )}
      <Toolbar variant="dense">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Box display="flex" ml="-10px">
              <Tooltip title="Info about Query">
                <PageviewOutlinedIcon color="secondary" />
              </Tooltip>
              <Typography variant="h6" className={classes.title}>
                <FormattedMessage id="dashboard.query" />
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Link to="/query-extractor">
              <IconButton
                size="small"
                color="secondary"
                className={classes.add}
              >
                <Tooltip title="Add New">
                  <AddCircleOutlinedIcon />
                </Tooltip>
              </IconButton>
            </Link>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider />
      <TableContainer>
        <Table size="small">
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell align="left" scope="row">
                  <Typography variant="body2">{row.name}</Typography>
                </TableCell>
                <TableCell align="right">
                  {row.type === "CUSTOM" ? (<EditIcon
                    onClick={() => {
                      handleEdit(row);
                    }}
                    style={{ cursor: "pointer" }}
                  />) : (<></>)}
                  <IconButton
                    onClick={(e) => handleClick(e, row)}
                    size="small"
                    style={{ padding: 0, marginTop: "-15px" }}
                  >
                    <MoreVertOutlinedIcon />
                  </IconButton>
                  <DeleteIcon
                    onClick={() => {
                      showConfirmDeleteModal(row);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => handleEditExecute()}>
                      <FormattedMessage id="dashboard.execute" />
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <FormattedMessage id="dashboard.copy" />
                    </MenuItem>
                    <MenuItem onClick={handleRename}>
                      <FormattedMessage id="dashboard.rename" />
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {QueryModal.show && (
        <FormattedMessage id="query.label.rename">
          {(text) => (
            <GenericModal
              title={text}
              component={componentSelectAction}
              click={clickToExtractor}
              handleClose={handleModalClose}
              text
            />
          )}
        </FormattedMessage>
      )}
    </>
  );
}

export default QueryList;

import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  Button
} from "@material-ui/core";
import { alpha } from '@material-ui/core/styles'
import TextField from "@material-ui/core/TextField";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ExtractorActionStatus from "../../../../models/enums/ExtractorActionStatus";
import ExtractorActionType from "../../../../models/enums/ExtractorActionType";
import ExtractorActionValidation from "../../../../models/enums/ExtractorActionValidation";
import ExtractorStatus from "../../../../models/enums/ExtractorStatus";
import GenericModal from "../../GenericModal";
import ActionError from "./ActionError";
import ActionContent from "./ActionContent";
import ActionTable from "./ActionTable";
import ActionUnfinished from "./ActionUnfinished";
import ActionWarning from "./ActionWarning";
import AppContext from "../../../../context/AppContext";


const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
  },
  screenshot: {
    height: 0,
    paddingTop: "25%",
  },
  navigateContent: {
    paddingTop: "25%",
  },
  subheader: {
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

function ListNavigatorActions(props) {
  const classes = useStyles();
  const {
    actions,
    setCurrentAction,
    showHtmlContent,
    showFileContent,
    showScreen
  } = props;
  const [anchorEl, setAnchorEl] = useState();
  const [anchorElUnfinished, setAnchorElUnfinished] = useState();
  const [showModalEditSequence, setShowModalEditSequence] = useState(false);
  const [editInputSequence, setEditInputSequence] = useState();
  const lastEnd = useRef();
  const intl = useIntl();
  const [state] = useContext(AppContext);

  useEffect(() => {}, [actions, lastEnd]);

  const getContent = (action) => {
    let content;

    if (action.pageScreenshot !== null) {
      content = (
          <ActionContent
            className={classes.screenshot}
            content={{
              screenshot: action.pageScreenshot,
            }}
          />
        );
      }

    return content;
  };

  const getCardSubHeader = (action) => {
    let screen = (<Grid item sm={4}>
        <Button variant="text" disabled>{intl.formatMessage({ id: "navigator.viewer.screen" })}</Button></Grid>);
    let html = (<Grid item sm={4}>
        <Button variant="text" disabled>{intl.formatMessage({ id: "navigator.viewer.html" })}</Button></Grid>);
    let file = (<Grid item sm={4}>
        <Button variant="text" disabled>{intl.formatMessage({ id: "navigator.viewer.file" })}</Button></Grid>);

    if (action.pageScreenshot !== null) {
      screen = (
        <Grid item sm={4}>
            <Button variant="text" onClick={() => showScreen(action)}>
                {intl.formatMessage({ id: "navigator.viewer.screen" })}</Button>
        </Grid>
      );
    }
    if (action.pageHtml !== null) {
          html = (
            <Grid item sm={4}>
               <Button variant="text" onClick={() => showHtmlContent(action)}>
                    {intl.formatMessage({ id: "navigator.viewer.html" })}</Button>
            </Grid>
          );
        }

    if (action.fileName !== null) {
      file = (
        <Grid item sm={4}>
            <Tooltip title={action.fileName} placement="botton">
            <Button variant="text" onClick={() => showFileContent(action)} alt={action.fileName}>
                {intl.formatMessage({ id: "navigator.viewer.file" })}
            </Button>
            </Tooltip>
        </Grid>
      );
    }

    return (
        <Grid container spacing={2} direction="col" className={classes.container}>
            {screen}{html}{file}
        </Grid>);
  };

  return (
    <>
      <List>
        {actions
          .sort((a, b) => a.sequence - b.sequence)
          .map((action) => (
            <ListItem key={action.sequence}>
              <Box className={classes.card} component={Card} raised>
                <CardHeader
                  subheader={getCardSubHeader(action)}
                />
                {getContent(action)}
              </Box>
            </ListItem>
          ))}
        <ListItem ref={lastEnd} />
      </List>
    </>
  );
}

ListNavigatorActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      pageScreenshot: PropTypes.string.isRequired,
      pageURL: PropTypes.string.isRequired,
      pageHtml: PropTypes.string.isRequired,
      fileName: PropTypes.string,
      fileURL: PropTypes.string,
    })
  ),
  setCurrentAction: PropTypes.func,
  showHtmlContent: PropTypes.func,
  showFileContent: PropTypes.func,
  showScreen: PropTypes.func,
};

ListNavigatorActions.defaultProps = {
  actions: [],
  setCurrentAction: () => {},
  showHtmlContent: () => {},
  showFileContent: () => {},
  showScreen: () => {},
};

export default ListNavigatorActions;

const ExtractorStatus = {
  NONE: 0,
  CREATING: 1,
  EDITING: 2,
  VALIDATING: 3,
  VALIDATING_EDITING: 4,

};

export default ExtractorStatus;

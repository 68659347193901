export default {
  EQUAL: 'EQUAL',
  NOT_EQUAL: 'NOT_EQUAL',
  CONTAINS: 'CONTAINS',
  NOT_CONTAINS: 'NOT_CONTAINS',
  GREATER_THAN: 'GREATER_THAN',
  LESS_THAN: 'LESS_THAN',
  GREATER_EQUAL_THAN: 'GREATER_EQUAL_THAN',
  LESS_EQUAL_THAN: 'LESS_EQUAL_THAN',
};

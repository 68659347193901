import {
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import PropTypes, { number } from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ExtractorType from "../../../../models/enums/ExtractorType";
import ExtractorActionType from "../../../../models/enums/ExtractorActionType";
import ExtractorActionTypeDisplay from "../../../../models/enums/ExtractorActionTypeDisplay";
import ExtractorStatus from "../../../../models/enums/ExtractorStatus";
import GenericModal from "../../GenericModal";
import IfConditionType from "../../../../models/enums/IfConditionType";
import DataType from "../../../../models/enums/DataType";
import "../../../app/App.css";
import AppContext from "../../../../context/AppContext";

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  input: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  getTableInput: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  getNumberInput: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },

  getDateInput: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  linearProgress: {
    width: "100%",
    margin: "30px 0 0 0",
  },
  buttonGroup: {
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
}));

function SelectAction(props) {
  const classes = useStyles();
  const intl = useIntl();
  const {
    source,
    isNew,
    addAction,
    getActionByType,
    maxSequence,
    allSequence,
    canAddAction,
    validate,
    resequence,
    extractorStatus,
    extractorProps,
  } = props;
  const [showModalSelectAction, setShowModalSelectAction] = useState(true);
  const [sequence, setSequence] = useState();
  const [credentials, setCredentials] = useState("");
  const [selectType, setSelectType] = useState(ExtractorActionType.NAVIGATE);
  const [selectCondition, setSelectCondition] = useState(IfConditionType.EQUAL);
  const [actionSelect, setactionSelect] = useState("");
  const [state] = useContext(AppContext);
  const [inputState, setInputState] = useState({
    value: "",
    error: false,
    errorMsg: "",
  });
  const [inputStateNumber, setInputStateNumber] = useState({
    value: "",
    error: false,
    errorMsg: "",
  });
  const [selectInputValueError, setSelectInputValueError] = useState("");

  useEffect(() => {
    setSequence(maxSequence + 10 - ((maxSequence + 10) % 10));
  }, [isNew, maxSequence, canAddAction]);

  useEffect(() => {
    if (extractorProps && extractorProps.actions) {
      // EDIT
      setShowModalSelectAction(false);
    }
  }, []);

  const clickAddNew = () => {
    setShowModalSelectAction(true);
  };

  const closeModalSelectAction = () => {
    setShowModalSelectAction(false);
  };

  const onChangeSelectInput = (event) => {
    setSequence(event.target.value);
    if (
      event.target.value < 0 ||
      event.target.value <= allSequence[0] ||
      allSequence.find((data) => event.target.value === data)
    ) {
      setSelectInputValueError("Invalid Value");
    } else {
      setSelectInputValueError("");
    }
  };

  const onChangeSelectType = (event) => {
    setInputState((prevState) => ({
      ...prevState,
      value: "",
      error: false,
      errorMsg: "",
    }));
    setSelectType(event.target.value);
    setCredentials("");
  };

  const onChangeActionSelect = (event) => {
    const actionSelectValue = getActionByType([
      ExtractorActionType.GET_TEXT,
      ExtractorActionType.GET_DATE,
      ExtractorActionType.GET_NUMBER,
      ExtractorActionType.GET_IMAGE,
      ExtractorActionType.GET_FILE,
      ExtractorActionType.SCRIPT,
    ])[event.target.value];
    actionSelectValue.index = event.target.value;
    setactionSelect(actionSelectValue);
  };
  const onChangeSelectCondition = (event) => {
    setSelectCondition(event.target.value);
  };

  const onChangeInput = (event) => {
    if (event.target.value && inputState.errorMsg === "Required") {
      setInputState((prevState) => ({
        ...prevState,
        error: false,
        errorMsg: "",
      }));
    }
    setInputState((prevState) => ({
      ...prevState,
      value: event.target.value,
    }));
  };

  const onChangeInputWait = (event) => {
    const re = /^[0-9\b]+$/;
    const { value } = event.target;

    if (re.test(value)) {
      const parsedInt = Math.max(0, Math.min(60, parseInt(value, 10)));
      setInputState((prevState) => ({
        ...prevState,
        value: parsedInt,
        error: false,
      }));
    } else if (value === "") {
      setInputState((prevState) => ({ ...prevState, value: 0, error: false }));
    }
  };
  const onChangeInputNumber = (event) => {
    const re = /^[0-9\b]+$/;
    const { value } = event.target;
    if (re.test(value)) {
      setInputStateNumber((prevState) => ({
        ...prevState,
        value,
        error: false,
      }));
    } else if (value === "") {
      setInputStateNumber((prevState) => ({
        ...prevState,
        value: 0,
        error: false,
      }));
    }
  };

  const onChangeInputMessage = (event) => {
    const error = event.target.value === "";
    const errorMsg = "Required";
    setInputState((prevState) => ({
      ...prevState,
      value: event.target.value,
      error,
      errorMessage: errorMsg,
    }));
  };

  const resetModalValues = () => {
    setInputState((prevState) => ({
      ...prevState,
      value: "",
      error: false,
      errorMsg: "",
    }));
    setInputStateNumber((prevState) => ({
      ...prevState,
      value: "",
      error: false,
      errorMsg: "",
    }));
    setSelectType(ExtractorActionType.NAVIGATE);
    setShowModalSelectAction(false);
    setactionSelect("");
    setSelectCondition(IfConditionType.EQUAL);
  };

  const clickSelectAction = () => {
    if (
      selectType === ExtractorActionType.NAVIGATE ||
      selectType === ExtractorActionType.GET_TABLE ||
      selectType === ExtractorActionType.WAIT ||
      selectType === ExtractorActionType.IF_CONDITION ||
      selectType === ExtractorActionType.RAISE_ERROR
    ) {
      if (inputState.value && !inputState.error) {
        const inputValue = inputState.value;
        const inputValueNumber = inputStateNumber.value;
        addAction({
          sequence,
          selectType,
          inputValue,
          inputValueNumber,
          selectCondition,
          actionSelect,
        });
        resetModalValues();
      } else {
        const errorMsg =
          selectType === ExtractorActionType.NAVIGATE
            ? "Invalid URL"
            : "Required";
        setInputState((prevState) => ({
          ...prevState,
          value: "",
          error: true,
          errorMsg,
        }));
      }
    } else {
      addAction({ sequence, selectType, credentials });
      resetModalValues();
    }
  };

  const titleSelectAction = "Add Action";

  const getinputStateLabel = () => {
    if (selectType === ExtractorActionType.NAVIGATE) {
      return "URL";
    }
    if (selectType === ExtractorActionType.GET_TABLE) {
      return "Table Name";
    }
    if (selectType === ExtractorActionType.WAIT) {
      return "Time (max 60s)";
    }
    if (selectType === ExtractorActionType.RAISE_ERROR) {
      return "Message";
    }

    return "";
  };

  const formatKeytointl = (key) => {
    const keyChanged = key.toLowerCase();
    return keyChanged.replaceAll("_", ".");
  };

  const renderSelectOptions = () => {
    let select = <></>;
    if (isNew) {
      select = (
        <Select
          native
          labelId="action-type-label"
          label="Type"
          onChange={onChangeSelectType}
          value={selectType}
          id="action-type"
        >
          <option value="NAVIGATE">
            {intl.formatMessage({ id: "extractor.action.navigate" })}
          </option>
        </Select>
      );
    } else {
      select = (
        <Select
          native
          labelId="action-type-label"
          label="Type"
          onChange={onChangeSelectType}
          value={selectType}
          id="action-type"
        >
          {Object.keys(ExtractorActionType)
            .sort()
            .filter((key) => extractorProps.extractorType === ExtractorType.EXTRACTOR ||
                              extractorProps.extractorType === ExtractorType.NAVIGATOR ?
                              ExtractorActionTypeDisplay[key].displayInExtractor :
                              ExtractorActionTypeDisplay[key].displayInInsertor)
            .map((key) => (
              <option value={ExtractorActionType[key]} key={ExtractorActionType[key]}>
                {intl.formatMessage({
                  id: `extractor.action.${formatKeytointl(key)}`,
                })}
              </option>
            ))}
        </Select>
      );
    }
    return select;
  };

  // {
  //   intl.formatMessage({
  //     // id: "title.welcome", 
  //     id: `extractor.action.${formatKeytointl(key)}`,
  //     })
  //     }

  const getHelpByInputType = () => {
    return (
        <div>
          {'\n'}
          <br />
          <p style={{
            whiteSpace: "pre-line"
        }}>
            {
              intl.formatMessage({
              defaultMessage: " ",
              id: `extractor.action.help.${formatKeytointl(selectType)}`,
            })
            }</p>
          </div>);
  }

    const getInputByType = () => {
    let input = <></>;
    if (selectType === ExtractorActionType.NAVIGATE) {
      input = (
        <TextField
          required
          className={classes.input}
          label={getinputStateLabel()}
          value={inputState.value}
          error={inputState.error}
          helperText={inputState.errorMsg}
          type="text"
          onChange={onChangeInput}
        />
      );
    } else if (selectType === ExtractorActionType.GET_TABLE) {
      input = (
        <TextField
          required
          className={classes.input}
          label={getinputStateLabel()}
          value={inputState.value}
          error={inputState.error}
          helperText={inputState.errorMsg}
          type="text"
          onChange={onChangeInput}
        />
      );
    } else if (selectType === ExtractorActionType.WAIT) {
      input = (
        <TextField
          required
          className={classes.input}
          label={getinputStateLabel()}
          value={inputState.value}
          error={inputState.error}
          helperText={inputState.errorMsg}
          type="text"
          onChange={onChangeInputWait}
        />
      );
    } else if (selectType === ExtractorActionType.RAISE_ERROR) {
      input = (
        <TextField
          required
          className={classes.input}
          label={getinputStateLabel()}
          value={inputState.value}
          error={inputState.error}
          helperText={inputState.errorMsg}
          type="text"
          onChange={onChangeInputMessage}
        />
      );
    } else if (selectType === ExtractorActionType.IF_CONDITION) {
      input = (
        <Grid item sm={12}>
          <Grid item sm={12}>
            <Select
              native
              fullWidth
              labelId="action-select"
              label="Type"
              onChange={onChangeActionSelect}
              value={actionSelect.index}
              id="action-select"
            >
              <option value="">Select Action</option>
              {getActionByType([
                ExtractorActionType.GET_TEXT,
                ExtractorActionType.GET_DATE,
                ExtractorActionType.GET_NUMBER,
                ExtractorActionType.GET_IMAGE,
                ExtractorActionType.GET_FILE,
                ExtractorActionType.SCRIPT,
              ]).map((action, index) => (
                <option value={index}>
                  {`${action.sequence} - ${action.label}`}
                </option>
              ))}
            </Select>
          </Grid>
          {actionSelect.type !== "" && (
            <>
              <Grid container sm={12}>
                <Grid item sm={6}>
                  <Select
                    native
                    fullWidth
                    labelId="select-condition"
                    label="Select Condition"
                    onChange={onChangeSelectCondition}
                    value={selectCondition}
                    id="select-condition"
                  >
                    {/* eslint-disable-next-line array-callback-return,consistent-return */}
                    {Object.keys(IfConditionType).map((type) => {
                      if (
                        type === IfConditionType.EQUAL ||
                        type === IfConditionType.NOT_EQUAL ||
                        type === IfConditionType.CONTAINS ||
                        type === IfConditionType.NOT_CONTAINS
                      ) {
                        return (
                          <option value={IfConditionType[type]}>
                            {intl.formatMessage({
                              id: `extractor.action.if.condition.${type.toLowerCase()}`,
                            })}
                          </option>
                        );
                      }
                      if (
                        actionSelect.type === ExtractorActionType.GET_NUMBER ||
                        actionSelect.type === ExtractorActionType.GET_DATE ||
                        (actionSelect.type === ExtractorActionType.SCRIPT &&
                          (actionSelect.options.script.returnType ===
                            DataType.NUMBER ||
                            actionSelect.options.script.returnType ===
                              DataType.DATETIME))
                      ) {
                        if (
                          IfConditionType[type] ===
                            IfConditionType.GREATER_THAN ||
                          IfConditionType[type] === IfConditionType.LESS_THAN ||
                          IfConditionType[type] ===
                            IfConditionType.GREATER_EQUAL_THAN ||
                          IfConditionType[type] ===
                            IfConditionType.LESS_EQUAL_THAN
                        ) {
                          return (
                            <option value={IfConditionType[type]}>
                              {intl.formatMessage({
                                id: `extractor.action.if.condition.${type.toLowerCase()}`,
                              })}
                            </option>
                          );
                        }
                      }
                    })}
                  </Select>
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    className={classes.input}
                    label="Value"
                    value={inputState.value}
                    error={inputState.error}
                    helperText={inputState.errorMsg}
                    type="text"
                    onChange={onChangeInput}
                  />
                </Grid>
              </Grid>
              <Grid item sm={12}>
                <TextField
                  className={classes.input}
                  label="Next Action Sequence"
                  value={inputStateNumber.value}
                  error={inputStateNumber.error}
                  helperText={inputStateNumber.errorMsg}
                  type="text"
                  onChange={onChangeInputNumber}
                />
              </Grid>
            </>
          )}
        </Grid>
      );
    }

    return input;
  };

  const componentSelectAction = (
    <>
      <Grid container spacing={2}>
        <Grid item sm={3}>
          <TextField
            label="Sequence"
            required
            value={sequence}
            error={selectInputValueError !== ""}
            helperText={selectInputValueError}
            onChange={onChangeSelectInput}
          />
        </Grid>
        <Grid item sm={9}>
          <FormControl className={classes.formControl}>
            <InputLabel id="action-type-label">
              <FormattedMessage id="select.action.title" />
            </InputLabel>
            {renderSelectOptions()}
          </FormControl>
        </Grid>

        <Grid item sm={12}>
          {getInputByType()}
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <TextField
          style={
            selectType === ExtractorActionType.ASK_F_ENGINE_PASSWORD ||
            selectType === ExtractorActionType.ASK_F_ENGINE_USER ||
            selectType === ExtractorActionType.ASK_F_ENGINE_URL
              ? { display: "block" }
              : { display: "none" }
          }
          className="inputASKFEngine"
          type={selectType === ExtractorActionType.ASK_F_ENGINE_PASSWORD ? "password" : "text"}
          label="Value"
          value={credentials}
          onChange={(e) => setCredentials(e.target.value)}
        />
      </Grid>
      <Grid item sm={12}>
        <div>
          {getHelpByInputType()}
        </div> 
      </Grid>
    </>
  );

  return (
    <>
      <Grid container>
        <Grid item sm={12}>
          <ButtonGroup size="small" className={classes.buttonGroup}>
            {/* eslint-disable-next-line max-len */}
            <Button
              disabled={
                extractorStatus !== ExtractorStatus.NONE || !canAddAction
              }
              onClick={clickAddNew}
              variant="contained"
              color="secondary"
            >
              <FormattedMessage id="select.action.add" />
            </Button>
            {source !== 'navigator' ? (
            <Button
              disabled={extractorStatus !== ExtractorStatus.NONE || isNew || (extractorProps.id != null && state.readOnlyEdit)}
              onClick={validate}
            >
              <FormattedMessage id="select.action.validate" />
            </Button>
            ) : (<></>)}
            {source !== 'navigator' ? (
            <Button
              disabled={extractorStatus !== ExtractorStatus.NONE || isNew || (extractorProps.id != null && state.readOnlyEdit)}
              onClick={resequence}
            >
              <FormattedMessage id="select.action.resequence" />
            </Button>
            ) : (<></>)}
          </ButtonGroup>
        </Grid>
      </Grid>
      {showModalSelectAction && (
        <GenericModal
          title={titleSelectAction}
          component={componentSelectAction}
          click={clickSelectAction}
          handleClose={closeModalSelectAction}
        />
      )}
    </>
  );
}

SelectAction.propTypes = {
  isNew: PropTypes.bool,
  addAction: PropTypes.func,
  getActionByType: PropTypes.func,
  maxSequence: PropTypes.number,
  canAddAction: PropTypes.bool,
  resequence: PropTypes.func,
  allSequence: PropTypes.arrayOf(number),
  validate: PropTypes.func,
  extractorStatus: PropTypes.number,
  extractorProps: PropTypes.shape(PropTypes.object.isRequired),
};

SelectAction.defaultProps = {
  isNew: false,
  addAction: () => {},
  getActionByType: () => {},
  resequence: () => {},
  maxSequence: 0,
  canAddAction: false,
  allSequence: [],
  validate: () => {},
  extractorStatus: ExtractorStatus.NONE,
  extractorProps: {},
};

export default SelectAction;

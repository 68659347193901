import { Box, LinearProgress, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useContext, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import LocalStorageService from "../../../../services/LocalStorageService";
import GenericModalAsk from "../../GenericModalAsk";
import ExtractorActionType from "../../../../models/enums/ExtractorActionType";
import ExtractorService from "../../../../services/ExtractorService";
import AppContext from "../../../../context/AppContext";

function Validate(props) {
  const { isValidating, callback, changeIsAdaptable } = props;
  const [progress, setProgress] = useState(0);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [showAskModal, setShowAskModal] = useState(false);
  const [askAction, setAskAction] = useState({});
  const intl = useIntl();
  const [state] = useContext(AppContext);

  const validateActions = useCallback(() => {
    setShow(true);
    setProgress(0);
    setMessage(intl.formatMessage({ id: "validate.progress" }));

    const promise = new Promise((resolve) => {
      const data = "";
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = true;

      xhr.addEventListener("readystatechange", () => {
        if (xhr.readyState === 3) {
          const { responseText } = xhr;
          const arrayDatas = responseText.split("\n");
          if (arrayDatas.length >= 5) {
            const actuallyTask = arrayDatas[arrayDatas.length - 3];
            const resss = JSON.parse(actuallyTask);
            let actuallydata;
            try {
              actuallydata = resss?.data?.split("|") || [];

              if (
                actuallydata[1] === ExtractorActionType.ASK_CAPTCHA ||
                actuallydata[1] === ExtractorActionType.ASK_QUESTION ||
                actuallydata[1] === ExtractorActionType.ASK_CODE ||
                actuallydata[1] === ExtractorActionType.ASK_PASSWORD ||
                actuallydata[1] === ExtractorActionType.ASK_RECAPTCHA ||
                actuallydata[1] === ExtractorActionType.ASK_ID ||
                actuallydata[1] === ExtractorActionType.ASK_F_ENGINE_USER ||
                actuallydata[1] === ExtractorActionType.ASK_F_ENGINE_URL ||
                actuallydata[1] === ExtractorActionType.ASK_F_ENGINE_PASSWORD ||
                actuallydata[1] === ExtractorActionType.ASK_F_ENGINE_INVESTOR
              ) {
                ExtractorService.checkPendingAsk(props.extractorType).then(
                  (res) => {
                    if (res.data !== "") {
                      if (res.data.type === ExtractorActionType.NAVIGATE) {
                        res.data.updated = true;
                        ExtractorService.updatePendingAskAction(res.data);
                      } else {
                        setShowAskModal(true);
                        setAskAction(res.data);
                      }
                    }
                  },
                  () => {
                    setMessage(
                      intl.formatMessage({ id: "extractor.list.error.execute" })
                    );
                  }
                );
              }
              setProgress(eval(actuallydata[0]) * 100);
            } catch (error) {
              throw new Error(error);
            }
          }
        }

        if (xhr.readyState === 4) {
          const { responseText } = xhr;
          const arrayDatas = responseText.split("\n");
          const actuallyTask = arrayDatas[arrayDatas.length - 3];
          const actuallydata = JSON.parse(actuallyTask);
          // console.log('actuallydata-if', actuallydata);
          if (actuallydata) {
            resolve({ success: true });
            setMessage(intl.formatMessage({ id: "validate.success" }));
          } else if (actuallydata && actuallydata.error !== null) {
            const errorSequence = actuallydata.data.sequence;
            const errorMessage = intl.formatMessage({
              id: `${
                actuallydata.error !== null
                  ? actuallydata.error
                  : actuallydata.code
              }`,
            });
            resolve({ success: false, errorSequence, errorMessage });
            setMessage(intl.formatMessage({ id: "validate.error" }));
          } else {
            resolve({ success: true });
            setMessage(intl.formatMessage({ id: "validate.success" }));
          }
          setProgress(100);
        }
      });
      setProgress(0);
      xhr.open(
        "GET",
        `${process.env.REACT_APP_API_BASE_URL}/extractor/validate`
      );
      const accessToken = LocalStorageService.getAccessToken();
      xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`);
      xhr.send(data);
      const refreshToken = LocalStorageService.getRefreshToken();
      if (refreshToken) {
        return fetch(`${process.env.REACT_APP_API_BASE_URL}/auth`, {
          method: "PUT",
          body: JSON.stringify({ refreshToken }),
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json;charset=utf-8",
          },
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
          }

          return response.json();
        }).then((jsonResponse) => {
            LocalStorageService.setAccessToken(jsonResponse.accessToken);
            LocalStorageService.setRefreshToken(jsonResponse.refreshToken);
            LocalStorageService.setUsername(jsonResponse.username);
        })
          .catch(() => {
            LocalStorageService.clear();
            window.location.href = "/logout";
          });
      }
      return null;
    });

    return promise;
  }, [intl]);

  const handleModalAskClose = () => {
    setShowAskModal(false);
  };

  useEffect(() => {
    if (isValidating)
      validateActions().then((res) => {
        callback(res);
        changeIsAdaptable();
      });
  }, [isValidating, callback, validateActions]);

  const checkPendingAsk = useCallback(() => {

    // When creating an insertor, if we hit the refresh button, this gets called with NULL. Not sure
    // why, or how to fix this.
    if (props.extractorType != null) {
      ExtractorService.checkPendingAsk(props.extractorType).then((res) => {
        if (res.data !== "") {
          if (res.data.type === ExtractorActionType.NAVIGATE) {
            res.data.updated = true;
            ExtractorService.updatePendingAskAction(res.data);
          }
        }
      }
    ); 
  }
  }, []);
  let intervalRef;
  useEffect(() => {
    checkPendingAsk();
    intervalRef = setInterval(checkPendingAsk.checkPendingAskInterval, state);
    return () => {
      clearInterval(intervalRef);
    };
  }, [checkPendingAsk]);

  return (
    <>
      {show && (
        <>
          <Box display="flex" alignItems="center" m={0} p={0}>
            <Box width="100%" mx={1}>
              <LinearProgress
                variant="determinate"
                color="secondary"
                value={progress || 0}
              />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(
                progress || 0
              )}%`}</Typography>
            </Box>
          </Box>
          <Typography variant="subtitle2" align="center">
            <i>{message}</i>
          </Typography>
          {showAskModal && (
            <GenericModalAsk
              askAction={askAction}
              handleClose={handleModalAskClose}
            />
          )}
        </>
      )}
    </>
  );
}

Validate.propTypes = {
  isValidating: PropTypes.bool,
  callback: PropTypes.func,
  changeIsAdaptable: PropTypes.func,
  extractorType: PropTypes.string
};

Validate.defaultProps = {
  isValidating: false,
  callback: () => {},
  changeIsAdaptable: () => {},
  extractorType: null
};

export default Validate;

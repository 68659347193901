import { Link, Typography } from "@material-ui/core";
import React from "react";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="secondary" href="https://www.ndexsystems.com/">
        Ndex Systems Inc.
      </Link>
      {" - "}
      {new Date().getFullYear()}
      <span style={{ color: "#303030" }}>
        <br />
        {process.env.REACT_APP_VERSION_NAME}
        {"."}
        {process.env.REACT_APP_GITHUB_SHA.substring(0, 7)}
      </span>
    </Typography>
  );
}

export default Copyright;

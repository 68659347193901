/* eslint-disable react/jsx-boolean-value */
import {
  Box,
  CardContent,
  CardHeader,
  Fade,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/es/Button/Button";
import Container from "@material-ui/core/es/Container/Container";
import TextField from "@material-ui/core/esm/TextField/TextField";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import User from "../../models/User";
import UserService from "../../services/UserService";
import Copyright from "../login/Copyright";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    marginTop: theme.spacing(12),
  },
  cardHeader: {
    marginTop: theme.spacing(6),
  },
  cardContent: {
    margin: theme.spacing(2, 2, 2, 2),
  },
  submit: {
    marginTop: theme.spacing(6),
  },
  textField: {
    fullWidth: true,
  },
}));

function ForgotPassword() {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const handleUsername = (event) => {
    setUsernameError("");
    setUsername(event.target.value);
  };

  const createAccount = () => {
    const newUser = new User(username, "", "", "");
    UserService.forgot(newUser)
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar("Please, check your email", { variant: "info" });
          setEmailSent(true);
        } else if (res.status === 400) {
          enqueueSnackbar("E-mail not found", { variant: "error" });
        }
      })
      .catch(() => {
        enqueueSnackbar("Internal server error", { variant: "error" });
      });
  };

  const handleClick = () => {
    /* eslint-disable no-useless-escape */
    /* eslint-disable max-len */
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        username
      )
    ) {
      setUsernameError("Email is not valid");
    } else {
      createAccount();
    }
  };

  const handleReturnClick = () => {
     history.push("/login");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      createAccount();
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Fade in timeout={500}>
          <Card className={classes.card} onKeyDown={handleKeyDown}>
            <CardHeader
              className={classes.cardHeader}
              align="center"
              title={<FormattedMessage id="forgotPassword.title" />}
              subheader={<FormattedMessage id="forgotPassword.subtitle" />}
            />
            <CardContent className={classes.cardContent}>
              <form noValidate>
              {!emailSent ? (
                <>
                <FormattedMessage id="forgotPassword.email">
                  {(text) => (
                    <TextField
                      autoFocus
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label={text}
                      name="email"
                      autoComplete="email"
                      value={username}
                      onChange={handleUsername}
                      error={usernameError !== ""}
                      helperText={usernameError}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleOutlinedIcon
                              color={username ? "secondary" : "inherit"}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </FormattedMessage>

                <Button
                  type="button"
                  fullWidth
                  size="large"
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                  onClick={handleClick}
                >
                  <Typography>
                    <FormattedMessage id="forgotPassword.submit" />
                  </Typography>
                </Button></>
                ) : (
                <>
                    <FormattedMessage id="forgotPassword.message">
                        {(text) => (
                            <Typography>{text}</Typography>
                        )}
                    </FormattedMessage>

                    <Button
                      type="button"
                      fullWidth
                      size="large"
                      variant="contained"
                      color="secondary"
                      className={classes.submit}
                      onClick={handleReturnClick}
                    >
                      <Typography>
                        <FormattedMessage id="forgotPassword.return" />
                      </Typography>
                    </Button>
                 </>
                )}
              </form>
            </CardContent>
          </Card>
        </Fade>
        <Box mt={4}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}

export default ForgotPassword;

/* eslint-disable react/jsx-boolean-value */
import {
  Box,
  CardContent,
  CardHeader,
  Fade, IconButton,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/es/Button/Button";
import Container from "@material-ui/core/es/Container/Container";
import TextField from "@material-ui/core/esm/TextField/TextField";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { string } from "prop-types";
import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import User from "../../models/User";
import UserService from "../../services/UserService";
import Copyright from "../login/Copyright";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    marginTop: theme.spacing(12),
  },
  cardHeader: {
    marginTop: theme.spacing(6),
  },
  cardContent: {
    margin: theme.spacing(2, 2, 2, 2),
  },
  submit: {
    marginTop: theme.spacing(6),
  },
  textField: {
    fullWidth: true,
  },
}));

function SignUp() {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [retypePasswordError, setRetypePasswordError] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (username) {
        validEmail(username);
      }
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [username]);

  const validEmail = (email) => {
    UserService.isEmailValid(email)
      .then((res) => {
        if (res.data) {
          setUsernameError("");
        } else {
          setUsernameError("Email already exists");
        }
      })
      .catch(() => {});
  };

  const handleValid = () => {
    if (
      usernameError !== "" ||
      passwordError !== "" ||
      retypePasswordError !== "" ||
      firstName === "" ||
      lastName === ""
    ) {
      return false;
    }
    return true;
  };

  const handleRetypePassword = (event) => {
    setRetypePasswordError("");
    setRetypePassword(event.target.value);
  };
  const handleFirstName = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastName = (event) => {
    setLastName(event.target.value);
  };

  const handlePassword = (event) => {
    setPasswordError("");
    setPassword(event.target.value);
  };

  const createAccount = () => {
    if (handleValid()) {
      const newUser = new User(username, password, firstName, lastName);
      UserService.create(newUser)
        .then(() => {})
        .catch(() => {
          enqueueSnackbar("There was an error on the server.", {
            variant: "error",
          });
        });
      enqueueSnackbar("Please check your email.", { variant: "info" });
      history.push("/welcome");
    } else {
      enqueueSnackbar("Please complete all the fields correctly", {
        variant: "error",
      });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  /* eslint-disable no-useless-escape */
  const handleClick = () => {
    if (firstName.length === 0) {
      enqueueSnackbar("Please type the first name", {
        variant: "error",
      });
      return;
    }

    if (lastName.length === 0) {
      enqueueSnackbar("Please type the last name", {
        variant: "error",
      });
      return;
    }

    let passwordMessage = 'Password must contain a minimum of 9 characters.';
        passwordMessage += ' Being 4 letters, 4 numbers, 1 special character and no white spaces.';
    /* eslint-disable max-len */
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        username.trim()
      )
    ) {
      setUsernameError("Email is not valid");
    } else if (!/(?=.*?[A-Za-z]{4,})/.test(password)) {
      setPasswordError(passwordMessage);
    } else if (!/(?=.*?[0-9]{4,})/.test(password)) {
      setPasswordError(passwordMessage);
    } else if (!/(?=.*?[^\w\s]{1,})/.test(password)) {
      setPasswordError(passwordMessage);
    } else if (/(?=.*?[+]{1,})/.test(password)) {
      setPasswordError(passwordMessage);
    } else if (!/.{9,}/.test(password)) {
      setPasswordError(passwordMessage);
    } else if (password !== retypePassword) {
      setRetypePasswordError("Password is different");
    } else {
      createAccount();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      createAccount();
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Fade in timeout={500}>
          <Card className={classes.card} onKeyDown={handleKeyDown}>
            <CardHeader
              className={classes.cardHeader}
              align="center"
              title={<FormattedMessage id="signUp.title" />}
              subheader={<FormattedMessage id="signUp.subtitle" />}
            />
            <CardContent className={classes.cardContent}>
              <form noValidate>
                <FormattedMessage id="signUp.firstName">
                  {(text) => (
                    <TextField
                      autoFocus
                      margin="normal"
                      required
                      fullWidth
                      id="firstName"
                      label={text}
                      name="firstName"
                      autoComplete="firstName"
                      value={firstName}
                      onChange={handleFirstName}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleOutlinedIcon
                              color={firstName ? "secondary" : "inherit"}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </FormattedMessage>
                <FormattedMessage id="signUp.lastName">
                  {(text) => (
                    <TextField
                      autoFocus
                      margin="normal"
                      required
                      fullWidth
                      id="lastName"
                      label={text}
                      name="lastName"
                      autoComplete="lastName"
                      value={lastName}
                      onChange={handleLastName}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleOutlinedIcon
                              color={lastName ? "secondary" : "inherit"}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </FormattedMessage>
                <FormattedMessage id="signUp.email">
                  {(text) => (
                    <TextField
                      autoFocus
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label={text}
                      name="email"
                      autoComplete="email"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      error={usernameError !== ""}
                      helperText={usernameError}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleOutlinedIcon
                              color={username ? "secondary" : "inherit"}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </FormattedMessage>
                <FormattedMessage id="signUp.password">
                  {(text) => (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label={text}
                      type={showPassword ? "text" : "password"}
                      id="password"
                      autoComplete="current-password"
                      value={password}
                      onChange={handlePassword}
                      error={passwordError !== ""}
                      helperText={passwordError}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlinedIcon
                              color={password ? "secondary" : "inherit"}
                            />
                          </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleClickShowPassword}>
                                {showPassword ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </FormattedMessage>
                <FormattedMessage id="signUp.retypePassword">
                  {(text) => (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label={text}
                      type={showConfirmPassword ? "text" : "password"}
                      id="password"
                      value={retypePassword}
                      onChange={handleRetypePassword}
                      error={retypePasswordError !== ""}
                      helperText={retypePasswordError}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlinedIcon
                              color={retypePassword ? "secondary" : "inherit"}
                            />
                          </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleClickShowConfirmPassword}>
                                {showConfirmPassword ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </FormattedMessage>

                <Button
                  type="button"
                  fullWidth
                  size="large"
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                  onClick={handleClick}
                >
                  <Typography>
                    <FormattedMessage id="signUp.create" />
                  </Typography>
                </Button>
              </form>
            </CardContent>
          </Card>
        </Fade>
        <Box mt={4}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}

export default SignUp;

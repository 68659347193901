import { Button } from '@material-ui/core';
import React from 'react';
import * as fileSaver from 'file-saver';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import QueryService from '../../services/QueryService';

function QueryExportExcel(props) {
  const { Querystep5data } = props;
  const exportToCSV = () => {
    QueryService.QueryGetLinkExport().then((response) => {
      const blob = new Blob([response.data],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fileSaver.saveAs(blob, `${Querystep5data}.xlsx`);
    }).catch(() => {
    });
  };

  return (
    <>
      <Tooltip title="Export to excel">
        <Button
          onClick={() => exportToCSV()}
          variant="contained"
          color="secondary"
          style={{ marginLeft: '28px' }}
        >
          Export
        </Button>
      </Tooltip>
    </>
  );
}

QueryExportExcel.propTypes = {
  Querystep5data: PropTypes.string,
};

QueryExportExcel.defaultProps = {
  Querystep5data: '',
};
export default QueryExportExcel;

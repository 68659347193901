export default {
  NAVIGATE: "NAVIGATE",
  CLICK: "CLICK",
  TYPE_TEXT: "TYPE_TEXT",
  CLICK_SELECT: "CLICK_SELECT",
  TYPE_PASSWORD: "TYPE_PASSWORD",
  GET_TEXT: "GET_TEXT",
  GET_NUMBER: "GET_NUMBER",
  GET_DATE: "GET_DATE",
  GET_TABLE: "GET_TABLE",
  GET_IMAGE: "GET_IMAGE",
  GET_FILE: "GET_FILE",
  MOVE: "MOVE",
  WAIT: "WAIT",
  RAISE_ERROR: "RAISE_ERROR",
  WAIT_UNTIL_EXISTS: "WAIT_UNTIL_EXISTS",
  WAIT_UNTIL_NOT_EXISTS: "WAIT_UNTIL_NOT_EXISTS",
  WAIT_UNTIL_DISPLAYED: "WAIT_UNTIL_DISPLAYED",
  WAIT_UNTIL_NOT_DISPLAYED: "WAIT_UNTIL_NOT_DISPLAYED",
  WAIT_UNTIL_ENABLED: "WAIT_UNTIL_ENABLED",
  WAIT_UNTIL_NOT_ENABLED: "WAIT_UNTIL_NOT_ENABLED",
  CLICK_IF_EXISTS: "CLICK_IF_EXISTS",
  CLICK_IF_DISPLAYED: "CLICK_IF_DISPLAYED",
  CLICK_IF_ENABLED: "CLICK_IF_ENABLED",
  IF_EXISTS: "IF_EXISTS",
  IF_NOT_EXISTS: "IF_NOT_EXISTS",
  IF_DISPLAYED: "IF_DISPLAYED",
  IF_NOT_DISPLAYED: "IF_NOT_DISPLAYED",
  IF_ENABLED: "IF_ENABLED",
  IF_NOT_ENABLED: "IF_NOT_ENABLED",
  SCRIPT: "SCRIPT",
  END: "END",
  SCROLL_UP: "SCROLL_UP",
  SCROLL_DOWN: "SCROLL_DOWN",
  MOUSE_CLICK: "MOUSE_CLICK",
  MOUSE_MOVE: "MOUSE_MOVE",
  IF_CONDITION: "IF_CONDITION",
  ASK_F_ENGINE_USER: "ASK_F_ENGINE_USER",
  ASK_F_ENGINE_URL: "ASK_F_ENGINE_URL",
  ASK_F_ENGINE_PASSWORD: "ASK_F_ENGINE_PASSWORD",
  ASK_F_ENGINE_INVESTOR: "ASK_F_ENGINE_INVESTOR",
  TYPE_F_ENGINE_FIELD: "TYPE_F_ENGINE_FIELD",
  ASK_ID: "ASK_ID",
  ASK_PASSWORD: "ASK_PASSWORD",
  ASK_CODE: "ASK_CODE",
  ASK_QUESTION: "ASK_QUESTION",
  ASK_CAPTCHA: "ASK_CAPTCHA",
  SCROLL_TOP: "SCROLL_TOP",
  SCROLL_BOTTOM: "SCROLL_BOTTOM",
  ASK_RECAPTCHA: "ASK_RECAPTCHA",
  GO_BACK: "GO_BACK",
  GO_FORWARD: "GO_FORWARD",
};

const { createTheme } = require('@material-ui/core');
const { orange, grey, indigo, yellow } = require('@material-ui/core/colors');

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: indigo[50],
      dark: indigo[50],
    },
    secondary: {
      dark: orange[200],
      main: orange[300],
      light: orange[100],
    },
    grey: {
      light: indigo[600],
    },
    yellow: {
      light: yellow[300],
      dark: yellow[600],
    },
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
      color: 'secondary',
    },
    MuiButton: {
      variant: 'outlined',
    },
    MuiPaper: {
      elevation: 0,
    },
    MuiSelect: {
      variant: 'outlined',
    },
    MuiFormControl: {
      variant: 'outlined',
      color: 'secondary',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

theme.overrides = {
  MuiTypography: {
    colorError: {
      color: theme.palette.error.light,
    },
  },
  MuiSnackbarContent: {
    root: {
      borderStyle: 'solid',
      border: 0,
      borderLeft: 1,
      borderLeftWidth: 5,
      backgroundColor: [[theme.palette.primary.dark], '!important'],
      color: [[theme.palette.text.primary], '!important'],
    },
  },
};

export default theme;

export default {
  NAVIGATE: { displayInExtractor: true, displayInInsertor: true },
  CLICK: { displayInExtractor: true, displayInInsertor: true },
  TYPE_TEXT: { displayInExtractor: true, displayInInsertor: true },
  CLICK_SELECT: { displayInExtractor: true, displayInInsertor: false },
  TYPE_PASSWORD: { displayInExtractor: true, displayInInsertor: true },
  GET_TEXT: { displayInExtractor: true, displayInInsertor: false },
  GET_NUMBER: { displayInExtractor: true, displayInInsertor: false },
  GET_DATE: { displayInExtractor: true, displayInInsertor: false },
  GET_TABLE: { displayInExtractor: true, displayInInsertor: false },
  GET_IMAGE: { displayInExtractor: true, displayInInsertor: false },
  GET_FILE: { displayInExtractor: true, displayInInsertor: false },
  MOVE: { displayInExtractor: true, displayInInsertor: false },
  WAIT: { displayInExtractor: true, displayInInsertor: true },
  RAISE_ERROR: { displayInExtractor: true, displayInInsertor: false },
  WAIT_UNTIL_EXISTS: { displayInExtractor: true, displayInInsertor: false },
  WAIT_UNTIL_NOT_EXISTS: { displayInExtractor: true, displayInInsertor: false },
  WAIT_UNTIL_DISPLAYED: { displayInExtractor: true, displayInInsertor: false },
  WAIT_UNTIL_NOT_DISPLAYED: { displayInExtractor: true, displayInInsertor: false },
  WAIT_UNTIL_ENABLED: { displayInExtractor: true, displayInInsertor: false },
  WAIT_UNTIL_NOT_ENABLED: { displayInExtractor: true, displayInInsertor: false },
  CLICK_IF_EXISTS: { displayInExtractor: true, displayInInsertor: false },
  CLICK_IF_DISPLAYED: { displayInExtractor: true, displayInInsertor: false },
  CLICK_IF_ENABLED: { displayInExtractor: true, displayInInsertor: false },
  IF_EXISTS: { displayInExtractor: true, displayInInsertor: false },
  IF_NOT_EXISTS: { displayInExtractor: true, displayInInsertor: false },
  IF_DISPLAYED: { displayInExtractor: true, displayInInsertor: false },
  IF_NOT_DISPLAYED: { displayInExtractor: true, displayInInsertor: false },
  IF_ENABLED: { displayInExtractor: true, displayInInsertor: false },
  IF_NOT_ENABLED: { displayInExtractor: true, displayInInsertor: false },
  SCRIPT: { displayInExtractor: true, displayInInsertor: false },
  END: { displayInExtractor: true, displayInInsertor: true },
  SCROLL_UP: { displayInExtractor: true, displayInInsertor: true },
  SCROLL_DOWN: { displayInExtractor: true, displayInInsertor: true },
  MOUSE_CLICK: { displayInExtractor: true, displayInInsertor: true },
  MOUSE_MOVE: { displayInExtractor: true, displayInInsertor: false },
  IF_CONDITION: { displayInExtractor: true, displayInInsertor: false },
  ASK_F_ENGINE_USER: { displayInExtractor: false, displayInInsertor: true },
  ASK_F_ENGINE_URL: { displayInExtractor: false, displayInInsertor: true },
  ASK_F_ENGINE_PASSWORD: { displayInExtractor: false, displayInInsertor: true },
  ASK_F_ENGINE_INVESTOR: { displayInExtractor: false, displayInInsertor: true },
  TYPE_F_ENGINE_FIELD: { displayInExtractor: false, displayInInsertor: true },
  ASK_ID: { displayInExtractor: true, displayInInsertor: false },
  ASK_PASSWORD: { displayInExtractor: true, displayInInsertor: true },
  ASK_CODE: { displayInExtractor: true, displayInInsertor: true },
  ASK_QUESTION: { displayInExtractor: true, displayInInsertor: false },
  ASK_CAPTCHA: { displayInExtractor: true, displayInInsertor: false },
  SCROLL_TOP: { displayInExtractor: true, displayInInsertor: true },
  SCROLL_BOTTOM: { displayInExtractor: true, displayInInsertor: true },
  ASK_RECAPTCHA: { displayInExtractor: true, displayInInsertor: false },
  GO_BACK: { displayInExtractor: true, displayInInsertor: false },
  GO_FORWARD: { displayInExtractor: true, displayInInsertor: false },
};

import HttpService from "./HttpService";

const SchedulerService = {
  getDataSetLabelColumn: () => HttpService.getDataSetLabelColumn(),

  createDataset: (dataset) => HttpService.createDataset(dataset),

  dataSetField: (setFields) => HttpService.dataSetField(setFields),

  SchedulerSave: (schedulerDTO) => HttpService.SchedulerSave(schedulerDTO),

  labeltablecolumnlist: (LabellistData) =>
    HttpService.labeltablecolumnlist(LabellistData),

  dataSetSave: (step4Data, datasetId) =>
    HttpService.dataSetSave(step4Data, datasetId),

  cancel: () => HttpService.cancel(),

  schedulerDashboard: () => HttpService.schedulerDashboard(),

  getServerTimezone: () => HttpService.getServerTimezone(),

  verifyDatasetHasData: (id) => HttpService.verifyDatasetHasData(id),

  schedulerDelete: (id) => HttpService.schedulerDelete(id),

  schedulerRename: (schedulerDto) => HttpService.schedulerRename(schedulerDto),

  schedulerEdit: (schedulerId) => HttpService.schedulerEdit(schedulerId),

  // eslint-disable-next-line max-len
  RemovedatasetField: (datasetId, datasetFieldId, datasetName) =>
    HttpService.RemovedatasetField(datasetId, datasetFieldId, datasetName),
};

export default SchedulerService;

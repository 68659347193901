import React from "react";
import {
  Container,
  Grid,
  Paper,
  makeStyles,
  Card,
  Button,
  CardHeader,
  Divider,
  Checkbox,
  Box,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: "2rem",
    maxWidth: "1900px",
  },
  leftGrid: {
    padding: "5rem",
  },
  rightGrid: {
    padding: "5rem",
  },
  buttonsGroup: {
    float: "right",
  },
  button: {
    marginLeft: "2rem",
  },
  List: {
    height: 500,
    width: "100%",
    overflow: "auto",
  },
  Table: {
    width: "100%",
    overflow: "auto",
    padding: "50px 20px!important",
  },
  Column: {
    border: `1px solid ${theme.palette.grey.light}`,
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 5px",
  },
  ColumnBlue: {
    border: `1px solid ${theme.palette.grey.light}`,
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 5px",
    overflow: "hidden",
    backgroundColor: theme.palette.secondary.main,
    fontWeight: "bolder",
  },
  Row: {
    height: "50px",
    padding: "0px !important",
  },
  CardHeader: {},
}));

function DatasetStep3(props) {
  const classes = useStyle();
  const {
    Left,
    Right,
    checked,
    leftChecked,
    rightChecked,
    handleToggle,
    numberOfChecked,
    handleToggleAll,
    handleCheckedRight,
    handleCheckedLeft,
  } = props;

  const CustomTransferList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.CardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "All items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <Box style={{ maxHeight: '70vh', overflow: 'auto' }}>
          <Grid container item sm={12} className={classes.Table} spacing={2}>
            <Grid container item className={classes.Row} sm={12}>
              <Grid item className={classes.ColumnBlue} sm={1} spacing={1} />
              <Grid item className={classes.ColumnBlue} sm={3} justifyContent="center">
                Extractor
              </Grid>
              <Grid item className={classes.ColumnBlue} sm={3} justifyContent="center">
                Label
              </Grid>
              <Grid item className={classes.ColumnBlue} sm={3} justifyContent="center">
                Table
              </Grid>
              <Grid item className={classes.ColumnBlue} sm={2} justifyContent="center">
                Column
              </Grid>
            </Grid>
            {items
              .sort((a, b) =>
                  a.extractorLabelTableColumnDto.extractorName.localeCompare(b.extractorLabelTableColumnDto.extractorName))
              .map((data, index) => {
              const labelId = `transfer-list-all-item-${data}-label`;
              return (
                <Grid
                  container
                  item
                  className={classes.Row}
                  sm={12}
                  key={`list-${index}`}
                  onClick={handleToggle(data)}
                >
                  <Grid
                    item
                    className={classes.Column}
                    style={{ display: "flex", justifyContent: "center" }}
                    sm={1}
                  >
                    <Checkbox
                      checked={checked.indexOf(data) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </Grid>
                  <Grid item className={classes.Column} sm={3}>
                    {`${data.extractorLabelTableColumnDto.extractorName}`}
                  </Grid>
                  <Grid item className={classes.Column} sm={3}>
                    {data.extractorLabelTableColumnDto.actionLabel !== null
                      ? data.extractorLabelTableColumnDto.actionLabel
                      : ""}
                  </Grid>
                  <Grid item className={classes.Column} sm={3}>
                    {data.extractorLabelTableColumnDto.tableName !== null
                      ? data.extractorLabelTableColumnDto.tableName
                      : ""}
                  </Grid>
                  <Grid item className={classes.Column} sm={2}>
                    {data.extractorLabelTableColumnDto.tableColumnName !== null
                      ? data.extractorLabelTableColumnDto.tableColumnName
                      : ""}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
      </Box>
    </Card>
  );

  return (
    <Container className={classes.root}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        direction="row"
      >
        <Grid item sm={5} className={classes.leftGrid}>
          <Paper elevation={3}>
            {CustomTransferList("Label and Table-Columns Available", Left)}
          </Paper>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              <FormattedMessage id="dataset.add.selected" />{" "}
              <ChevronRightIcon />
            </Button>
            <Divider />
            <Divider />
            <Divider />
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              <ChevronLeftIcon />{" "}
              <FormattedMessage id="dataset.remove.selected" />
            </Button>
          </Grid>
        </Grid>
        <Grid item sm={5} className={classes.rightGrid}>
          <Paper elevation={3}>
            {CustomTransferList("Label and Table-Columns Selected", Right)}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

DatasetStep3.propTypes = {
  Left: PropTypes.arrayOf(PropTypes.object),
  Right: PropTypes.arrayOf(PropTypes.object),
  checked: PropTypes.arrayOf(PropTypes.object),
  leftChecked: PropTypes.arrayOf(PropTypes.object),
  rightChecked: PropTypes.arrayOf(PropTypes.object),
  handleToggle: PropTypes.func,
  numberOfChecked: PropTypes.func,
  handleToggleAll: PropTypes.func,
  handleCheckedRight: PropTypes.func,
  handleCheckedLeft: PropTypes.func,
};

DatasetStep3.defaultProps = {
  Left: [],
  Right: [],
  checked: [],
  leftChecked: [],
  rightChecked: [],
  handleToggle: () => {},
  numberOfChecked: () => {},
  handleToggleAll: () => {},
  handleCheckedRight: () => {},
  handleCheckedLeft: () => {},
};

export default DatasetStep3;

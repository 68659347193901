import { CardContent, makeStyles, Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import ExtractorActionType from "../../../../models/enums/ExtractorActionType";

const useStyles = makeStyles(() => ({
  textIcon: {
    display: "flex",
    alignItems: "center",
  },
  text: {
    marginLeft: 3,
    paddingTop: 2,
    whiteSpace: "pre",
  },
}));

function ActionUnfinished(props) {
  const classes = useStyles();
  const { actionType } = props;

  const getMessage = () => {
    let msg;

    if (
      actionType === ExtractorActionType.GET_TABLE ||
      actionType === ExtractorActionType.WAIT ||
      actionType === ExtractorActionType.RAISE_ERROR ||
      actionType === ExtractorActionType.SCRIPT
    ) {
      msg = "action.unfinished.content.default";
    } else if (
      actionType === ExtractorActionType.MOUSE_CLICK ||
      actionType === ExtractorActionType.MOUSE_MOVE
    ) {
      msg = "action.unfinished.content.mouse";
    } else if (actionType === ExtractorActionType.ASK_F_ENGINE_INVESTOR) {
      msg = "action.unfinished.content.investor";
    } else {
      msg = "action.unfinished.content";
    }

    return msg;
  };

  return (
    <CardContent>
      <div className={classes.textIcon}>
        <InfoOutlinedIcon />
        <Typography noWrap className={classes.text}>
          <FormattedMessage display="block" id={getMessage()} />
        </Typography>
      </div>
    </CardContent>
  );
}

ActionUnfinished.propTypes = {
  actionType: PropTypes.string,
};

ActionUnfinished.defaultProps = {
  actionType: "",
};

export default ActionUnfinished;

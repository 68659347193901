class ExtractorActionOptions {
  constructor() {
    this.text = undefined;
    this.number = undefined;
    this.numberFormat = undefined;
    this.datetimeFormat = undefined;
    this.point = undefined;
    this.select = {};
    this.select.option = undefined;
  }
}

export default ExtractorActionOptions;

import React, { useEffect, useState } from "react";
import {
  Typography,
  FormControl,
  Divider,
  Grid,
  TextField,
  Card,
  CardContent,
  makeStyles,
  InputLabel,
  Select,
  Container,
  FormHelperText,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import SchedulerService from "../../services/SchedulerService";

const useStyles = makeStyles(() => ({
  leftMenu: {
    maxHeight: "90vh",
  },
  table: {
    maxHeight: "60vh",
    overflow: "auto",
    overflowX: "hidden",
  },
}));

function SchedulerStep2(props) {
  const { Dataset, setQueryData } = props;

  const [timezoneinfo, setTimezoneinfo] = useState("");

  let defaultMinute = 1;
  let defaultHour = 1;
  if (Dataset?.data?.data?.time) {
    const time = Dataset.data.data.time.split(":");
    defaultHour = Number(time[0]);
    defaultMinute = Number(time[1]);
  }

  let schedulerTypeSelect = "INTERVAL";
  if (Dataset?.data?.data?.scheduleType) {
    schedulerTypeSelect = Dataset?.data?.data?.scheduleType;
  }
  if (schedulerTypeSelect === "MINUTE_INTERVAL") {
    schedulerTypeSelect = "INTERVAL";
  }

  const defaultDayMonth = Dataset?.data?.data?.dayOfMonth ?? null;
  const defaultDayWeek = Dataset?.data?.data?.daysOfWeek ?? null;
  const defaultIntervalTime = Dataset?.data?.data?.minuteInterval ?? 1;

  useEffect(() => {
    SchedulerService.getServerTimezone().then(
        (response) => {
          const { data } = response;
          setTimezoneinfo(data);
        },
    );

    updateComboSchedulerFreq(schedulerTypeSelect);
    setQueryData({
      hour: defaultHour,
      frequency: schedulerTypeSelect,
      minute: defaultMinute,
      daymonth: defaultDayMonth,
      dayweek: defaultDayWeek,
      intervaltime: defaultIntervalTime,
    });
  }, [
    schedulerTypeSelect,
    defaultHour,
    defaultMinute,
    defaultDayMonth,
    defaultDayWeek,
    defaultIntervalTime,
  ]);

  const classes = useStyles();
  const [daymonth, setDaymonth] = useState(defaultDayMonth);
  const [dayweek, setDayweek] = useState(defaultDayWeek);
  const [hour, setHour] = useState(defaultHour);
  const [minute, setMinute] = useState(defaultMinute);
  const [intervaltime, setIntervaltime] = useState(defaultIntervalTime);
  const [frequency, setFrequency] = useState(schedulerTypeSelect);
  const [isDayWeekVisible, setIsDayWeekVisible] = useState(false);
  const [isDayMonthVisible, setIsDayMonthVisible] = useState(false);
  const [isHourVisible, setIsHourVisible] = useState(false);
  const [isMinuteVisible, setIsMinuteVisible] = useState(false);
  const [isIntervalVisible, setIsIntervalVisible] = useState(false);
  const [isRequiredFrequency, setIsRequiredFrequency] = useState(false);

  const handleDayMonth = (e) => {
    if (!e.target.value) {
      setDaymonth("");
      setQueryData({ daymonth: e.target.value });
      return;
    }
    let value = parseInt(e.target.value, 10);
    if (Number.isNaN(value)) {
      setDaymonth("");

      return;
    }

    if (value > 31) {
      value = 31;
    } else if (value < 1) {
      value = 1;
    }
    setDaymonth(value);
    setQueryData({ daymonth: value });
  };
  const handleHour = (e) => {
    if (!e.target.value) {
      setHour("");
      setQueryData({ hour: e.target.value });
      return;
    }
    let value = parseInt(e.target.value, 10);
    if (Number.isNaN(value)) {
      setHour("");

      return;
    }

    if (value > 23) {
      value = 23;
    } else if (value < 0) {
      value = 0;
    }
    setHour(value);
    setQueryData({ hour: value });
  };

  const handleMinute = (e) => {
    if (!e.target.value) {
      setMinute("");
      setQueryData({ minute: e.target.value });
      return;
    }
    let value = parseInt(e.target.value, 10);
    if (Number.isNaN(value)) {
      setMinute("");

      return;
    }

    if (value > 59) {
      value = 59;
    } else if (value < 0) {
      value = 0;
    }
    setMinute(value);
    setQueryData({ minute: value });
  };

  const preventForbiddenNumberChar = (e) => {
    if (e.code === "Minus" || e.code === "Equal" || e.code === "KeyE") {
      e.preventDefault();
    }
  };

  const handleIntervalTime = (e) => {
    if (!e.target.value) {
      setQueryData({ intervaltime: e.target.value });
      setIntervaltime("");
      return;
    }
    let intervalValue = e.target.value;
    const regex = /^[0-9\b]+$/;
    if (intervalValue === "" || regex.test(intervalValue)) {
      if (intervalValue > 1440) {
        intervalValue = 1440;
      } else if (intervalValue < 1) {
        intervalValue = "";
      }
    } else {
      intervalValue = "";
    }
    setIntervaltime(intervalValue);
    setQueryData({ intervaltime: intervalValue });
  };

  const updateComboSchedulerFreq = (value) => {
    setIsRequiredFrequency(false);
    setIsDayWeekVisible(false);
    setIsDayMonthVisible(false);
    setIsHourVisible(false);
    setIsMinuteVisible(false);
    setIsIntervalVisible(false);

    if (value === "INTERVAL") {
      setIsIntervalVisible(true);
    } else if (value === "DAILY") {
      setIsHourVisible(true);
      setIsMinuteVisible(true);
    } else if (value === "WEEKLY") {
      setIsDayWeekVisible(true);
      setIsHourVisible(true);
      setIsMinuteVisible(true);
    } else if (value === "MONTHLY") {
      setIsDayMonthVisible(true);
      setIsHourVisible(true);
      setIsMinuteVisible(true);
    }
    setFrequency(value);
  };


  const handleFrequency = (e) => {
    const { value } = e.target;

    setHour("");
    setMinute("");
    setDaymonth("");

    setQueryData({
      frequency: value,
      hour: "",
      dayOfMonth: "",
      minute: "",
      daymonth: "",
      dayweek: "",
    });


    updateComboSchedulerFreq(value);

    // setQueryData({ frequency: value });
  };

  const handleDayWeek = (e) => {
    const { value } = e.target;

    setDayweek(value);
    setQueryData({ dayweek: value });
  };

  return (
    <>
      <Container className={classes.root}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Card style={{ marginTop: "2rem" }}>
            <CardContent>
              <Typography varinat="h1" noWrap>
                <FormattedMessage id="scheduler.label.step2" />
              </Typography>

              <InputLabel
                id="data-type-label"
                style={{ marginTop: "1rem", marginBottom: "10px" }}
              >
                Frequency *
              </InputLabel>

              <FormControl style={{ width: "100%" }}>
                <Select
                  key="frequency"
                  native
                  label="Type"
                  id="frequency"
                  required
                  onChange={(e) => handleFrequency(e)}
                  value={frequency}
                  labelId="data-type-label"
                  error={isRequiredFrequency}
                >
                  <option value="">-</option>
                  <option value="INTERVAL">Interval</option>
                  <option value="DAILY">Daily</option>
                  <option value="WEEKLY">Weekly</option>
                  <option value="MONTHLY">Monthly</option>
                </Select>
                <FormHelperText
                  style={
                    isRequiredFrequency
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  Frequency is required
                </FormHelperText>
              </FormControl>
              {isDayWeekVisible ? (
                <>
                  <InputLabel
                    id="data-type-label"
                    style={{ marginTop: "1rem", marginBottom: "10px" }}
                  >
                    Day of week
                  </InputLabel>
                  <Select
                    key="dayweek"
                    native
                    label="Type"
                    id="dayweek"
                    required
                    onChange={(e) => handleDayWeek(e)}
                    value={dayweek}
                    labelId="data-type-label-week"
                  >
                    <option value="">-</option>
                    <option value="SUNDAY">Sunday</option>
                    <option value="MONDAY">Monday</option>
                    <option value="TUESDAY">Tuesday</option>
                    <option value="WEDNESDAY">Wednesday</option>
                    <option value="THURSDAY">Thursday</option>
                    <option value="FRIDAY">Friday</option>
                    <option value="SATURDAY">Saturday</option>
                  </Select>
                </>
              ) : (
                ""
              )}

              {isDayMonthVisible ? (
                <>
                  <FormattedMessage id="extractor.form.label.daymonth">
                    {(text) => (
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="daymonthModal"
                        name="daymonthModal"
                        value={daymonth}
                        inputProps={{ maxLength: 2, min: 1, max: 31 }}
                        label={text}
                        type="number"
                        onChange={handleDayMonth}
                        onKeyDown={preventForbiddenNumberChar}
                      />
                    )}
                  </FormattedMessage>
                </>
              ) : (
                ""
              )}

              {isHourVisible ? (
                <>
                  <FormattedMessage id="extractor.form.label.hour">
                    {(text) => (
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="hourModal"
                        name="hourModal"
                        value={hour}
                        inputProps={{ maxLength: 2, min: 0, max: 23 }}
                        label={text}
                        type="number"
                        onChange={handleHour}
                        onKeyDown={preventForbiddenNumberChar}
                      />
                    )}
                  </FormattedMessage>
                </>
              ) : (
                ""
              )}

              {isMinuteVisible ? (
                <>
                  <FormattedMessage id="extractor.form.label.minute">
                    {(text) => (
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="minuteModal"
                        name="minuteModal"
                        value={minute}
                        inputProps={{ maxLength: 2, min: 0, max: 59 }}
                        label={text}
                        type="number"
                        onChange={handleMinute}
                        onKeyDown={preventForbiddenNumberChar}
                      />
                    )}
                  </FormattedMessage>
                </>
              ) : (
                ""
              )}

              {isIntervalVisible ? (
                <>
                  <FormattedMessage id="extractor.form.label.intervaltime">
                    {(text) => (
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="intervaltimeModal"
                        name="intervaltimeModal"
                        value={intervaltime}
                        inputProps={{ maxLength: 4, min: 1, max: 1440 }}
                        label={text}
                        type="number"
                        onChange={handleIntervalTime}
                        onKeyDown={preventForbiddenNumberChar}
                      />
                    )}
                  </FormattedMessage>
                </>
              ) : (
                ""
              )}

              <Typography variant="caption" noWrap>
                Timezone:  { timezoneinfo }
              </Typography>

            </CardContent>
            <Divider />
          </Card>
        </Grid>
      </Container>
    </>
  );
}

SchedulerStep2.propTypes = {
  Dataset: PropTypes.shape(PropTypes.object.isRequired),
  setQueryData: PropTypes.func,
};

SchedulerStep2.defaultProps = {
  Dataset: { name: "" },
  setQueryData: () => {},
};

export default SchedulerStep2;

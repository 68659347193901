import { Box, Button } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import TextFormatIcon from "@material-ui/icons/TextFormat";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import GenericModal from "../../GenericModal";

function ActionTable(props) {
  const { action } = props;
  const [showModalColumns, setModalColumns] = useState(false);
  const intl = useIntl();

  const getColumType = (Column) => {
    if (Column.type === "NUMBER") {
      return <FormatListNumberedIcon />;
    }
    if (Column.type === "DATE") {
      return <CalendarTodayIcon />;
    }
    if (Column.type === "FILE") {
      return <FileCopyIcon />;
    }
    
    return <TextFormatIcon />;
  };

  const componentSelectAction = (
    <>
      <List>
        {action.extractorActionGetTableDto.columns.map((column, index) => (
          <ListItem button key={index}>
            <ListItemIcon>{getColumType(column)}</ListItemIcon>
            <ListItemText>{column.label} </ListItemText>
          </ListItem>
        ))}
      </List>
    </>
  );

  const openColumnsModal = () => {
    setModalColumns(true);
  };

  function closeModalColumns() {
    setModalColumns(false);
  }

  return (
    <>
      <Box display="flex" justifyContent="flex-end" pr={2} pb={2}>
        <Button size="small" onClick={openColumnsModal}>
          <FormattedMessage id="getTable.allColumns" />
        </Button>
      </Box>
      {showModalColumns && (
        <GenericModal
          title={intl.formatMessage({ id: "action.table.see.columns" })}
          component={componentSelectAction}
          handleClose={closeModalColumns}
          maxWidth="lg"
        />
      )}
    </>
  );
}

ActionTable.propTypes = {
  action: PropTypes.shape(PropTypes.object.isRequired),
};
ActionTable.defaultProps = {
  action: { extractorActionGetTableDto: { columns: [] } },
};

export default ActionTable;

import React, { useState } from "react";
import {
  Button,
  Typography,
  FormControl,
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Toolbar,
  Card,
  CardContent,
  makeStyles,
  withStyles,
  InputLabel,
  Select,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import DatasetActionType from "../../models/enums/DatasetActionType";
import DatasetValues from "../../models/enums/DatasetValues";

const useStyles = makeStyles((theme) => ({
  leftMenu: {
    maxHeight: "90vh",
  },
  table: {
    maxHeight: "60vh",
    overflow: "auto",
    overflowX: "hidden",
  },
  Table: {
    width: "100%",
    overflow: "auto",
    padding: "50px 20px!important",
  },
  Column: {
    border: `1px solid ${theme.palette.grey.light}`,
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 5px",
  },
  ColumnBlue: {
    border: `1px solid ${theme.palette.grey.light}`,
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 5px",
    overflow: "hidden",
    backgroundColor: theme.palette.secondary.main,
    fontWeight: "bolder",
  },
  Row: {
    height: "50px",
    padding: "0px !important",
  },
}));

function DatasetStep2(props) {
  const [datasetName, setDatasetName] = useState("");
  const [sequence, setSequence] = useState(1);
  const [datasetNameError, setDatasetNameError] = useState("");
  const [datasetType, setDatasetType] = useState(DatasetActionType.GET_TEXT);
  const [datachecked, setdatachecked] = useState(true);
  const {
    handlesubmit,
    rowstable,
    selectedCheckbox,
    handleMoveUpAction,
    handleDeleteAction,
    handleMoveDownAction,
    handleSelectionCheckboxChange,
    RowCheckChange,
    getSelection,
  } = props;
  const classes = useStyles();
  const intl = useIntl();

  const handlesetType = (e) => setDatasetType(e.target.value);
  const checkvalue = (e) => {
    setdatachecked(e.target.checked);
  };
  const handlesetName = (e) => {
    setDatasetName(e.target.value);
  };

  const validateDeleteAction = () => {
    handleDeleteAction();
    setSequence(sequence - 1);
  };

  const ValidateForm = () => {
    if (datasetName.length === 0) {
      setDatasetNameError(intl.formatMessage({ id: "dataset.name.error" }));
    } else if (rowstable.find((data) => data.name === datasetName)) {
      setDatasetNameError(
        intl.formatMessage({ id: "dataset.name.differ.error" })
      );
    } else {
      const Dataset = new DatasetValues(
        datasetName,
        datasetType,
        datachecked,
        sequence
      );
      handlesubmit(Dataset);
      setSequence(sequence + 1);
      setDatasetName("");
      setDatasetNameError("");
      setdatachecked(true);
    }
  };

  return (
    <>
      <Grid item sm={3} className={classes.leftMenu}>
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Card>
            <CardContent>
              <InputLabel>
                <Typography variant="h6" noWrap>
                  <FormattedMessage id="dataset.define" />
                </Typography>
              </InputLabel>
              <FormattedMessage id="dataset.fields.Name">
                {(text) => (
                  <TextField
                    margin="normal"
                    required
                    name="name"
                    fullWidth
                    label={text}
                    value={datasetName}
                    error={datasetNameError !== ""}
                    helperText={datasetNameError}
                    onChange={handlesetName}
                  />
                )}
              </FormattedMessage>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                marginTop={2}
              >
                <FormControl>
                  <InputLabel id="data-type-label">
                    <FormattedMessage id="dataset.fields.Type" />
                  </InputLabel>
                  <Select
                    native
                    label="Type"
                    id="data-type"
                    fullWidth
                    required
                    onChange={handlesetType}
                    value={datasetType}
                    labelId="data-type-label"
                  >
                    <option value={DatasetActionType.GET_TEXT}>
                      {intl.formatMessage({ id: "dataset.action.get.text" })}
                    </option>
                    <option value={DatasetActionType.GET_DATE}>
                      {intl.formatMessage({ id: "dataset.action.get.date" })}
                    </option>
                    <option value={DatasetActionType.GET_NUMBER}>
                      {intl.formatMessage({ id: "dataset.action.get.number" })}
                    </option>
                    <option value={DatasetActionType.GET_FILE}>
                      {intl.formatMessage({ id: "dataset.action.get.file" })}
                    </option>
                  </Select>
                </FormControl>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                marginTop={2}
              >
                <InputLabel>
                  <Typography noWrap>
                    <FormattedMessage id="dataset.key" />
                    <Checkbox
                      required
                      onChange={checkvalue}
                      checked={datachecked}
                    />
                  </Typography>
                </InputLabel>
                <Button
                  color="secondary"
                  type="button"
                  size="large"
                  variant="contained"
                  onClick={ValidateForm}
                >
                  <FormattedMessage id="dataset.add" />
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      <Grid item sm={9}>
        <Paper>
          <Toolbar size="small" variant="dense">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Box display="flex" ml="-10px">
                  <Typography color="secondary" variant="h6">
                    <FormattedMessage id="dataset.fields.Table.Name" />
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={2}>
                {rowstable.length !== 0 && (
                  <Box display="flex">
                    {selectedCheckbox !== 0 && (
                      <IconButton onClick={handleMoveUpAction}>
                        <Tooltip title="MoveUp" cursor="pointer">
                          <ArrowUpwardIcon color="secondary" />
                        </Tooltip>
                      </IconButton>
                    )}
                    {rowstable.length - 1 !== selectedCheckbox && (
                      <IconButton onClick={handleMoveDownAction}>
                        <Tooltip title="MoveDown" cursor="pointer">
                          <ArrowDownwardIcon color="secondary" />
                        </Tooltip>
                      </IconButton>
                    )}
                    <IconButton onClick={() => validateDeleteAction()}>
                      <Tooltip title="Delete" cursor="pointer">
                        <DeleteOutlineRoundedIcon color="secondary" />
                      </Tooltip>
                    </IconButton>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Toolbar>
          <Divider />
          <Grid container item sm={12} className={classes.Table} spacing={2}>
            <Grid container item className={classes.Row} sm={12} justifyContent="center">
                <Grid
                  item
                  className={classes.ColumnBlue}
                  sm={1}
                />
                <Grid item className={classes.ColumnBlue} sm={4} justifyContent="center">
                    <FormattedMessage id="dataset.fields.Name" />
                </Grid>
                <Grid item className={classes.ColumnBlue} sm={4} justifyContent="center">
                    <FormattedMessage id="dataset.fields.Type" />
                </Grid>
                <Grid item className={classes.ColumnBlue} sm={3} justifyContent="center">
                    <FormattedMessage id="dataset.fields.key" />
                </Grid>
            </Grid>
                {rowstable.map((row, index) => (
                  <Grid
                    hover
                    container
                    item 
                    className={classes.Row}
                    sm={12}
                    key={`list-${index}`}
                    onClick={(e) => {
                      getSelection(e, row);
                    }}
                  >
                    <Grid item className={classes.Column} sm={1} style={{ display: "flex", justifyContent: "center" }}>
                      <Checkbox
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<CheckCircleIcon />}
                        checked={selectedCheckbox === index}
                        onChange={() => handleSelectionCheckboxChange(index)}
                      />
                    </Grid>
                    <Grid item className={classes.Column} sm={4}>
                      {row.name}
                    </Grid>
                    <Grid item className={classes.Column} sm={4}>
                      {row.type.charAt(0).toUpperCase() +
                        row.type.toLowerCase().slice(1)}
                    </Grid>
                    <Grid item className={classes.Column} sm={3} style={{ display: "flex", justifyContent: "center" }}>
                        <Checkbox
                          checked={row.dataSetKey}
                          onChange={(e) => RowCheckChange(e, row)}
                        />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
        </Paper>
      </Grid>
    </>
  );
}

DatasetStep2.propTypes = {
  handlesubmit: PropTypes.func,
  rowstable: PropTypes.arrayOf(PropTypes.object),
  selectedCheckbox: PropTypes.number,
  handleMoveDownAction: PropTypes.func,
  handleDeleteAction: PropTypes.func,
  handleMoveUpAction: PropTypes.func,
  handleSelectionCheckboxChange: PropTypes.func,
  RowCheckChange: PropTypes.func,
  getSelection: PropTypes.func,
};

DatasetStep2.defaultProps = {
  handlesubmit: () => {},
  rowstable: [],
  selectedCheckbox: null,
  handleMoveDownAction: () => {},
  handleDeleteAction: () => {},
  handleMoveUpAction: () => {},
  handleSelectionCheckboxChange: () => {},
  RowCheckChange: () => {},
  getSelection: () => {},
};

export default DatasetStep2;

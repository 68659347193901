import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Divider,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: 10,
  },
}));

function GenericModal(props) {
  const classes = useStyles();
  const {
    title, component, click, handleClose, maxWidth, showCancel, showEnter, handleEnter,
  } = props;
  const [isMounted, setIsMounted] = useState(true);
  const [disabledButton, setDisabledButton] = useState(false);

  useEffect(() => () => {
    setIsMounted(false);
    setDisabledButton(false);
  }, []);

  const handleClick = () => {
    setDisabledButton(true);
    setTimeout(() => {
      if (isMounted) setDisabledButton(false);
    }, 500);
    click(null);
  };

  return (
    <Dialog
      onClose={handleClose}
      open
      maxWidth={maxWidth}
      fullWidth
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {component}
      </DialogContent>
      {click && <Divider className={classes.divider} />}
      <DialogActions>
        {click && (
          <>
            {showCancel && (
            <Button onClick={handleClose}>
              <FormattedMessage id="generic.modal.cancel" />
            </Button>
            )}
             {showEnter && (
            <Button onClick={handleEnter}>
              <FormattedMessage id="generic.modal.enter" />
            </Button>
            )}
            <Button onClick={handleClick} color="secondary" variant="contained" disabled={disabledButton}>
              <FormattedMessage id="generic.modal.confirm" />
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

GenericModal.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  component: PropTypes.shape(PropTypes.object.isRequired),
  click: PropTypes.func,
  handleClose: PropTypes.func,
  maxWidth: PropTypes.string,
  showCancel: PropTypes.bool,
  showEnter: PropTypes.bool,
  handleEnter: PropTypes.func,
};

GenericModal.defaultProps = {
  title: '',
  component: <></>,
  click: undefined,
  handleClose: () => {},
  handleEnter: () => {},
  maxWidth: 'sm',
  showCancel: true,
  showEnter: false,
};

export default GenericModal;

import {
  makeStyles,
  TextField,
  Grid,
  Select,
  Typography,
  Tooltip,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { Help } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import GenericModal from "./GenericModal";
import ExtractorService from "../../services/ExtractorService";

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: 10,
  },
}));

function GenericDateTime(props) {
  const classes = useStyles();
  const { click, selectorValue, label, testAvailable } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [showConfirmationModal, setShowConfirmationModal] = useState(true);
  const [selectInputTimezone, setSelectInputTimezone] = useState("Etc/GMT0");
  const [selectInputLabel, setSelectInputLabel] = useState("");
  const [selectDate, setSelectDate] = useState("MM/dd/yyyy");
  const [selectCustomMask, setSelectCustomMask] = useState("");
  const [maskResultValue, setMaskResultValue] = useState("");
  const [allTimeZone, setAllTimeZone] = useState([]);

  const intl = useIntl();

  const GET_DATE_CUSTOM_VALUE = "custom";

  const onChangeInputTimezone = (event) => {
    setSelectInputTimezone(event.target.value);
  };

  const onChangeSelectInputLabel = (event) => {
    setSelectInputLabel(event.target.value);
  };

  const onChangeSelectDate = (event) => {
    setSelectDate(event.target.value);
  };
  const onChangeCustomMask = (event) => {
    setSelectCustomMask(event.target.value);
  };

  const getPreviewDateTimeMask = (datetimeMaskDto) =>
    ExtractorService.previewDateTimeMask(datetimeMaskDto);

  const getAllTimeZones = () => ExtractorService.getAllTimeZones();

  useEffect(() => {
    getAllTimeZones().then(
      (response) => {
        setAllTimeZone(response.data.sort());
      },
      () => {
        enqueueSnackbar("Error to get Timezones", { variant: "error" });
        setMaskResultValue("");
      }
    );
  }, []);

  const handleClickTestMask = () => {
    const extractorActionNumberMaskDto = {
      gmt: selectInputTimezone,
      mask:
        selectDate !== GET_DATE_CUSTOM_VALUE ? selectDate : selectCustomMask,
      selectorValue,
    };
    getPreviewDateTimeMask(extractorActionNumberMaskDto).then(
      (response) => {
        setMaskResultValue(response.data);
      },
      () => {
        enqueueSnackbar("Error to apply mask", { variant: "error" });
        setMaskResultValue("");
      }
    );
  };

  const getConfirmationModalTitle = () => {
    const modalTitle = (
      <Grid container>
        <Grid item sm={11}>
          Confirm Get Date?
        </Grid>
        <Grid item sm={1}>
          <Tooltip
            title={
              <div>
                <p>YY - Year(70 71 ... 29 30)</p>
                <p>YYYY - Year(1970 1971 ... 2029 2030)</p>
                <p>MM - Month(01 02 ... 11 12)</p>
                <p>MMM - Month(Jan Feb ... Nov Dec)</p>
                <p>MMMM - Month(January February ... November December)</p>
                <p>Q - Quarter(1 2 3 4)</p>
                <p>D - Day(1 2 ... 30 31)</p>
                <p>DD - Day(01 02 ... 30 31)</p>
                <p>DDD - Day of Year(1 2 ... 364 365)</p>
                <p>H - Hours(0 1 ... 22 23)</p>
                <p>HH - Hours(00 01 ... 22 23)</p>
                <p>m - Minutes(0 1 ... 58 59)</p>
                <p>mm - Minutes(00 01 ... 58 59)</p>
                <p>s - Seconds(0 1 ... 58 59)</p>
                <p>ss - Seconds(00 01 ... 58 59)</p>
                <p>[test] - Escape word test</p>
              </div>
            }
          >
            <Help className={classes.topIcon} />
          </Tooltip>
        </Grid>
      </Grid>
    );
    return modalTitle;
  };
  const getConfirmationModalContent = () => {
    const modalContent = (
      <Grid container spacing={1}>
        {label && (
          <Grid item sm={12}>
            <TextField
              required
              label={intl.formatMessage({ id: "extractor.action.modal.label" })}
              value={selectInputLabel}
              type="text"
              onChange={onChangeSelectInputLabel}
              fullWidth
            />
          </Grid>
        )}
        <Grid item sm={12}>
          <Select
            native
            label={intl.formatMessage({
              id: "extractor.action.modal.timezone",
            })}
            onChange={onChangeInputTimezone}
            value={selectInputTimezone}
            id="select-timezone"
            required
            fullWidth
          >
            {allTimeZone.map((timezone) => (
              <option value={timezone}>
                {timezone.replace("/GMT", "/UTC").replace("_", " ")}
              </option>
            ))}
          </Select>
        </Grid>
        <Grid item sm={12}>
          <Select
            native
            label="Type"
            onChange={onChangeSelectDate}
            value={selectDate}
            id="select-date"
            required
            fullWidth
          >
            <option value="MM/dd/yyyy">MM/dd/yyyy</option>
            <option value="dd/MM/yyyy">dd/MM/yyyy</option>
            <option value="hh:mm:ss">hh:mm:ss</option>
            <option value="MM/dd/yyyy hh:mm:ss">MM/dd/yyyy hh:mm:ss</option>
            <option value={GET_DATE_CUSTOM_VALUE}>Other</option>
          </Select>
        </Grid>
        <Grid item sm={12}>
          {selectDate === GET_DATE_CUSTOM_VALUE && (
            <TextField
              required
              label="Custom mask"
              value={selectCustomMask}
              onChange={onChangeCustomMask}
              fullWidth
            />
          )}
        </Grid>
        {testAvailable && (
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.grid}
          >
            <Grid item sm={3}>
              <Button
                type="button"
                size="large"
                variant="contained"
                color="secondary"
                onClick={handleClickTestMask}
              >
                <Typography>
                  <FormattedMessage id="action.mask.submit" />
                </Typography>
              </Button>
            </Grid>
            <Grid item sm={9}>
              <Typography>
                <FormattedMessage id="action.mask.result" />
                {maskResultValue.replace("T", " ") || "-"}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    );

    return modalContent;
  };

  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const clickInGenericModal = () => {
    // click(selectDate !== 'other' ? selectDate : selectCustomMask, selectInputTimezone, selectInputLabel);
    click(
      selectDate !== GET_DATE_CUSTOM_VALUE ? selectDate : selectCustomMask,
      selectInputTimezone,
      selectInputLabel
    );
  };

  return (
    <div>
      {showConfirmationModal && (
        <GenericModal
          title={getConfirmationModalTitle()}
          click={clickInGenericModal}
          component={getConfirmationModalContent()}
          handleClose={handleConfirmationModalClose}
        />
      )}
    </div>
  );
}

GenericDateTime.propTypes = {
  click: PropTypes.func,
  selectorValue: PropTypes.string,
  label: PropTypes.bool,
  testAvailable: PropTypes.bool,
};

GenericDateTime.defaultProps = {
  click: undefined,
  selectorValue: "",
  label: true,
  testAvailable: true,
};

export default GenericDateTime;

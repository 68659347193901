import { CardContent, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

function ActionError(props) {
  const { action } = props;

  return (
    <CardContent>
      <Typography>
        {action.errorMessage}
      </Typography>
    </CardContent>
  );
}

ActionError.propTypes = {
  // eslint-disable-next-line react/require-default-props
  action: PropTypes.shape(PropTypes.object.isRequired),
};

export default ActionError;

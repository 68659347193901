import {
  Button,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSnackbar } from "notistack";
import ExtractorService from "../../services/ExtractorService";
import ExtractorActionType from "../../models/enums/ExtractorActionType";
import ImageMapper from "./extractor/normalized/ImageMapper";
import "./GenericModalAsk.css";

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: 10,
  },
}));

function GenericModalAsk(props) {
  const classes = useStyles();
  const { askAction, handleClose } = props;
  const [isMounted, setIsMounted] = useState(true);
  const [disabledButton, setDisabledButton] = useState(false);
  const [selectInputLabelAsk, setSelectInputLabelAsk] = useState("");
  const [selectCheckBoxAsk, setSelectCheckBoxAsk] = useState(false);
  const [title, setTile] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  useEffect(() => {
    if (askAction.type === ExtractorActionType.ASK_F_ENGINE_USER) {
      setTile(intl.formatMessage({ id: "action.type.user.engine" }));
    } else if (askAction.type === ExtractorActionType.ASK_F_ENGINE_PASSWORD) {
      setTile(intl.formatMessage({ id: "action.type.password.engine" }));
    } else if (askAction.type === ExtractorActionType.ASK_F_ENGINE_URL) {
      setTile(intl.formatMessage({ id: "action.type.url.engine" }));
    } else if (askAction.type === ExtractorActionType.ASK_F_ENGINE_INVESTOR) {
      setTile(intl.formatMessage({ id: "action.type.investor.engine" }));
    } else if (askAction.type === ExtractorActionType.ASK_CODE ||
               askAction.type === ExtractorActionType.ASK_PASSWORD) {
      setTile(askAction.label);
    }
  }, [askAction]);

  const onChangeInputLabel = (event) => {
    setSelectInputLabelAsk(event.target.value);
  };

  const onChangeCheckbox = (event) => {
    setSelectCheckBoxAsk(event.target.checked);
  };

  const cancel = () => {
    askAction.options.text = "CANCELED";
    askAction.saveOptionsFlag = false;
    ExtractorService.updatePendingAskAction(askAction).then((res) => {
      handleClose();
    });
    setSelectInputLabelAsk("");
    setSelectCheckBoxAsk(false);
  };

  const click = () => {
    askAction.options.text = selectInputLabelAsk;
    askAction.saveOptionsFlag = selectCheckBoxAsk;
    if (askAction.type === ExtractorActionType.ASK_F_ENGINE_INVESTOR) {
      const text = document.getElementById("investor-select-validate")?.value;
      askAction.options.text = text;
    }

    ExtractorService.updatePendingAskAction(askAction).then(
      (res) => {
        handleClose();
        if (!res.data.id && res.data.saveOptionsFlag) {
          enqueueSnackbar(
            "Please, save the extractor before save for the next time!",
            { variant: "error" }
          );
        }
      },
      () => {
        handleClose();
        enqueueSnackbar(
          intl.formatMessage({ id: "extractor.list.error.execute" }),
          { variant: "error" }
        );
      }
    );
    setSelectInputLabelAsk("");
    setSelectCheckBoxAsk(false);
  };

  const handleClickNormalized = (event) => {
    const element = event.target;
    askAction.options.askMultiStepDto.askDataContentSelector =
      element.getAttribute("selector");
    ExtractorService.updatePendingAskAction(askAction).then(
      () => {
        ExtractorService.checkPendingAsk().then(
          (res) => {
            if (res.data !== "") {
              askAction.options.askMultiStepDto.askDataScreenshot =
                res.data.options.askMultiStepDto.askDataScreenshot;
              askAction.normalizedElements = res.data.normalizedElements;
            }
          },
          () => {
            enqueueSnackbar(
              intl.formatMessage({ id: "extractor.list.error.execute" }),
              { variant: "error" }
            );
          }
        );
      },
      () => {
        enqueueSnackbar(
          intl.formatMessage({ id: "extractor.list.error.execute" }),
          { variant: "error" }
        );
      }
    );
  };

  const component = () => {
    const modalContent = (
      <form noValidate autoComplete="off">
        <Grid container spacing={2}>
          {(askAction.type === ExtractorActionType.ASK_QUESTION ||
            askAction.type === ExtractorActionType.ASK_CAPTCHA) && (
            <Grid item sm={12}>
              <CardMedia
                className={classes.media}
                component="img"
                image={
                  askAction.elementScreenshot
                    ? `data:image/png;base64, ${askAction.options.askMultiStepDto.askDataScreenshot}`
                    : ""
                }
              />
            </Grid>
          )}
          {(askAction.type === ExtractorActionType.ASK_F_ENGINE_USER ||
            askAction.type === ExtractorActionType.ASK_F_ENGINE_URL ||
            askAction.type === ExtractorActionType.ASK_F_ENGINE_PASSWORD) && (
            <Grid item sm={6}>
              <TextField
                fullWidth
                type={
                  askAction.type === ExtractorActionType.ASK_F_ENGINE_PASSWORD
                    ? "password"
                    : "text"
                }
                label={intl.formatMessage({ id: "dashboard.modal.ask.value" })}
                value={selectInputLabelAsk}
                onChange={onChangeInputLabel}
              />
            </Grid>
          )}
          {(askAction.type === ExtractorActionType.ASK_ID ||
            askAction.type === ExtractorActionType.ASK_PASSWORD ||
            askAction.type === ExtractorActionType.ASK_QUESTION) && (
            <Grid item sm={6}>
              <TextField
                fullWidth
                type={
                  askAction.type === ExtractorActionType.ASK_PASSWORD
                    ? "password"
                    : "text"
                }
                label={intl.formatMessage({ id: "dashboard.modal.ask.value" })}
                value={selectInputLabelAsk}
                onChange={onChangeInputLabel}
              />
            </Grid>
          )}
          {(askAction.type === ExtractorActionType.ASK_CODE ||
            askAction.type === ExtractorActionType.ASK_CAPTCHA) && (
            <Grid item sm={12}>
              <TextField
                fullWidth
                label={intl.formatMessage({ id: "dashboard.modal.ask.value" })}
                value={selectInputLabelAsk}
                onChange={onChangeInputLabel}
              />
            </Grid>
          )}
          {(askAction.type === ExtractorActionType.ASK_ID ||
            askAction.type === ExtractorActionType.ASK_PASSWORD ||
            askAction.type === ExtractorActionType.ASK_QUESTION ||
            askAction.type === ExtractorActionType.ASK_F_ENGINE_USER ||
            askAction.type === ExtractorActionType.ASK_F_ENGINE_URL ||
            askAction.type === ExtractorActionType.ASK_F_ENGINE_PASSWORD) && (
            <Grid item sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectCheckBoxAsk}
                    onChange={onChangeCheckbox}
                  />
                }
                label={intl.formatMessage({ id: "dashboard.modal.ask.safe" })}
              />
            </Grid>
          )}
          {askAction.type === ExtractorActionType.ASK_RECAPTCHA && (
            <ImageMapper
              img={askAction.options.askMultiStepDto.askDataScreenshot}
              areas={askAction.normalizedElements}
              onClick={handleClickNormalized}
            />
          )}
          {askAction.type === ExtractorActionType.ASK_F_ENGINE_INVESTOR && (
            <Select
              native
              labelId="investor-select-label-validate"
              label="Type"
              //onChange={onChangeSelectType}
              // value={investors[0]?.portfolioid + " - " + investors[0]?.name}
              id="investor-select-validate"
              // class="investor-select-validate"
            >
              {Object.keys(askAction.managementDTO?.investors)
                .sort()
                .map((key) => (
                  <option
                    key={
                      askAction.managementDTO?.investors[key].confirmationNumber
                    }
                    value={
                      askAction.managementDTO?.investors[key].confirmationNumber
                    }
                  >
                    {askAction.managementDTO?.investors[key]?.portfolioid +
                      " - " +
                      askAction.managementDTO?.investors[key]?.name}
                  </option>
                ))}
            </Select>
          )}
        </Grid>
      </form>
    );
    return modalContent;
  };

  useEffect(
    () => () => {
      setIsMounted(false);
      setDisabledButton(false);
    },
    []
  );

  const handleClick = () => {
    setDisabledButton(true);
    setTimeout(() => {
      if (isMounted) setDisabledButton(false);
    }, 500);
    click();
  };

  return (
    <div>
      <Dialog onClose={handleClose} open fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{component()}</DialogContent>
        {click && <Divider className={classes.divider} />}
        <DialogActions>
          {click && (
            <>
              <Button onClick={cancel}>
                <FormattedMessage id="generic.modal.cancel" />
              </Button>
              <Button
                onClick={handleClick}
                color="secondary"
                variant="contained"
                disabled={disabledButton}
              >
                <FormattedMessage id="generic.modal.confirm" />
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

GenericModalAsk.propTypes = {
  askAction: PropTypes.shape(PropTypes.object.isRequired),
  handleClose: PropTypes.func,
};

GenericModalAsk.defaultProps = {
  askAction: {},
  handleClose: () => {},
};

export default GenericModalAsk;

import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthenticationService from '../services/AuthenticationService';

function NotFound() {
  return (
    <>
      {AuthenticationService.isAccessTokenValid()
        ? <Redirect to="/" />
        : <Redirect to="/login" />}
    </>
  );
}

export default NotFound;

import ExtractorActionStatus from "./enums/ExtractorActionStatus";
import ExtractorActionValidation from "./enums/ExtractorActionValidation";

class ExtractorAction {
  constructor(sequence, type, credentials, option) {
    this.id = undefined;
    this.type = type;
    this.sequence = sequence;
    this.selectorType = "CSS";
    this.selectorValue = undefined;
    this.elementScreenshot = undefined;
    this.pageScreenshot = undefined;
    this.normalizedHtml = undefined;
    this.status = ExtractorActionStatus.NONE;
    this.validation = ExtractorActionValidation.SUCCESS;
    this.errorMessage = undefined;
    this.label = undefined;
    this.options = {};
    this.options.askMultiStepDto = {};
    this.options.condition = {};
    this.options.select = {};
    this.options.select.options = [];
    this.options.select.option = option;
    this.extractorActionGetTableDto = undefined;
    this.credentials = credentials;
    this.managementDTO = {};
    this.managementDTO.investors = [];
    this.pageURL = undefined;
    this.timestamp = undefined;
  }
}

export default ExtractorAction;

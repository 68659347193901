class DatasetValues {
  constructor(name, type, dataSetKey, sequence) {
    this.name = name;
    this.type = type;
    this.dataSetKey = dataSetKey;
    this.sequence = sequence;
    this.id = 0;
    this.datasetFieldId = 0;
  }
}

export default DatasetValues;

import {
  Card,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  Grid,
  Paper,
  Button,
  Toolbar,
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Select,
  FormControl,
  InputLabel,
  TextField,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

const useStyle = makeStyles(() => ({
  root: {
    marginTop: "2rem",
  },
  leftGrid: {
    padding: "5rem",
  },
  rightGrid: {
    padding: "5rem",
  },
  buttonsGroup: {
    float: "right",
  },
  button: {
    marginLeft: "2rem",
  },
  List: {
    height: 500,
    width: "100%",
    overflow: "auto",
  },
  CardHeader: {},
}));

function QueryStep4(props) {
  const [error1, setError] = useState("");

  const classes = useStyle();
  const {
    Querystep4Rightcheck,
    handleTogglestep4,
    QueryLeftChecked,
    QueryLeftData,
    QueryRightData,
    handleQueryCheckRight,
    handleQueryCheckLeft,
    operator,
    Querystep4check,
    handlestep4Checkbox,
    handlesetoperator,
    handleoperatorvalue,
    QueryMoveUpAction,
    QueryMoveDownAction,
    Querystep4checkrow,
    error,
  } = props;
  useEffect(() => {}, []);
  useEffect(() => {
    setError(error);
    const resquery3 = QueryRightData.map((q) => {
      if (q.inputValue === "") {
        return false;
      }
      return true;
    });
    if (!resquery3.includes(false)) {
      setError("");
    } else if (error !== "") {
      setError("All fields are mandatory!");
    }
  }, [props]);
  const isOperatorVoid = (data) => {
    if (data !== undefined) {
      const idOperator = parseInt(data.split(",")[0], 10);
      return idOperator >= 22 && idOperator <= 28;
    }
    return false;
  };

  const CustomTransferList = (title, items) => (
    <Card>
      <Toolbar className={classes.CardHeader}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Box display="flex" ml="-10px">
              <Typography variant="h6" className={classes.title}>
                {title}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider />
      <List className={classes.List} dense component="div" role="list">
        {items.map((data, index) => {
          const keyvalue = `list-${index}`;
          return (
            <ListItem button key={keyvalue} onClick={handleTogglestep4(data)}>
              <ListItemIcon>
                <Checkbox
                  checked={Querystep4Rightcheck.indexOf(data) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={`${data.name}`} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  const CustomTransferRightList = (items) => (
    <>
      <Toolbar className={classes.CardHeader}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Box display="flex">
              <Typography variant="h6" className={classes.title}>
                Fields selected
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            {QueryRightData.length !== 0 && (
              <Box display="flex">
                {Querystep4check !== 0 && (
                  <IconButton onClick={QueryMoveUpAction}>
                    <Tooltip title="MoveUp" cursor="pointer">
                      <ArrowUpwardIcon color="secondary" />
                    </Tooltip>
                  </IconButton>
                )}
                {QueryRightData.length - 1 !== Querystep4check && (
                  <IconButton onClick={QueryMoveDownAction}>
                    <Tooltip title="MoveDown" cursor="pointer">
                      <ArrowDownwardIcon color="secondary" />
                    </Tooltip>
                  </IconButton>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Toolbar>
      <Divider />
      <List className={classes.List} dense component="div" role="list">
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {items.map((row, index) => {
          const keyvalueright = `${row.id}-${index}`;
          return (
            <Table key={keyvalueright}>
              <TableBody>
                <TableRow class="cell-dataset-step4">
                  <TableCell padding="checkbox">
                    <Checkbox
                      key={keyvalueright}
                      tabIndex={-1}
                      inputProps={{ "aria-labelledby": row }}
                      checked={Querystep4check === index}
                      onChange={(e) => {
                        handlestep4Checkbox(e, index, row);
                      }}
                      disableRipple
                    />
                  </TableCell>
                  <TableCell align="left" scope="row" padding="none">
                    <Typography variant="body2">{row.name}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <FormControl>
                      <InputLabel id="data-type-label1">Operator</InputLabel>
                      <Select
                        native
                        label="Value"
                        id={keyvalueright}
                        value={row.operator}
                        required
                        onChange={(e) => handlesetoperator(e, index)}
                        fullWidth
                        labelId="data-type-label1"
                      >
                        <option value="-">Select</option>
                        {operator[row.type] &&
                          operator[row.type].map((data, i) => (
                            <option
                              value={`${data.id},${data.valueType}`}
                              key={`${data.name}-${i}`}
                            >
                              {data.name}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  {!isOperatorVoid(row.operator) && (
                    <TableCell>
                      <FormControl style={{ width: 140 }}>
                        <TextField
                          key={keyvalueright}
                          type={`${
                            row.operatorId === "13" ||
                            row.operatorId === "14" ||
                            row.operatorId === "15" ||
                            row.operatorId === "16" ||
                            row.operatorId === "17" ||
                            row.operatorId === "18" ||
                            row.operatorId === "19"
                              ? "date"
                              : "text"
                          }`}
                          label={row.operatorType === "DATETIME" ? "" : ""}
                          id={keyvalueright}
                          value={row.inputValue}
                          required
                          disabled={row.operatorType === undefined}
                          onChange={(e) => handleoperatorvalue(e, index)}
                        />
                      </FormControl>
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          );
        })}
        <p style={{ textAlign: "center", color: "red" }}>{error1}</p>
      </List>
    </>
  );

  return (
    <Container className={classes.root}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        direction="row"
      >
        <Grid item sm={5} className={classes.leftGrid}>
          <Paper elevation={3}>
            {CustomTransferList("Fields Available", QueryLeftData)}
          </Paper>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.button}
              onClick={handleQueryCheckRight}
              disabled={QueryLeftChecked.length === 0}
              aria-label="move selected right"
            >
              <FormattedMessage id="dataset.add.selected" />{" "}
              <ChevronRightIcon />
            </Button>
            <Divider />
            <Divider />
            <Divider />
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.button}
              onClick={handleQueryCheckLeft}
              disabled={Object.keys(Querystep4checkrow).length === 0}
              aria-label="move selected left"
            >
              <ChevronLeftIcon />{" "}
              <FormattedMessage id="dataset.remove.selected" />
            </Button>
          </Grid>
        </Grid>
        <Grid item sm={5} className={classes.rightGrid}>
          <Paper elevation={3}>{CustomTransferRightList(QueryRightData)}</Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

QueryStep4.propTypes = {
  QueryLeftData: PropTypes.arrayOf(PropTypes.object),
  QueryRightData: PropTypes.arrayOf(PropTypes.object),
  Querystep4Rightcheck: PropTypes.arrayOf(PropTypes.object),
  handleTogglestep4: PropTypes.func,
  QueryLeftChecked: PropTypes.arrayOf(PropTypes.object),
  handleQueryCheckRight: PropTypes.func,
  handleQueryCheckLeft: PropTypes.func,
  operator: PropTypes.objectOf(PropTypes.array),
  Querystep4check: PropTypes.number,
  handlestep4Checkbox: PropTypes.func,
  handlesetoperator: PropTypes.func,
  handleoperatorvalue: PropTypes.func,
  QueryMoveDownAction: PropTypes.func,
  QueryMoveUpAction: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  Querystep4checkrow: PropTypes.object,
  error: PropTypes.string,
};

QueryStep4.defaultProps = {
  QueryLeftData: [],
  QueryRightData: [],
  Querystep4Rightcheck: [],
  handleTogglestep4: () => {},
  QueryLeftChecked: [],
  handleQueryCheckRight: () => {},
  handleQueryCheckLeft: () => {},
  operator: {},
  Querystep4check: undefined,
  handlestep4Checkbox: () => {},
  handlesetoperator: () => {},
  handleoperatorvalue: () => {},
  QueryMoveUpAction: () => {},
  QueryMoveDownAction: () => {},
  Querystep4checkrow: {},
  error: undefined,
};

export default QueryStep4;

class Extractor {
  constructor(name, description, viewerEmail, extractorType) {
    this.id = null;
    this.name = name;
    this.description = description;
    this.viewerEmail = viewerEmail;
    this.actions = [];
    this.status = undefined;
    this.extractorType = extractorType;
  }
}

export default Extractor;

import {
  Container,
  Grid,
  Paper,
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DatasetService from "../../services/DatasetService";
import ResponsiveDialog from "../shared/ResponsiveDialog";

const useStyles = makeStyles(() => ({
  root: {},
  ListItem: {
    minHeight: "70vh",
    overflow: "auto",
  },
}));

function QueryStep2(props) {
  const classes = useStyles();
  const [check, setCheck] = useState();
  const [message, setMessage] = useState();
  const [showMessage, setShowMessage] = useState();
  const { Dataset, setQueryData } = props;

  const verifyDatasetHasData = (id) => {
    DatasetService.verifyDatasetHasData(id).then(
      (response) => {
        if (response.data === false) {
          setMessage(
            "This dataset has not data extracted yet, do you want to continue?"
          );
          setShowMessage(true);
        }
      }
      // () => {

      // }
    );
  };

  const handleCancel = () => {
    setCheck("");
    setQueryData("");
    setShowMessage(false);
  };

  const handleToggel = (e, index, data) => {
    setCheck(index);
    verifyDatasetHasData(data.id);
    setQueryData(data);
  };

  const handleCheckbox = (index, data) => {
    setCheck(index);
    verifyDatasetHasData(data.id);
    setQueryData(data);
  };

  useEffect(() => {}, []);

  return (
    <>
      <Container className={classes.root}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Grid item sm={5} className={classes.leftGrid}>
            <Paper elevation={3}>
              <Card>
                <CardHeader title="Available Datasets" />
                <Divider />
                <List
                  dense
                  className={classes.ListItem}
                  component="div"
                  role="list"
                >
                  {Dataset.map((data, index) => (
                    <ListItem
                      button
                      key={index}
                      onClick={(e) => {
                        handleToggel(e, index, data);
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={check === index}
                          onChange={() => {
                            handleCheckbox(index, data);
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={data.name} />
                    </ListItem>
                  ))}
                </List>
              </Card>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <ResponsiveDialog
        message={message}
        isOpen={showMessage}
        setOpen={setShowMessage}
        title={"Warning"}
        showCancel={true}
        handleCancel={handleCancel}
      />
    </>
  );
}

QueryStep2.propTypes = {
  Dataset: PropTypes.arrayOf(PropTypes.object),
  setQueryData: PropTypes.func,
};

QueryStep2.defaultProps = {
  Dataset: [],
  setQueryData: () => {},
};

export default QueryStep2;

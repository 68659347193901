import {
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Help, Visibility, VisibilityOff } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import ExtractorActionType from "../../../../models/enums/ExtractorActionType";
import ExtractorActionOptions from "../../../../models/ExtractorActionOptions";
import ExtractorActionPoint from "../../../../models/ExtractorActionPoint";
import GenericDateTime from "../../GenericDateTime";
import GenericIf from "../../GenericIf";
import GenericModal from "../../GenericModal";
import GenericNumber from "../../GenericNumber";
import InputActionCode from "../actions/InputActionCode";
import ImageMapperGetTable from "./ImageMapperGetTable";
import ImageMapperMouse from "./ImageMapperMouse";
import ExtractorService from "../../../../services/ExtractorService";
import ExtractorActionAskType from "../../../../models/enums/ExtractorActionAskType";
import ExtractorActionStatus from "../../../../models/enums/ExtractorActionStatus";
import ExtractorAction from "../../../../models/ExtractorAction";
import AdaptableActionType from "../../../../models/enums/AdaptableActionType";
import ImageMapper from "./ImageMapper";

const useStyles = makeStyles(() => ({
  normalized: {
    transform: "scale(0.999)",
  },
  input: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  topIcon: {
    float: "right",
  },
  centerText: {
    textAlign: "center",
  },
  grid: {
    margin: "1%",
  },
}));

function Normalized(props) {
  const classes = useStyles();
  const {
    setCurrentAction,
    normalizedData,
    currentAction,
    onFinishAction,
    cancelAction,
    status,
    isAdaptable,
    valueInput,
    setImage,
    setValueInput,
    image,
    typeFieldValue,
  } = props;
  const actionType = currentAction.type ? currentAction.type : undefined;
  const intl = useIntl();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showGenericDateTime, setShowGenericDateTime] = useState(false);
  const [showGenericNumber, setShowGenericNumber] = useState(false);
  const [showGenericIf, setShowGenericIf] = useState(false);
  const [selectInputValue, setSelectInputValue] = useState("");
  const [selectInputLabel, setSelectInputLabel] = useState("");
  const [xpathValue, setXpathValue] = useState();
  const [selectorValue, setSelectorValue] = useState();
  const [selectorOptions, setSelectorOptions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [options, setOptions] = useState(undefined);
  const [adaptiveAction, setAdaptiveAction] = useState(undefined);
  const [selectSequence] = useState("");
  const [codeData, setCodeData] = useState();

  useEffect(() => {
    setXpathValue(undefined);
    setSelectInputValue("");
    setShowPassword(false);
    setSelectInputValue(valueInput);
    // console.log("valueInput");
    setSelectInputLabel("");

    // if (
    //   actionType === ExtractorActionType.TYPE_TEXT ||
    //   actionType === ExtractorActionType.TYPE_PASSWORD
    // ) {
    //   setSelectInputLabel(currentAction.label);
    //   setSelectInputValue(currentAction.options.text);
    // }
  }, [normalizedData, valueInput, image]);

  useEffect(() => {
    if (actionType === ExtractorActionType.SCRIPT) {
      setShowConfirmationModal(true);
      setCodeData(currentAction.options.script);
    } else setShowConfirmationModal(false);
  }, [actionType]);

  // const getPreviewNumberMask = (numberMaskDto) => ExtractorService.previewNumberMask(numberMaskDto);

  // const handleClickTestMask = (clickSelectAction) => {
  //   const extractorActionNumseberMaskDto = new ExtractorActionNumberMaskDto(
  //     selectInputValueBeforePositive,
  //     selectInputValueAfterPositive,
  //     selectInputValueBeforeNegative,
  //     selectInputValueAfterNegative,
  //     selectInputValueDecimal,
  //     selectInputValueGroup,
  //     selectorValue,
  //   );
  //   getPreviewNumberMask(extractorActionNumberMaskDto)
  //     .then((response) => {
  //       setMaskResultValue(response.data);
  //     }, () => {
  //       enqueueSnackbar('Error to apply mask', { variant: 'error' });
  //       setMaskResultValue('');
  //     });
  // };
  const formatKeytointl = (key) => {
    if (key) {
      const keyChanged = key.toLowerCase();
      return keyChanged.replaceAll("_", ".");
    }
    return "";
  };

  const handleEnter = () => {
    const text = selectInputValue + "/n";
    setSelectInputValue(text);
    setValueInput(text);
    clickSelectAction(text);
  };

  function extractSelectorFromHTML(outerHTML) {
    // Define a regular expression pattern to match the selector attribute
    let regex = /selector="([^"]+)"/;

    // Use the exec() method to search for the pattern in the outerHTML
    let match = regex.exec(outerHTML);

    // Check if a match is found and return the extracted selector if available
    if (match && match.length > 1) {
      return match[1];
    }
    return null;
  }

  const clickSelectAction = (valueInputText) => {
    const data = {
      selectorValue: undefined,
      xpathValue: undefined,
      label: undefined,
      options: new ExtractorActionOptions(),
    };

    setShowConfirmationModal(false);
    if (adaptiveAction !== undefined) {
      data.selectorValue = adaptiveAction.selectorValue;
      data.xpathValue = adaptiveAction.xpathValue;
      data.type = adaptiveAction.type;
      data.options = adaptiveAction.options;
      data.options.text =
        valueInputText == null ? selectInputValue : valueInputText;
      data.label = selectInputLabel;
      data.adaptiveAction = true;
    } else if (
      actionType === ExtractorActionType.CLICK ||
      actionType === ExtractorActionType.RAISE_ERROR ||
      actionType === ExtractorActionType.WAIT_UNTIL_EXISTS ||
      actionType === ExtractorActionType.WAIT_UNTIL_NOT_EXISTS ||
      actionType === ExtractorActionType.WAIT_UNTIL_DISPLAYED ||
      actionType === ExtractorActionType.WAIT_UNTIL_NOT_DISPLAYED ||
      actionType === ExtractorActionType.WAIT_UNTIL_ENABLED ||
      actionType === ExtractorActionType.WAIT_UNTIL_NOT_ENABLED ||
      actionType === ExtractorActionType.CLICK_IF_EXISTS ||
      actionType === ExtractorActionType.CLICK_IF_ENABLED ||
      actionType === ExtractorActionType.CLICK_IF_DISPLAYED ||
      actionType === ExtractorActionType.MOVE ||
      actionType === ExtractorActionType.CLICK_IF_DISPLAYED ||
      actionType === ExtractorActionType.ASK_RECAPTCHA
    ) {
      data.selectorValue = selectorValue;
      data.xpathValue = xpathValue;
    } else if (
      actionType === ExtractorActionType.GET_TEXT ||
      actionType === ExtractorActionType.GET_IMAGE ||
      actionType === ExtractorActionType.GET_FILE
    ) {
      data.selectorValue = selectorValue;
      data.xpathValue = xpathValue;
      data.label = selectInputLabel;
    } else if (
      actionType === ExtractorActionType.TYPE_TEXT ||
      actionType === ExtractorActionType.TYPE_PASSWORD
    ) {
      data.selectorValue = selectorValue;
      data.xpathValue = xpathValue;
      data.options.text = valueInputText || selectInputValue;
      data.label = selectInputLabel;
    } else if (actionType === ExtractorActionType.CLICK_SELECT) {
      data.selectorValue = selectorValue;
      data.xpathValue = xpathValue;
      data.options.select.option = selectInputValue;
      data.options.select.options = selectorOptions;
      data.label = selectInputLabel;
    } else if (
      actionType === ExtractorActionType.ASK_ID ||
      actionType === ExtractorActionType.ASK_PASSWORD ||
      actionType === ExtractorActionType.ASK_CODE ||
      actionType === ExtractorActionType.ASK_QUESTION ||
      (currentAction.options.askMultiStepDto !== undefined &&
        currentAction.options.askMultiStepDto.builderStep ===
          ExtractorActionAskType.USER_RESPONSE) ||
      actionType === ExtractorActionType.ASK_CAPTCHA
    ) {
      data.selectorValue = selectorValue;
      data.xpathValue = xpathValue;
      data.options.text = selectInputValue;
      data.label = selectInputLabel;
      data.saveOptionsFlag = false;
    } else if (
      actionType === ExtractorActionType.MOUSE_CLICK ||
      actionType === ExtractorActionType.MOUSE_MOVE ||
      actionType === undefined
    ) {
      data.options = options;
    } else if (actionType === ExtractorActionType.IF_EXISTS) {
      data.selectorValue = selectorValue;
      data.xpathValue = xpathValue;
      data.options.number = selectSequence;
    } else if (actionType === ExtractorActionType.SCRIPT) {
      data.selectorValue = undefined;
      data.xpathValue = undefined;
      data.options.script = codeData;
    } else if (actionType === ExtractorActionType.IF_ENABLED) {
      data.selectorValue = selectorValue;
      data.xpathValue = xpathValue;
      data.options.number = selectSequence;
    } else if (actionType === ExtractorActionType.IF_NOT_ENABLED) {
      data.selectorValue = selectorValue;
      data.xpathValue = xpathValue;
      data.options.number = selectSequence;
    } else if (actionType === ExtractorActionType.TYPE_F_ENGINE_FIELD) {
      data.selectorValue = selectorValue;
      data.xpathValue = xpathValue;
      data.options.number = selectSequence;
      data.options.select.option = typeFieldValue;
    }
    setSelectorValue(undefined);
    onFinishAction(data);
  };

  const clickIf = (event) => {
    const data = {
      selectorValue: undefined,
      xpathValue: undefined,
      label: undefined,
      options: new ExtractorActionOptions(),
    };
    data.selectorValue = selectorValue;
    data.xpathValue = xpathValue;
    data.options.number = event;
    setSelectorValue(undefined);
    onFinishAction(data);
    setShowGenericIf(false);
  };

  const handleClick = useCallback(
    (event) => {
      setAdaptiveAction(undefined);
      if (
        actionType !== ExtractorActionType.MOUSE_CLICK &&
        actionType !== ExtractorActionType.MOUSE_MOVE &&
        actionType !== undefined &&
        !isAdaptable
      )
        event.preventDefault();
      const element = event.target;
      if (element) {
        const selectorValueE = extractSelectorFromHTML(element.outerHTML);

        // if (currentAction.selectorValue === selectorValueE) {

        if (selectorValueE === currentAction.selectorValue) {
          setSelectInputLabel(currentAction.label);
          setSelectInputValue(currentAction.options.text);
        }
        // setCurrentAction((prevState) => ({
        //   ...prevState,
        //   selectorValue: selectorValueE,
        // }));

        // }

        setSelectorValue(selectorValueE);
        setXpathValue(element.getAttribute("xpath"));
      }
      if (isAdaptable) {
        const data = new ExtractorAction();
        const point = new ExtractorActionPoint(event.x, event.y);
        data.options.point = point;
        setOptions((prevState) => ({ ...prevState, point }));
        data.type = ExtractorActionType.MOUSE_CLICK;
        ExtractorService.getElementPoint(data).then(
          (res) => {
            data.selectorValue = res.data.selector;
            if (res.data.type === AdaptableActionType.INPUTABLE_TEXT) {
              data.type = ExtractorActionType.TYPE_TEXT;
              setValueInput("");
            } else if (
              res.data.type === AdaptableActionType.INPUTABLE_PASSWORD
            ) {
              data.type = ExtractorActionType.TYPE_PASSWORD;
              setValueInput("");
            } else if (res.data.type === AdaptableActionType.SELECT) {
              data.type = ExtractorActionType.SELECT;
            } else if (
              res.data.type === AdaptableActionType.CLICKABLE ||
              res.data.type === AdaptableActionType.OTHER
            ) {
              data.type = ExtractorActionType.MOUSE_CLICK;
              setOptions((prevState) => ({ ...prevState, point }));
            }
            setAdaptiveAction(data);
            setShowConfirmationModal(true);
          },
          () => {}
        );
      } else if (
        actionType === ExtractorActionType.MOUSE_CLICK ||
        actionType === ExtractorActionType.MOUSE_MOVE
      ) {
        let point = new ExtractorActionPoint(event.x, event.y);
        if (normalizedData.elements) {
          event.preventDefault();
          const coords = event.target.coords.replace("\n", "").split(",");
          point = new ExtractorActionPoint(coords[0].trim(), coords[1].trim());
        }

        setOptions((prevState) => ({ ...prevState, point }));
        setShowConfirmationModal(true);
      } else if (actionType === ExtractorActionType.GET_DATE) {
        setShowGenericDateTime(true);
      } else if (actionType === ExtractorActionType.GET_NUMBER) {
        setShowGenericNumber(true);
      } else if (
        actionType === ExtractorActionType.IF_EXISTS ||
        actionType === ExtractorActionType.IF_NOT_EXISTS ||
        actionType === ExtractorActionType.IF_DISPLAYED ||
        actionType === ExtractorActionType.IF_NOT_DISPLAYED ||
        actionType === ExtractorActionType.IF_ENABLED ||
        actionType === ExtractorActionType.IF_NOT_ENABLED
      ) {
        setShowGenericIf(true);
      } else if (actionType === ExtractorActionType.CLICK_SELECT) {
        const data = currentAction;
        data.selectorValue = selectorValue;
        data.xpathValue = xpathValue;
        if (element) {
          data.selectorValue = element.getAttribute("selector");
          data.xpathValue = element.getAttribute("xpath");
        }
        if (status !== ExtractorActionStatus.EDITING) {
          ExtractorService.executeAction(data).then(
            (res) => {
              setSelectorOptions(res.data.options.select.options);
              setShowConfirmationModal(true);
            },
            () => {}
          );
        } else {
          setSelectorOptions(currentAction.options.select.options);
          setShowConfirmationModal(true);
        }
      } else {
        setShowConfirmationModal(true);
        setValueInput("");
      }
    },
    [actionType]
  );

  const handleFinishGetTable = (event) => {
    const { selectedTable, selectedRow, selectedColumns } = event;
    const columns = selectedColumns.map((sc) => ({
      selector: sc.selector,
      label: sc.label,
      type: sc.type,
    }));

    onFinishAction({
      tableSelector: selectedTable.selector,
      rowSelector: selectedRow.selector,
      columns,
      options: {},
    });
  };

  const onChangeInputValue = (event) => {
    setSelectInputValue(event.target.value);
  };
  const onChangeInputLabel = (event) => {
    setSelectInputLabel(event.target.value);
  };

  const getConfirmationModalTitle = () => {
    let modalTitle;

    if (actionType === ExtractorActionType.GET_DATE) {
      modalTitle = (
        <Grid container>
          <Grid item sm={11}>
            {intl.formatMessage({ id: "extractor.action.modal.title.confirm" })}
          </Grid>
          <Grid item sm={1}>
            <Tooltip
              title={
                <div>
                  <p>YY - Year(70 71 ... 29 30)</p>
                  <p>YYYY - Year(1970 1971 ... 2029 2030)</p>
                  <p>MM - Month(01 02 ... 11 12)</p>
                  <p>MMM - Month(Jan Feb ... Nov Dec)</p>
                  <p>MMMM - Month(January February ... November December)</p>
                  <p>Q - Quarter(1 2 3 4)</p>
                  <p>D - Day(1 2 ... 30 31)</p>
                  <p>DD - Day(01 02 ... 30 31)</p>
                  <p>DDD - Day of Year(1 2 ... 364 365)</p>
                  <p>H - Hours(0 1 ... 22 23)</p>
                  <p>HH - Hours(00 01 ... 22 23)</p>
                  <p>m - Minutes(0 1 ... 58 59)</p>
                  <p>mm - Minutes(00 01 ... 58 59)</p>
                  <p>s - Seconds(0 1 ... 58 59)</p>
                  <p>ss - Seconds(00 01 ... 58 59)</p>
                  <p>[test] - Escape word test</p>
                </div>
              }
            >
              <Help className={classes.topIcon} />
            </Tooltip>
          </Grid>
        </Grid>
      );
    } else if (actionType === ExtractorActionType.SCRIPT) {
      modalTitle = intl.formatMessage({ id: "extractor.action.script.title" });
    } else if (actionType === ExtractorActionType.TYPE_F_ENGINE_FIELD) {
      let typeAction = actionType;
      if (adaptiveAction) {
        typeAction = adaptiveAction.type;
      }
      modalTitle = `${intl.formatMessage({
        id: "extractor.action.modal.title.confirm",
      })} ${intl.formatMessage({
        id: `extractor.action.${formatKeytointl(typeAction)}`,
      })} value: ${typeFieldValue.split("-")[1]}?`;
    } else {
      let typeAction = actionType;
      if (adaptiveAction) {
        typeAction = adaptiveAction.type;
      }
      modalTitle = `${intl.formatMessage({
        id: "extractor.action.modal.title.confirm",
      })} ${intl.formatMessage({
        id: `extractor.action.${formatKeytointl(typeAction)}`,
      })}?`;
    }

    return modalTitle;
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const getConfirmationModalContent = () => {
    let modalContent;
    if (
      (adaptiveAction &&
        adaptiveAction.type === ExtractorActionType.TYPE_TEXT) ||
      (!adaptiveAction &&
        (actionType === ExtractorActionType.TYPE_TEXT ||
          actionType === ExtractorActionType.ASK_ID ||
          actionType === ExtractorActionType.ASK_CODE ||
          (currentAction.options.askMultiStepDto !== undefined &&
            currentAction.options.askMultiStepDto.builderStep ===
              ExtractorActionAskType.USER_RESPONSE)))
    ) {
      modalContent = (
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <TextField
                fullWidth
                label={intl.formatMessage({
                  id: "extractor.action.modal.label",
                })}
                value={selectInputLabel}
                onChange={onChangeInputLabel}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                label="Value"
                required
                value={selectInputValue}
                onChange={onChangeInputValue}
              />
            </Grid>
          </Grid>
        </form>
      );
    } else if (actionType === ExtractorActionType.CLICK_SELECT) {
      modalContent = (
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <TextField
                fullWidth
                label={intl.formatMessage({
                  id: "extractor.action.modal.label",
                })}
                value={selectInputLabel}
                onChange={onChangeInputLabel}
              />
            </Grid>
            <Grid item sm={12}>
              <Select
                native
                fullWidth
                required
                onChange={onChangeInputValue}
                value={selectInputValue}
                labelId="select-options"
              >
                <option value="">Select Value</option>
                {selectorOptions &&
                  selectorOptions.map((option) => (
                    <option value={option.value}>{option.label}</option>
                  ))}
              </Select>
            </Grid>
          </Grid>
        </form>
      );
    } else if (
      (adaptiveAction &&
        adaptiveAction.type === ExtractorActionType.TYPE_PASSWORD) ||
      (!adaptiveAction &&
        (actionType === ExtractorActionType.TYPE_PASSWORD ||
          actionType === ExtractorActionType.ASK_PASSWORD))
    ) {
      modalContent = (
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <TextField
                fullWidth
                label={intl.formatMessage({
                  id: "extractor.action.modal.label",
                })}
                value={selectInputLabel}
                onChange={onChangeInputLabel}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                type={showPassword ? "text" : "password"}
                label={intl.formatMessage({
                  id: "extractor.action.label.password",
                })}
                required
                value={selectInputValue}
                onChange={onChangeInputValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </form>
      );
    } else if (
      actionType === ExtractorActionType.GET_TEXT ||
      actionType === ExtractorActionType.GET_IMAGE ||
      actionType === ExtractorActionType.GET_FILE
    ) {
      modalContent = (
        <Grid item sm={12}>
          <TextField
            fullWidth
            label={intl.formatMessage({ id: "extractor.action.modal.label" })}
            value={selectInputLabel}
            onChange={onChangeInputLabel}
          />
        </Grid>
      );
    } else if (
      actionType === ExtractorActionType.IF_EXISTS ||
      actionType === ExtractorActionType.IF_ENABLED ||
      actionType === ExtractorActionType.IF_NOT_ENABLED
    ) {
      modalContent = (
        <Grid item sm={12}>
          <TextField
            fullWidth
            label={intl.formatMessage({ id: "select.sequence" })}
            value={selectSequence}
            // onChange={onChangeSelectSequence}
            onChange={() => {}}
            type="text"
          />
        </Grid>
      );
    } else if (actionType === ExtractorActionType.SCRIPT) {
      modalContent = <InputActionCode data={codeData} setData={setCodeData} />;
    } else {
      modalContent = <></>;
    }

    return modalContent;
  };

  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
    setSelectInputLabel("");
    setSelectInputValue("");
    if (actionType === ExtractorActionType.SCRIPT) cancelAction();
  };

  const handleConfirmationGenericNumberClose = () => {
    setShowGenericNumber(false);
    if (actionType === ExtractorActionType.SCRIPT) cancelAction();
  };

  const getImageMapper = () => {
    let mapper;
    if (
      // actionType === ExtractorActionType.MOUSE_CLICK ||
      actionType === ExtractorActionType.MOUSE_MOVE ||
      isAdaptable
    ) {
      mapper = (
        <ImageMapperMouse
          img={image}
          onClick={handleClick}
          areas={normalizedData.elements}
          displayLines={props.displayLines}
        />
      );
    } else if (actionType === ExtractorActionType.GET_TABLE) {
      mapper = (
        <ImageMapperGetTable
          img={image}
          areas={normalizedData.elements}
          handleFinishGetTable={handleFinishGetTable}
          cancelAction={cancelAction}
        />
      );
    } else {
      mapper = (
        <ImageMapper
          img={image}
          areas={normalizedData.elements}
          onClick={handleClick}
          displayLines={props.displayLines}
        />
      );
    }
    return mapper;
  };

  const clickDateTime = (selectDateValue, selectGMT, label) => {
    setShowGenericDateTime(false);
    onFinishAction({
      selectorValue,
      options: { datetimeFormat: { mask: selectDateValue, gmt: selectGMT } },
      label,
    });
  };

  const clickNumber = (numberFormat, label) => {
    setShowGenericNumber(false);
    onFinishAction({ selectorValue, options: { numberFormat }, label });
  };

  return (
    <>
      {getImageMapper()}
      {showConfirmationModal && (
        <GenericModal
          title={getConfirmationModalTitle()}
          click={clickSelectAction}
          component={getConfirmationModalContent()}
          handleClose={handleConfirmationModalClose}
          handleEnter={handleEnter}
          showEnter={
            (adaptiveAction &&
              adaptiveAction.type === ExtractorActionType.TYPE_TEXT) ||
            (!adaptiveAction &&
              (actionType === ExtractorActionType.TYPE_TEXT ||
                actionType === ExtractorActionType.ASK_ID ||
                actionType === ExtractorActionType.ASK_CODE ||
                (currentAction.options.askMultiStepDto !== undefined &&
                  currentAction.options.askMultiStepDto.builderStep ===
                    ExtractorActionAskType.USER_RESPONSE)))
          }
          maxWidth={actionType === ExtractorActionType.SCRIPT ? "lg" : "sm"}
        />
      )}
      {showGenericNumber && (
        <GenericNumber
          click={clickNumber}
          xpathValue={xpathValue}
          selectorValue={selectorValue}
          label
          testAvailable={status !== ExtractorActionStatus.EDITING}
          handleClose={handleConfirmationGenericNumberClose}
        />
      )}
      {showGenericDateTime && (
        <GenericDateTime
          click={clickDateTime}
          selectorValue={selectorValue}
          label
          testAvailable={status !== ExtractorActionStatus.EDITING}
        />
      )}

      {showGenericIf && <GenericIf click={clickIf} type={actionType} />}
    </>
  );
}

Normalized.propTypes = {
  normalizedData: PropTypes.shape(PropTypes.object.isRequired),
  setImage: PropTypes.func,
  image: PropTypes.string,
  onFinishAction: PropTypes.func,
  setCurrentAction: PropTypes.func,
  currentAction: PropTypes.shape(PropTypes.object.isRequired),
  cancelAction: PropTypes.func,
  status: PropTypes.number,
  isAdaptable: PropTypes.bool,
  valueInput: PropTypes.string,
  setValueInput: PropTypes.func,
  displayLines: Boolean,
};

Normalized.defaultProps = {
  normalizedData: { pageScreenshot: undefined, elements: [] },
  onFinishAction: () => {},
  currentAction: {},
  cancelAction: () => {},
  status: 0,
  setCurrentAction: () => {},
  isAdaptable: true,
  valueInput: "",
  setValueInput: () => {},
  setImage: () => {},
  image: "",
  displayLines: true,
};

export default Normalized;

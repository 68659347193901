import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import TextFormatIcon from "@material-ui/icons/TextFormat";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import TableStepEnum from "../../../../models/enums/TableStep";
import GenericModal from "../../GenericModal";
import DataType from "../../../../models/enums/DataType";
import GenericDateTime from "../../GenericDateTime";
import GenericNumber from "../../GenericNumber";

const useStyles = makeStyles(() => ({
  input: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: "25%",
    position: "absolute",
    top: "175px",
    height: "80vh",
  },
  drawerTitle: {
    textAlign: "center",
  },
  right: {
    float: "right",
  },
}));

function ImageMapperGetTable(props) {
  const classes = useStyles();
  const intl = useIntl();
  const { img, areas, handleFinishGetTable, cancelAction } = props;
  const [currentElement, setCurrentElement] = useState();
  const [tableStep, setTableStep] = useState(TableStepEnum.TABLE);
  const [selectedTable, setSelectedTable] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showModalNext, setShowModalNext] = useState(false);
  const [showIsTakenModal, setShowIsTakenModal] = useState(false);
  const [showGenericDateTime, setShowGenericDateTime] = useState(false);
  const [showGenericNumber, setShowGenericNumber] = useState(false);
  const [elementStack, setElementStack] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectTypeColumn, setSelectTypeColumn] = useState("TEXT");
  const [labelColumnInputState, setLabelColumnInputState] = useState({
    value: "",
    error: false,
    errorMsg: "",
  });
  const [selectorValue, setSelectorValue] = useState("");
  const [xpathValue, setXpathValue] = useState("");
  const [anchorEl, setAnchorEl] = useState();
  const [currentColumn, setCurrentColumn] = useState();
  const [isEditColumn, setIsEditColumn] = useState(false);
  const [selectedElement, setSelectedElement] = useState(false);
  const [filteredAreas, setFilteredAreas] = useState([]);
  const [showDrawer, setShowDrawer] = useState([]);
  const prevTableStep = useRef(tableStep);
  const imgRef = useRef(null);
  const canvasRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (prevTableStep.current !== tableStep) prevTableStep.current = tableStep;
  }, [tableStep]);

  const getContext = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.setLineDash([5, 10]);
    ctx.lineWidth = 2;
    ctx.strokeStyle = "green";

    return ctx;
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, imgRef.current.width, imgRef.current.height);
    if (img.includes("ndex")) {
      imgRef.current.setAttribute("src", img);
    } else {
      imgRef.current.setAttribute("src", `data:image/png;base64, ${img}`);
    }
  }, [img, areas, canvasRef]);

  const handleOnLoad = (event) => {
    canvasRef.current.width = event.currentTarget.width;
    canvasRef.current.height = event.currentTarget.height;

    if (areas && areas.length > 0) {
      setFilteredAreas(areas.filter((a) => a.hasText));

      const ctx = getContext();
      areas
        .filter((a) => a.hasText)
        .forEach((a) => {
          ctx.strokeRect(a.x, a.y, a.width, a.height);
        });

      setShowModalNext(true);
      setShowDrawer(true);
    }
  };

  const isInside = (rect, reference) =>
    rect.x >= reference.x &&
    rect.y >= reference.y &&
    rect.width + rect.x <= reference.width + reference.x &&
    rect.height + rect.y <= reference.height + reference.y;

  const drawAreasWhenTable = () => {
    const ctx = getContext();

    areas
      .filter((a) => a.hasText)
      .forEach((a) => {
        ctx.strokeRect(a.x, a.y, a.width, a.height);
      });
  };

  const drawSelectedTable = (ctx, table) => {
    ctx.save();
    ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
    ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    ctx.clearRect(table.x, table.y, table.width, table.height);
    ctx.setLineDash([5, 10]);
    ctx.lineWidth = 2;
    ctx.strokeRect(table.x, table.y, table.width, table.height);
    ctx.restore();
  };

  const drawAreasWhenRow = (areasRef, table) => {
    const ctx = getContext();

    drawSelectedTable(ctx, table);

    areasRef.forEach((a) => {
      ctx.strokeRect(a.x, a.y, a.width, a.height);
    });
  };

  const drawSelectedRow = (ctx, row) => {
    drawSelectedTable(ctx, selectedTable);
    ctx.save();
    ctx.fillStyle = "rgba(255, 255, 255, 0.5)";
    ctx.fillRect(
      selectedTable.x,
      selectedTable.y,
      selectedTable.width,
      selectedTable.height
    );
    ctx.clearRect(row.x, row.y, row.width, row.height);
    ctx.setLineDash([]);
    ctx.lineWidth = 2;
    ctx.strokeRect(row.x, row.y, row.width, row.height);
    ctx.restore();
  };

  const drawSelectedColumn = (rect) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    ctx.fillStyle = "rgba(79, 195, 247, 0.5)";
    ctx.fillRect(rect.x, rect.y, rect.width, rect.height);
  };

  const drawAreasWhenColumn = (areasRef, row) => {
    const ctx = getContext();

    drawSelectedRow(ctx, row);

    areasRef.forEach((a) => {
      ctx.strokeRect(a.x, a.y, a.width, a.height);
    });

    selectedColumns.forEach((col) => {
      const { rect } = col;
      drawSelectedColumn({
        x: rect.x,
        y: rect.y,
        width: rect.width,
        height: rect.height,
      });
    });
  };

  const clearSelectedColumn = (col) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(col.x, col.y, col.width, col.height);
    ctx.setLineDash([5, 10]);
    ctx.lineWidth = 2;
    ctx.strokeStyle = "green";
    ctx.strokeRect(col.x, col.y, col.width, col.height);
  };

  const drawSelectedElement = (element) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (tableStep === TableStepEnum.TABLE) {
      drawAreasWhenTable();
    } else if (tableStep === TableStepEnum.ROW) {
      drawSelectedTable(ctx, selectedTable);
      drawAreasWhenRow(filteredAreas, selectedTable);
    } else if (tableStep === TableStepEnum.COLUMN) {
      drawSelectedRow(ctx, selectedTable);
      drawAreasWhenColumn(filteredAreas, selectedRow);
    }

    ctx.fillStyle = "rgba(79, 195, 247, 0.5)";
    ctx.fillRect(element.x, element.y, element.width, element.height);
  };

  const drawMouseEnter = (rect) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    ctx.setLineDash([]);
    ctx.lineWidth = 3;
    ctx.fillStyle = "rgba(255, 255, 255, 0.25)";
    ctx.fillRect(rect.x, rect.y, rect.width, rect.height);
    ctx.strokeRect(rect.x, rect.y, rect.width, rect.height);
  };

  const handleMouseEnter = (e) => {
    if (!currentElement) {
      const element = e.target;
      const coords = element.getAttribute("coords").split(",");
      const rect = {
        x: coords[0],
        y: coords[1],
        width: coords[2] - coords[0],
        height: coords[3] - coords[1],
      };

      drawMouseEnter(rect);
    }
  };

  const handleMouseLeave = () => {
    if (!selectedElement) {
      if (tableStep === TableStepEnum.TABLE) {
        drawAreasWhenTable();
      } else if (tableStep === TableStepEnum.ROW) {
        drawAreasWhenRow(filteredAreas, selectedTable);
      } else if (tableStep === TableStepEnum.COLUMN) {
        drawAreasWhenColumn(filteredAreas, selectedRow);
      }
    }
  };

  const handleTableClick = (e) => {
    e.preventDefault();
    setElementStack([]);
    const element = areas.find(
      (el) => el.id === Number(e.target.getAttribute("id"))
    );
    if (element) {
      setSelectedElement(true);
      if (tableStep === TableStepEnum.TABLE) {
        drawSelectedElement({
          x: element.x,
          y: element.y,
          width: element.width,
          height: element.height,
        });
        setCurrentElement(element);
      } else if (tableStep === TableStepEnum.ROW) {
        drawSelectedElement({
          x: element.x,
          y: element.y,
          width: element.width,
          height: element.height,
        });
        setCurrentElement(element);
      } else if (tableStep === TableStepEnum.COLUMN) {
        if (
          selectedColumns &&
          selectedColumns.find((c) => c.selector === element.selector)
        ) {
          setShowIsTakenModal(true);
        } else {
          setCurrentElement(element);
          setIsEditColumn(false);
          setLabelColumnInputState((prevState) => ({
            ...prevState,
            value: "",
            error: false,
            errorMessage: "",
          }));
          setShowModal(true);
        }
      }
    }
  };

  const handleTableClickUp = () => {
    if (currentElement) {
      const parentElement = areas.find((p) => p.id === currentElement.parentId);

      if (tableStep === TableStepEnum.TABLE) {
        if (parentElement) {
          drawSelectedElement({
            x: parentElement.x,
            y: parentElement.y,
            width: parentElement.width,
            height: parentElement.height,
          });
          setElementStack((prevState) => [...prevState, currentElement]);
          setCurrentElement(parentElement);
        }
      } else if (tableStep === TableStepEnum.ROW) {
        if (
          parentElement &&
          parentElement !== selectedTable &&
          parentElement.tagName !== "TBODY"
        ) {
          drawSelectedElement({
            x: parentElement.x,
            y: parentElement.y,
            width: parentElement.width,
            height: parentElement.height,
          });
          setElementStack((prevState) => [...prevState, currentElement]);
          setCurrentElement(parentElement);
        }
      } else if (tableStep === TableStepEnum.COLUMN) {
        if (parentElement && parentElement !== selectedRow) {
          drawSelectedElement({
            x: parentElement.x,
            y: parentElement.y,
            width: parentElement.width,
            height: parentElement.height,
          });
          setElementStack((prevState) => [...prevState, currentElement]);
          setCurrentElement(parentElement);
        }
      }
    }
  };

  const handleTableClickDown = () => {
    if (currentElement) {
      let nextElement = currentElement;
      if (tableStep === TableStepEnum.TABLE) {
        if (elementStack.length > 0) {
          nextElement = elementStack.pop();
          setElementStack(elementStack);
          setCurrentElement(nextElement);
        }
        drawSelectedElement({
          x: nextElement.x,
          y: nextElement.y,
          width: nextElement.width,
          height: nextElement.height,
        });
      } else if (tableStep === TableStepEnum.ROW) {
        if (elementStack.length > 0) {
          nextElement = elementStack.pop();
          setElementStack(elementStack);
          setCurrentElement(nextElement);
        }
        drawSelectedElement({
          x: nextElement.x,
          y: nextElement.y,
          width: nextElement.width,
          height: nextElement.height,
        });
      } else if (tableStep === TableStepEnum.COLUMN) {
        currentElement.classList.remove("border-outline");

        if (elementStack.length > 0) {
          nextElement = currentElement.firstElementChild;
          setCurrentElement(nextElement);
        }

        drawSelectedElement({
          x: nextElement.x,
          y: nextElement.y,
          width: nextElement.width,
          height: nextElement.height,
        });
      }
    }
  };

  const handleClick = (event, column) => {
    setAnchorEl(event.currentTarget);
    setCurrentColumn(column);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteColumn = () => {
    const removeColumns = selectedColumns.filter(
      (column) => column !== currentColumn
    );
    setSelectedColumns(removeColumns);
    clearSelectedColumn({
      x: currentColumn.x,
      y: currentColumn.y,
      width: currentColumn.width,
      height: currentColumn.height,
    });
    setAnchorEl(null);
  };

  const editColumn = () => {
    setIsEditColumn(true);
    setShowModal(true);
    setAnchorEl(null);
    setLabelColumnInputState((prevState) => ({
      ...prevState,
      value: currentColumn.label,
      error: false,
      errorMessage: "",
    }));
  };

  const reset = () => {
    imgRef.current.onload = null;
    setShowDrawer(false);
    setCurrentElement(undefined);
    setSelectedTable(undefined);
    setSelectedRow(undefined);
    setElementStack([]);
    setSelectedColumns([]);
    setTableStep(TableStepEnum.TABLE);
    setSelectedElement(false);
  };

  const handleTableClickCancel = () => {
    cancelAction();
    reset();
  };

  const handleTableClickNext = () => {
    if (tableStep === TableStepEnum.TABLE) {
      setSelectedTable(currentElement);
      setTableStep(TableStepEnum.ROW);
      setCurrentElement(undefined);
      setSelectedElement(false);
      setElementStack([]);
      setShowModalNext(true);
      const newFilteredAreas = filteredAreas.filter((a) =>
        isInside(a, currentElement)
      );
      setFilteredAreas(newFilteredAreas);
      drawAreasWhenRow(newFilteredAreas, currentElement);
    } else if (tableStep === TableStepEnum.ROW) {
      setSelectedRow(currentElement);
      setTableStep(TableStepEnum.COLUMN);
      setCurrentElement(undefined);
      setSelectedElement(false);
      setElementStack([]);
      setShowModalNext(true);
      const newFilteredAreas = filteredAreas
        .filter((a) => isInside(a, currentElement))
        .filter(() => true); // TODO show only element with same tag that selectedRow
      setFilteredAreas(
        newFilteredAreas.filter((a) => isInside(a, currentElement))
      );
      drawAreasWhenColumn(newFilteredAreas, currentElement);
    } else if (tableStep === TableStepEnum.COLUMN) {
      reset();
      handleFinishGetTable({
        selectedTable,
        selectedRow,
        selectedColumns,
      });
    }
  };

  const onChangeSelectType = (event) => {
    setSelectTypeColumn(event.target.value);
  };

  const onChangeLabelType = (event) => {
    setLabelColumnInputState((prevState) => ({
      ...prevState,
      value: event.target.value,
      error: false,
      errorMessage: "",
    }));
  };

  const modalContent = (
    <Grid container spacing={2}>
      <Grid item sm={9}>
        <TextField
          className={classes.input}
          label={intl.formatMessage({ id: "extractor.action.get.table.label" })}
          required
          value={labelColumnInputState.value}
          error={labelColumnInputState.error}
          helperText={labelColumnInputState.errorMsg}
          onChange={onChangeLabelType}
        />
      </Grid>
      <Grid item sm={3}>
        <FormControl className={classes.formControl}>
          <InputLabel id="action-type-label">
            <FormattedMessage id="extractor.action.get.table.type" />
          </InputLabel>
          <Select
            native
            labelId="action-type-label"
            label={intl.formatMessage({
              id: "extractor.action.get.table.type",
            })}
            onChange={onChangeSelectType}
            value={selectTypeColumn}
            id="action-type"
          >
            <option value={DataType.TEXT}>
              {intl.formatMessage({ id: "extractor.action.get.table.text" })}
            </option>
            <option value={DataType.NUMBER}>
              {intl.formatMessage({ id: "extractor.action.get.table.number" })}
            </option>
            <option value={DataType.DATETIME}>
              {intl.formatMessage({
                id: "extractor.action.get.table.datetime",
              })}
            </option>
            <option value={DataType.FILE}>
              {intl.formatMessage({
                id: "extractor.action.get.table.file",
              })}
            </option>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  const modalContentNext = () => {
    if (tableStep === TableStepEnum.TABLE) {
      return (
        <div>
          <FormattedMessage id="extractor.action.get.table.instructions.first" />
        </div>
      );
    }
    if (tableStep === TableStepEnum.ROW) {
      return (
        <div>
          <FormattedMessage id="extractor.action.get.table.instructions.second" />
        </div>
      );
    }
    if (tableStep === TableStepEnum.COLUMN) {
      return (
        <div>
          <FormattedMessage id="extractor.action.get.table.instructions.third" />
        </div>
      );
    }
    return <div />;
  };

  const modalTitleNext = () => {
    if (tableStep === TableStepEnum.TABLE) {
      return intl.formatMessage({
        id: "extractor.action.get.table.select.first",
      });
    }
    if (tableStep === TableStepEnum.ROW) {
      return intl.formatMessage({
        id: "extractor.action.get.table.select.second",
      });
    }
    if (tableStep === TableStepEnum.COLUMN) {
      return intl.formatMessage({
        id: "extractor.action.get.table.select.third",
      });
    }
    return "";
  };

  const modalTitleConfirm = () =>
    intl.formatMessage({ id: "extractor.action.get.table.column.selected" });

  const modalContentConfirm = () => (
    <div>
      <FormattedMessage id="extractor.action.get.table.column.another" />
    </div>
  );

  const handleConfirmModal = () => {
    if (
      !labelColumnInputState.value ||
      labelColumnInputState.value.trim() === ""
    ) {
      setLabelColumnInputState((prevState) => ({
        ...prevState,
        value: "",
        error: true,
        errorMessage: "Required",
      }));
    } else {     
      if (selectTypeColumn === DataType.FILE && !currentElement.containsHref && currentElement.tagName !== "A") {
        enqueueSnackbar(intl.formatMessage({ id: "error.element.does.not.contain.download.link" }), {
          variant: "error",
        });

        return;
      }

      let rect;
      if (currentElement) {
        rect = {
          x: currentElement.x,
          y: currentElement.y,
          width: currentElement.width,
          height: currentElement.height,
        };
      } else {
        rect = {
          x: currentColumn.x,
          y: currentColumn.y,
          width: currentColumn.width,
          height: currentColumn.height,
        };
      }
      if (isEditColumn) {
        const newColumn = {
          id: currentColumn.id,
          selector: currentColumn.selector,
          label: labelColumnInputState.value,
          type: selectTypeColumn,
          rect,
        };

        const editColumns = selectedColumns.map((column) => {
          if (column !== currentColumn) {
            return column;
          }
          return newColumn;
        });
        setSelectedColumns(editColumns);
        setShowModal(false);
        setAnchorEl(null);

        if (selectTypeColumn === DataType.NUMBER) {
          setShowGenericNumber(true);
        } else if (selectTypeColumn === DataType.DATETIME) {
          setShowGenericDateTime(true);
        }
      } else if (selectTypeColumn === DataType.TEXT || selectTypeColumn === DataType.FILE) {
        //TODO Needs a proper icon, it currently display a calendar icon
        const newColumn = {
          id: currentElement.id,
          selector: currentElement.selector,
          label: labelColumnInputState.value,
          type: selectTypeColumn,
          rect,
        };
        setSelectedColumns((prevState) => [...prevState, newColumn]);
        setLabelColumnInputState((prevState) => ({
          ...prevState,
          value: "",
          error: false,
          errorMessage: "",
        }));
        setSelectTypeColumn("TEXT");
        setShowModal(false);
        setCurrentElement(undefined);
        setSelectedElement(false);
        drawSelectedColumn(currentElement);
      } else if (selectTypeColumn === DataType.DATETIME) {
        setSelectorValue(currentElement.selector);
        setShowGenericDateTime(true);
        setShowModal(false);
      } else if (selectTypeColumn === DataType.NUMBER) {
        setSelectorValue(currentElement.selector);
        setXpathValue(currentElement.xpathValue);
        setShowGenericNumber(true);
        setShowModal(false);
      }
    }
  };

  const getTableStep = () => {
    if (tableStep === TableStepEnum.TABLE) {
      return <FormattedMessage id="get.table.drawer.title.table" />;
    }
    if (tableStep === TableStepEnum.ROW) {
      return <FormattedMessage id="get.table.drawer.title.row" />;
    }
    return <FormattedMessage id="get.table.drawer.title.columns" />;
  };

  const getColumType = (Column) => {
    if (Column.type === "NUMBER") {
      return <FormatListNumberedIcon />;
    }
    if (Column.type === "DATE") {
      return <CalendarTodayIcon />;
    }
    if (Column.type === "FILE") {
      return <FileCopyIcon />;
    }
    
    return <TextFormatIcon />;
  };

  const handleColumnModalCancel = () => {
    setShowModal(false);
    drawAreasWhenColumn(filteredAreas, selectedRow);
    setCurrentElement(undefined);
  };

  // eslint-disable-next-line no-unused-vars
  const clickDateTime = (selectDateValue, selectGMT, label) => {
    let rect;
    if (currentElement) {
      rect = {
        x: currentElement.x,
        y: currentElement.y,
        width: currentElement.width,
        height: currentElement.height,
      };
    } else {
      rect = {
        x: currentColumn.x,
        y: currentColumn.y,
        width: currentColumn.width,
        height: currentColumn.height,
      };
    }

    if (isEditColumn) {
      const newColumn = {
        id: currentColumn.id,
        selector: currentColumn.selector,
        label: labelColumnInputState.value,
        type: selectTypeColumn,
        rect,
      };

      const editColumns = selectedColumns.map((column) => {
        if (column !== currentColumn) {
          return column;
        }
        return newColumn;
      });
      setSelectedColumns(editColumns);
      setAnchorEl(null);
    } else if (selectTypeColumn === DataType.DATETIME) {
      const newColumn = {
        id: currentElement.id,
        selector: currentElement.selector,
        label: labelColumnInputState.value,
        type: selectTypeColumn,
        mask: selectDateValue,
        textValue: selectGMT,
        rect,
      };
      setSelectedColumns((prevState) => [...prevState, newColumn]);
      setLabelColumnInputState((prevState) => ({
        ...prevState,
        value: "",
        error: false,
        errorMessage: "",
      }));
      setSelectTypeColumn("DATETIME");
      setShowModal(false);
      setCurrentElement(undefined);
      setSelectedElement(false);
      drawSelectedColumn(currentElement);
    } else if (selectTypeColumn === DataType.DATETIME) {
      setSelectorValue(currentElement.selector);
      setShowGenericDateTime(true);
      setShowModal(false);
    } else if (selectTypeColumn === DataType.NUMBER) {
      setSelectorValue(currentElement.selector);
      setXpathValue(currentElement.xpathValue);
      setShowGenericNumber(true);
      setShowModal(false);
    }

    setShowGenericDateTime(false);
  };

  // eslint-disable-next-line no-unused-vars
  const clickNumber = (extractorActionNumberMaskDto, label) => {
    let rect;
    if (currentElement) {
      rect = {
        x: currentElement.x,
        y: currentElement.y,
        width: currentElement.width,
        height: currentElement.height,
      };
    } else {
      rect = {
        x: currentColumn.x,
        y: currentColumn.y,
        width: currentColumn.width,
        height: currentColumn.height,
      };
    }

    if (isEditColumn) {
      const newColumn = {
        id: currentColumn.id,
        selector: currentColumn.selector,
        label: labelColumnInputState.value,
        type: selectTypeColumn,
        rect,
      };

      const editColumns = selectedColumns.map((column) => {
        if (column !== currentColumn) {
          return column;
        }
        return newColumn;
      });
      setSelectedColumns(editColumns);
      setAnchorEl(null);
    } else {
      const newColumn = {
        id: currentElement.id,
        selector: currentElement.selector,
        label: labelColumnInputState.value,
        type: selectTypeColumn,
        extractorActionNumberMaskDto,
        rect,
      };
      setSelectedColumns((prevState) => [...prevState, newColumn]);
      setLabelColumnInputState((prevState) => ({
        ...prevState,
        value: "",
        error: false,
        errorMessage: "",
      }));
      setSelectTypeColumn("NUMBER");
      setShowModal(false);
      setCurrentElement(undefined);
      setSelectedElement(false);
      drawSelectedColumn(currentElement);
    }
    setShowGenericNumber(false);
  };

  return (
    <>
      {showDrawer && (
        <Drawer
          variant="permanent"
          anchor="left"
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <List>
            <ListItem key="title">
              <ListItemText className={classes.drawerTitle}>
                <FormattedMessage id="get.table.drawer.title" />
                {getTableStep()}
              </ListItemText>
            </ListItem>
            {tableStep !== TableStepEnum.COLUMN && (
              <ListItem onClick={handleTableClickUp} button key="up">
                <ListItemIcon>
                  <ArrowUpwardIcon />
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id="get.table.up" />
                </ListItemText>
              </ListItem>
            )}
            {tableStep !== TableStepEnum.COLUMN && (
              <ListItem onClick={handleTableClickDown} button key="down">
                <ListItemIcon>
                  <ArrowDownwardIcon />
                </ListItemIcon>
                <ListItemText>
                  <FormattedMessage id="get.table.down" />
                </ListItemText>
              </ListItem>
            )}
            <ListItem onClick={handleTableClickNext} button key="next">
              <ListItemIcon>
                {tableStep === TableStepEnum.COLUMN ? (
                  <NavigateNextIcon />
                ) : (
                  <DoneIcon />
                )}
              </ListItemIcon>
              <ListItemText>
                {tableStep === TableStepEnum.COLUMN ? (
                  <FormattedMessage id="get.table.finish" />
                ) : (
                  <FormattedMessage id="get.table.next" />
                )}
              </ListItemText>
            </ListItem>
            <ListItem onClick={handleTableClickCancel} button key="cancel">
              <ListItemIcon>
                <CloseIcon />
              </ListItemIcon>
              <ListItemText>
                <FormattedMessage id="get.table.cancel" />
              </ListItemText>
            </ListItem>
          </List>
          <Divider />
          <List>
            {selectedColumns.map((column, index) => (
              <ListItem button key={index}>
                <ListItemIcon>{getColumType(column)}</ListItemIcon>
                <ListItemText>
                  {column.label}{" "}
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      handleClick(e, column);
                    }}
                    className={classes.right}
                  >
                    <MoreVertOutlinedIcon />
                  </IconButton>
                </ListItemText>
              </ListItem>
            ))}
          </List>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => editColumn()}>
              <FormattedMessage id="action.edit" />
            </MenuItem>
            <MenuItem onClick={deleteColumn}>
              <FormattedMessage id="action.remove" />
            </MenuItem>
          </Menu>
        </Drawer>
      )}

      <div style={{ position: "relative" }}>
        <img src="" alt="" useMap="#map" ref={imgRef} onLoad={handleOnLoad} />

        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            pointerEvents: "none",
            zIndex: 2,
          }}
        />

        <map name="map">
          {filteredAreas &&
            filteredAreas.map((e, index) => (
              <area
                key={index}
                alt={index}
                shape="rect"
                coords={`${e.x}, 
              ${e.y}, 
              ${e.x + e.width}, 
              ${e.y + e.height} `}
                target="_self"
                href="#"
                onClick={handleTableClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                selector={e.selector}
                id={e.id}
                parentid={e.parentId ? e.parentId : undefined}
              />
            ))}
        </map>
      </div>

      <Box
        ndex-overlay="true"
        display="flex"
        justifyContent="center"
        component={Paper}
        square
        elevation={5}
        flexGrow={1}
        style={{ position: "sticky", top: 0, zIndex: 999 }}
      />
      {showModal && (
        <GenericModal
          title=""
          click={handleConfirmModal}
          component={modalContent}
          handleClose={handleColumnModalCancel}
        />
      )}
      {showModalNext && (
        <GenericModal
          title={modalTitleNext()}
          click={() => setShowModalNext(false)}
          component={modalContentNext()}
          handleClose={() => setShowModalNext(false)}
          showCancel={false}
        />
      )}
      {showIsTakenModal && (
        <GenericModal
          title={modalTitleConfirm()}
          click={() => setShowIsTakenModal(false)}
          component={modalContentConfirm()}
          handleClose={() => setShowIsTakenModal(false)}
          showCancel={false}
        />
      )}

      {showGenericDateTime && (
        <GenericDateTime
          click={clickDateTime}
          selectorValue={selectorValue}
          label={false}
        />
      )}

      {showGenericNumber && (
        <GenericNumber
          click={clickNumber}
          selectorValue={selectorValue}
          xpathValue={xpathValue}
          label={false}
        />
      )}
    </>
  );
}

ImageMapperGetTable.propTypes = {
  img: PropTypes.string,
  areas: PropTypes.arrayOf(PropTypes.shape({})),
  handleFinishGetTable: PropTypes.func,
  cancelAction: PropTypes.func,
};

ImageMapperGetTable.defaultProps = {
  img: undefined,
  areas: [],
  handleFinishGetTable: () => {},
  cancelAction: () => {},
};

export default ImageMapperGetTable;

/* eslint-disable react/jsx-boolean-value */
import {
  Box,
  CardContent,
  CardHeader,
  Fade,
  Typography,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/es/Button/Button";
import Container from "@material-ui/core/es/Container/Container";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import Copyright from "../login/Copyright";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    marginTop: theme.spacing(12),
  },
  cardHeader: {
    marginTop: theme.spacing(6),
  },
  cardContent: {
    margin: theme.spacing(2, 2, 2, 2),
  },
  submit: {
    marginTop: theme.spacing(6),
  },
  textField: {
    fullWidth: true,
  },
}));

function SignMessage() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Fade in timeout={500}>
          <Card className={classes.card}>
            <CardHeader
              className={classes.cardHeader}
              align="center"
              title={<FormattedMessage id="title.welcome" />}
              subheader={<FormattedMessage id="verify.welcome" />}
            />
            <CardContent className={classes.cardContent}>
              Click on the link you received via email to confirm your sign up.
              <Button
                type="button"
                fullWidth
                size="large"
                variant="contained"
                color="secondary"
                className={classes.submit}
                onClick={() => history.push("/login")}
              >
                <Typography>
                  <FormattedMessage id="login.back" />
                </Typography>
              </Button>
            </CardContent>
          </Card>
        </Fade>
        <Box mt={4}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}

export default SignMessage;

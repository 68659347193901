import {
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import PropTypes, { number } from "prop-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ExtractorActionType from "../../../../models/enums/ExtractorActionType";
import ExtractorStatus from "../../../../models/enums/ExtractorStatus";
import GenericModal from "../../GenericModal";
import "../../../app/App.css";

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  input: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  getTableInput: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  getNumberInput: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },

  getDateInput: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  linearProgress: {
    width: "100%",
    margin: "30px 0 0 0",
  },
  buttonGroup: {
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
}));

function InvestorSelect(props) {
  const classes = useStyles();
  const intl = useIntl();
  const { addAction, showInvestor, setShowInvestor, investors, setSelectType } =
    props;

  const clickSelectAction = () => {
    setSelectType(ExtractorActionType.ASK_F_ENGINE_INVESTOR);
    const option = document.getElementById("investor-select").value;
    const sequence = null;
    const selectType = ExtractorActionType.ASK_F_ENGINE_INVESTOR;
    addAction({ sequence, selectType, undefined, option });
  };

  const renderSelectOptions = () => {
    let select = <></>;
    select = (
      <Select
        native
        labelId="investor-select-label"
        label="Type"
        // value={investors[0]?.portfolioid + " - " + investors[0]?.name}
        id="investor-select"
      >
        {Object.keys(investors)
          .sort()
          .map((key) => (
            <option value={investors[key].confirmationNumber} key={investors[key].confirmationNumber}>
              {investors[key]?.portfolioid + " - " + investors[key]?.name}
            </option>
          ))}
      </Select>
    );
    return select;
  };

  const componentSelectAction = (
    <>
      <Grid item sm={9}>
        <FormControl className={classes.formControl}>
          <InputLabel id="action-type-label">
            <FormattedMessage id="select.action.title" />
          </InputLabel>
          {renderSelectOptions()}
        </FormControl>
      </Grid>
    </>
  );

  return (
    <>
      {showInvestor && (
        <GenericModal
          title={"Select Investor"}
          component={componentSelectAction}
          click={clickSelectAction}
          handleClose={() => setShowInvestor(false)}
        />
      )}
    </>
  );
}

InvestorSelect.propTypes = {
  addAction: PropTypes.func,
  setShowInvestor: PropTypes.func,
  setSelectType: PropTypes.func,
  showInvestor: PropTypes.bool,
  investors: PropTypes.array,
};

InvestorSelect.defaultProps = {
  showInvestor: false,
  investors: [],
  addAction: () => {},
  setShowInvestor: () => {},
  setSelectType: () => {},
};

export default InvestorSelect;

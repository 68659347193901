import {
  makeStyles,
  TextField,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import GenericModal from "./GenericModal";
import ExtractorService from "../../services/ExtractorService";
import ExtractorActionNumberMaskDto from "../../models/ExtractorActionNumberMaskDto";

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: 10,
  },
}));

function GenericNumber(props) {
  const classes = useStyles();
  const { click, selectorValue, label, testAvailable, xpathValue, handleClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [showConfirmationModal, setShowConfirmationModal] = useState(true);
  const [selectInputValueBeforePositive, setSelectInputValueBeforePositive] =
    useState("");
  const [selectInputValueAfterPositive, setSelectInputValueAfterPositive] =
    useState("");
  const [selectInputValueBeforeNegative, setSelectInputValueBeforeNegative] =
    useState("");
  const [selectInputValueAfterNegative, setSelectInputValueAfterNegative] =
    useState("");
  const [selectInputValueDecimal, setSelectInputValueDecimal] = useState("");
  const [selectInputValueGroup, setSelectInputValueGroup] = useState("");
  const [selectInputLabel, setSelectInputLabel] = useState("");

  const [maskResultValue, setMaskResultValue] = useState("");

  const intl = useIntl();

  useEffect(() => {
    setSelectInputValueGroup(",");
    setSelectInputValueDecimal(".");
  }, []);

  const getPreviewNumberMask = (numberMaskDto) =>
    ExtractorService.previewNumberMask(numberMaskDto);

  const handleClickTestMask = () => {
    const extractorActionNumberMaskDto = new ExtractorActionNumberMaskDto(
      selectInputValueBeforePositive,
      selectInputValueAfterPositive,
      selectInputValueBeforeNegative,
      selectInputValueAfterNegative,
      selectInputValueDecimal,
      selectInputValueGroup,
      selectorValue,
      xpathValue
    );
    getPreviewNumberMask(extractorActionNumberMaskDto).then(
      (response) => {
        setMaskResultValue(response.data);
      },
      () => {
        enqueueSnackbar("Error to apply mask", { variant: "error" });
        setMaskResultValue("");
      }
    );
  };
  const onChangeInputValueBeforePositive = (event) => {
    setSelectInputValueBeforePositive(event.target.value);
  };

  const onChangeInputValueAfterPositive = (event) => {
    setSelectInputValueAfterPositive(event.target.value);
  };

  const onChangeInputValueBeforeNegative = (event) => {
    setSelectInputValueBeforeNegative(event.target.value);
  };

  const onChangeInputValueAfterNegative = (event) => {
    setSelectInputValueAfterNegative(event.target.value);
  };

  const onChangeInputValueDecimal = (event) => {
    setSelectInputValueDecimal(event.target.value);
  };

  const onChangeInputValueGroup = (event) => {
    setSelectInputValueGroup(event.target.value);
  };
  const onChangeSelectInputLabel = (event) => {
    setSelectInputLabel(event.target.value);
  };

  const getConfirmationModalTitle = () => {
    const modalTitle = (
      <Grid container>
        <Grid item sm={11}>
          Confirm Get Number?
        </Grid>
      </Grid>
    );
    return modalTitle;
  };

  const disableInputs = (event) => {
    if (event.target.value) {
      document
        .querySelectorAll("div.group-text > div > input")
        .forEach((element) => {
          element.disabled = true;
        });
      event.target.disabled = false;
    } else {
      document
        .querySelectorAll("div.group-text > div > input")
        .forEach((element) => {
          element.disabled = false;
        });
    }
  };

  const getConfirmationModalContent = () => {
    const modalContent = (
      <Grid container spacing={1}>
        {label && (
          <Grid item sm={12}>
            <TextField
              required
              label={intl.formatMessage({ id: "extractor.action.modal.label" })}
              value={selectInputLabel}
              type="text"
              className="group-text"
              onChange={onChangeSelectInputLabel}
              onBlur={disableInputs}
              fullWidth
            />
          </Grid>
        )}
        <Grid item sm={12}>
          <TextField
            fullWidth
            label="Text before if it's positive"
            className="group-text"
            value={selectInputValueBeforePositive}
            onChange={onChangeInputValueBeforePositive}
            onBlur={disableInputs}
            InputProps={{ inputProps: { maxLength: 1 } }}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            fullWidth
            label="Text after if it's positive"
            className="group-text"
            value={selectInputValueAfterPositive}
            onChange={onChangeInputValueAfterPositive}
            onBlur={disableInputs}
            InputProps={{ inputProps: { maxLength: 1 } }}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            fullWidth
            label="Text before if it's negative"
            className="group-text"
            value={selectInputValueBeforeNegative}
            onChange={onChangeInputValueBeforeNegative}
            onBlur={disableInputs}
            InputProps={{ inputProps: { maxLength: 1 } }}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            fullWidth
            label="Text after if it's negative"
            className="group-text"
            value={selectInputValueAfterNegative}
            onChange={onChangeInputValueAfterNegative}
            onBlur={disableInputs}
            InputProps={{ inputProps: { maxLength: 1 } }}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            required
            fullWidth
            label="Decimal Separator"
            value={selectInputValueDecimal}
            onChange={onChangeInputValueDecimal}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            required
            fullWidth
            label="Group Separator"
            value={selectInputValueGroup}
            onChange={onChangeInputValueGroup}
          />
        </Grid>
        {testAvailable && (
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.grid}
          >
            <Grid item sm={3}>
              <Button
                type="button"
                size="large"
                variant="contained"
                color="secondary"
                onClick={handleClickTestMask}
              >
                <Typography>
                  <FormattedMessage id="action.mask.submit" />
                </Typography>
              </Button>
            </Grid>
            <Grid item sm={9}>
              <Typography>
                <FormattedMessage id="action.mask.result" />
                {maskResultValue || "-"}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    );

    return modalContent;
  };

  const handleConfirmationModalClose = () => {
    setShowConfirmationModal(false);
  };

  const clickInGenericModal = () => {
    const extractorActionNumberMaskDto = {
      beforePositive: selectInputValueBeforePositive,
      afterPositive: selectInputValueBeforePositive,
      beforeNegative: selectInputValueBeforeNegative,
      afterNegative: selectInputValueAfterNegative,
      decimalSeparator: selectInputValueDecimal,
      groupSeparator: selectInputValueGroup,
      selectorValue,
      xpathValue,
    };
    click(
      { ...extractorActionNumberMaskDto, selectorValue: maskResultValue },
      selectInputLabel
    );
    // click(extractorActionNumberMaskDto, selectInputLabel);
  };

  return (
    <div>
      {showConfirmationModal && (
        <GenericModal
          title={getConfirmationModalTitle()}
          click={clickInGenericModal}
          component={getConfirmationModalContent()}
          handleClose={handleClose}
        />
      )}
    </div>
  );
}

GenericNumber.propTypes = {
  click: PropTypes.func,
  selectorValue: PropTypes.string,
  xpathValue: PropTypes.string,
  label: PropTypes.bool,
  testAvailable: PropTypes.bool,
  handleClose: PropTypes.func,
};

GenericNumber.defaultProps = {
  click: undefined,
  selectorValue: "",
  xpathValue: "",
  label: true,
  testAvailable: true,
  handleClose: () => {},
};

export default GenericNumber;

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Grid,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import ChangePassword from "../change-password/ChangePassword";
import UserSetting from "../../models/UserSetting";
import UserService from "../../services/UserService";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
  },
  cardSelect: {
    width: "100%",
    background: theme.palette.secondary.dark,
  },
  avatarSelect: {
    background: theme.palette.secondary.light,
  },
  settingsDisplay: {
    height: "100vh",
    width: "100%",
  },
}));

function Settings(props) {
  const classes = useStyles();
  const listSettings = ["changePassword"];
  const { location } = props;
  const { settingsProps } = location;
  const [selectSettings, setSelectSettings] = useState("");
  const [isHeadLess, setIsHeadLess] = useState(true);

  const changeSelectSettings = (value) => {
    if (selectSettings === value) {
      setSelectSettings("");
    } else {
      setSelectSettings(value);
    }
  };

  useEffect(() => {
    loadSettings();
  }, []);
  const loadSettings = () => {
    // load isHeadLess
    UserService.getSetting("headless").then((res) => {
      setIsHeadLess(res.data.value === "true");
    });
  };

  const handleHeadLess = (event) => {
    setIsHeadLess(event.target.checked);
    const userSetting = new UserSetting(
      null,
      "headless",
      event.target.checked ? "true" : "false"
    );
    UserService.saveSetting(userSetting, "headless");
  };

  const settingsPropsneedPassword = useCallback(() => {
    if (settingsProps && settingsProps.needPassword === true) {
      setSelectSettings("changePassword");
    }
  }, [setSelectSettings, settingsProps]);

  useEffect(() => {
    settingsPropsneedPassword();
  }, [location, settingsPropsneedPassword]);

  return (
    <>
      <Grid container>
        <Grid item sm={3}>
          {listSettings.map((item) => (
            <Box onClick={() => changeSelectSettings(item)}>
              <List>
                <ListItem>
                  <Box
                    className={
                      item === selectSettings
                        ? classes.cardSelect
                        : classes.card
                    }
                    component={Card}
                    raised
                  >
                    <CardHeader
                      title={
                        <Tooltip
                          title="Change Password"
                          placement="bottom-start"
                        >
                          <Typography
                            className={classes.subheader}
                            variant="subtitle1"
                            noWrap
                          >
                            <FormattedMessage id={`settings.${item}`} />
                          </Typography>
                        </Tooltip>
                      }
                      avatar={
                        <Avatar
                          className={
                            item === selectSettings ? classes.avatarSelect : ""
                          }
                          variant="rounded"
                        >
                          <VpnKeyIcon />
                        </Avatar>
                      }
                    />
                  </Box>
                </ListItem>
              </List>
            </Box>
          ))}
        </Grid>
        <Grid item sm={9} className={classes.settingsDisplay}>
          {selectSettings === "changePassword" && <ChangePassword />}
        </Grid>
      </Grid>
    </>
  );
}

Settings.propTypes = {
  location: PropTypes.shape(PropTypes.object),
  settingsProps: PropTypes.shape(PropTypes.object),
};

Settings.defaultProps = {
  location: {},
  settingsProps: { needPassword: false },
};

export default Settings;

import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef } from "react";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
}));

function ImageMapperMouse(props) {
  const classes = useStyles();
  const { img, onClick, areas } = props;
  const imgRef = useRef(null);
  const canvasRef = useRef(null);

  const getContext = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.setLineDash([5, 10]);
    ctx.lineWidth = 2;
    ctx.fillStyle = "green";
    ctx.font = "15px Arial";

    return ctx;
  }, []);

  const drawAreas = (arr) => {
    const canvas = canvasRef.current;
    const ctx = getContext();
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.setLineDash([5, 3]);
    ctx.lineWidth = 2;
    ctx.strokeStyle = "green";

    arr.forEach((a) => {
      ctx.strokeRect(a.x, a.y, a.width, a.height);
    });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, imgRef.current.width, imgRef.current.height);
    if (img.includes("ndex")) {
      imgRef.current.setAttribute("src", img);
    } else {
      imgRef.current.setAttribute("src", `data:image/png;base64, ${img}`);
    }
    if (areas && areas.length > 0) drawAreas(areas);
  }, [img, canvasRef, getContext]);

  const handleOnLoad = (event) => {
    canvasRef.current.width = event.currentTarget.width;
    canvasRef.current.height = event.currentTarget.height;
    // if (areas && areas.length > 0) drawAreas(areas);
  };

  const drawLines = (x, y) => {
    const ctx = getContext();
    const imgRect = imgRef.current.getBoundingClientRect();
    ctx.beginPath();
    ctx.moveTo(x, 0);
    ctx.lineTo(x, imgRect.height);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(0, y);
    ctx.lineTo(imgRect.width, y);
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(x, y, 3, 0, 2 * Math.PI);
    ctx.fill();
    ctx.stroke();
  };

  const calcCoordinates = (clientX, clientY) => {
    const imgRect = imgRef.current.getBoundingClientRect();
    const xOffset = imgRect.x;
    const yOffset = imgRect.y;
    const x = clientX - xOffset;
    const y = clientY - yOffset;

    return { x, y };
  };

  const handleMouseMove = (event) => {
    const { x, y } = calcCoordinates(event.clientX, event.clientY);
    if (props.displayLines) drawLines(x, y);
  };

  const handleOnClick = (event) => {
    const coordinates = calcCoordinates(event.clientX, event.clientY);
    onClick(coordinates);
  };

  return (
    <>
      <div className={classes.root}>
        <img
          src=""
          alt=""
          useMap="#map"
          ref={imgRef}
          onLoad={handleOnLoad}
          onMouseMove={handleMouseMove}
          onClick={handleOnClick}
          aria-hidden="true"
        />

        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            pointerEvents: "none",
            zIndex: 2,
          }}
        />

        <map name="map">
          {areas &&
            areas
              .filter((e) => e !== undefined)
              .map((e, index) => (
                <area
                  key={index}
                  alt={index}
                  shape="rect"
                  coords={`${e.x}, 
                      ${e.y}, 
                      ${e.x + e.width}, 
                      ${e.y + e.height} `}
                  target="_self"
                  href="#"
                  onClick={handleOnClick}
                  // onMouseEnter={handleMouseEnter}
                  //onMouseLeave={handleMouseLeave}
                  selector={e.selector}
                  xpath={e.xpath}
                  textcontent={e.textContent}
                />
              ))}
        </map>
      </div>
    </>
  );
}

ImageMapperMouse.propTypes = {
  img: PropTypes.string,
  onClick: PropTypes.func,
  displayLines: Boolean,
};

ImageMapperMouse.defaultProps = {
  img: undefined,
  onClick: () => {},
  displayLines: true,
};

export default ImageMapperMouse;

import {
  ButtonGroup,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ExtractorActionType from "../../../../models/enums/ExtractorActionType";
import GenericModal from "../../GenericModal";
import "../../../app/App.css";
import "./FieldSelectCss.css";

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  input: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  getTableInput: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  getNumberInput: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },

  getDateInput: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  linearProgress: {
    width: "100%",
    margin: "30px 0 0 0",
  },
  buttonGroup: {
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
}));

function FieldSelect(props) {
  const classes = useStyles();
  //const intl = useIntl();
  const {
    addAction,
    showFields,
    setShowFields,
    sections,
    setSelectType,
    isNew,
    maxSequence,
    allSequence,
    canAddAction,
  } = props;
  const [fieldsArray, setFieldsArray] = useState([]);
  const [sequence, setSequence] = useState();

  const toggleDropDown = () => {
    let wrapper = document.getElementById("wrapper");
    wrapper.classList.toggle("active");
  };

  useEffect(() => {
    setSequence(maxSequence + 10 - ((maxSequence + 10) % 10));
  }, [isNew, maxSequence, canAddAction]);

  function dynamicSort(prop) {
    let property = prop;
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function calculate(a, b) {
      const result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  useEffect(() => {
    let tempFields = [];

    sections.forEach((e) => {
      e.fields.forEach((f) => {
        f.sectionName = e.name;
        tempFields.push(f);
      });
    });
    setFieldsArray(tempFields);
  }, []);

  const updateName = (event) => {
    let wrapper = document.getElementById("wrapper");
    wrapper?.classList.remove("active");
    let selectBtn = document.getElementById("select-btn");

    let element =
      event.target.tagName === "SPAN"
        ? event.target.parentElement
        : document.getElementById(event.target.id);

    let option = element?.innerHTML;
    let reddot = '<span className="reddot">*</span>';

    if (option.includes(reddot)) {
      document.getElementById("reddot").style.display = "block";
    } else {
      document.getElementById("reddot").style.display = "none";
    }
    
    selectBtn.childNodes[1].innerHTML = element.childNodes[1].innerHTML;
    selectBtn.childNodes[1].setAttribute("value", element.getAttribute("value"));
    selectBtn.childNodes[1].setAttribute("fieldname", element.getAttribute("fieldname"));
  };

  const clickSelectAction = () => {
    setSelectType(ExtractorActionType.TYPE_F_ENGINE_FIELD);
    const elementSelected = document.getElementById("field-select");
    let value = elementSelected.getAttribute("value");
    const text = elementSelected.getAttribute("fieldname") + ":-";

    if (!value) {
      value = "empty";
    }
    const option = text.concat(value);

    const selectType = ExtractorActionType.TYPE_F_ENGINE_FIELD;
    addAction({ sequence, selectType, undefined, option });
  };

  const renderSelectOptionsFields = () => {
    let select = <></>;
    select = (
      <>
        <div className="wrapper" id="wrapper">
          <div className="select-btn" id="select-btn" onClick={toggleDropDown}>
            <span className="reddot-default" id="reddot">
              *
            </span>
            <span id="field-select" style={{ whiteSpace: "pre-line" }}>Select a Field</span>
          </div>
          <div className="content">
            {/* <div className="search">
              <i className="uil uil-search"></i>
              <input spellcheck="false" type="text" placeholder="Search" />
            </div> */}
            {fieldsArray && (
              <ul
                className="options"
                //</div>ref={options}
              >
                {Object.keys(fieldsArray).map((key) => (
                  <li
                    key={key}
                    value={fieldsArray[key]?.value}
                    fieldname={fieldsArray[key]?.name.replace(":", "")}
                    id={key}
                    onClick={(e) => updateName(e)}
                  >
                    <span className="reddot">
                      {fieldsArray[key]?.value ? "" : "*"}
                    </span>
                    <span style={{ whiteSpace: "pre-line" }}>
                      Section: {fieldsArray[key]?.sectionName.replace(":", "")}<br />
                      Field: {fieldsArray[key]?.name.replace(":", "")}<br />
                      Value: {fieldsArray[key]?.value}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {/* <li onclick="updateName(this)" className="${isSelected}">
          ${field?.name.replace(":", "")}
        </li> */}

        {/* {fieldsArray && (
          <Select
            native
            labelId="section-select-label"
            label="Type"
            // value={investors[0]?.portfolioid + " - " + investors[0]?.name}
            id="field-select"
          >
            {Object.keys(fieldsArray).map((key) => (
              <option value={fieldsArray[key]?.value}>
                {fieldsArray[key]?.value ? (
                  <font>*</font>
                ) : (
                  // + fieldsArray[key]?.name?.replace(":", "")
                  fieldsArray[key]?.name?.replace(":", "")
                )}
              </option>
            ))}
          </Select>
        )} */}
      </>
    );
    return select;
  };

  const componentSelectAction = (
    <>
      <Grid item sm={9}>
        <FormControl className={classes.formControl}>
          <InputLabel id="action-type-label">
            <FormattedMessage id="select.action.title" />
          </InputLabel>
          {renderSelectOptionsFields()}
        </FormControl>
      </Grid>
    </>
  );

  return (
    <>
      {showFields && (
        <GenericModal
          title={"Select a Field"}
          component={componentSelectAction}
          click={clickSelectAction}
          handleClose={() => setShowFields(false)}
        />
      )}
    </>
  );
}

FieldSelect.propTypes = {
  addAction: PropTypes.func,
  setShowFields: PropTypes.func,
  setSelectType: PropTypes.func,
  showFields: PropTypes.bool,
  sections: PropTypes.array,
};

FieldSelect.defaultProps = {
  showFields: false,
  sections: [],
  addAction: () => {},
  setShowFields: () => {},
  setSelectType: () => {},
};

export default FieldSelect;

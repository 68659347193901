import HttpService from "./HttpService";

const ExtractorService = {
  start: (extractorDto) => HttpService.start(extractorDto),

  rename: (extractorDto) => HttpService.rename(extractorDto),

  save: () => HttpService.save(),

  reload: () => HttpService.reload(),

  getCurrentNormalized: () => HttpService.getCurrentNormalized(),

  finish: () => HttpService.finish(),

  executeAction: (action) => HttpService.executeAction(action),

  GetLastImage: () => HttpService.getLastImage(),

  changeAction: (action) => HttpService.changeAction(action),

  changeSequence: (currentSequence, newSequence) =>
    HttpService.changeSequence(currentSequence, newSequence),

  getNormalizedData: (action) => HttpService.getNormalizedData(action),

  previewImage: (sequence) => HttpService.previewImage(sequence),

  previewNumberMask: (numberMaskDto) =>
    HttpService.previewNumberMask(numberMaskDto),

  previewDateTimeMask: (datetimeMaskDto) =>
    HttpService.previewDateTimeMask(datetimeMaskDto),

  getAllTimeZones: () => HttpService.getAllTimeZones(),

  getNormalizedHtml: (sequence, actionType) =>
    HttpService.getNormalizedHtml(sequence, actionType),

  getElementScreenshot: (sequence) =>
    HttpService.getElementScreenshot(sequence),

  getExtractors: (extractorType) => HttpService.getExtractors(extractorType),

  getExtractor: (id) => HttpService.getExtractor(id),

  deleteExtractor: (id) => HttpService.deleteExtractor(id),

  deleteAction: (sequence) => HttpService.deleteAction(sequence),

  resequenceActions: () => HttpService.resequenceActions(),

  getNormalizedToEdit: (sequence) => HttpService.getNormalizedToEdit(sequence),

  getAllElementScreenshots: () => HttpService.getAllElementScreenshots(),

  execute: (extractorId) => HttpService.executeExtractor(extractorId),

  checkPendingAsk: (extractorType) => HttpService.checkPendingAsk(extractorType),

  clearErrorMessage: (id) => HttpService.clearErrorMessage(id),

  updatePendingAskAction: (action) =>
    HttpService.updatePendingAskAction(action),

  getElementPoint: (action) => HttpService.getElementPoint(action),
};

export default ExtractorService;
